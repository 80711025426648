/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import '../App.css'
import { useEffect, useState } from "react";
import axios from 'axios';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import serverInfo from "../util/globals";
// This values are the props in the UI
let currency="EUR"
const style = {"layout":"vertical"};

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ dapagare,currency, showSpinner }) => {
    const [risposta, setRisposta] = useState("");
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);


    return (
    
    risposta!==''
    ?
    risposta
    :
            
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[currency, style]}
            
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: dapagare,
                                    },

                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            console.log("orderId "+orderId)
                            return orderId;
                        });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details)=> {
                        console.log("data: ")
                        console.log(data)
                        
                        var url_string = window.location.href;
                        var url = new URL(url_string);
                        var dataparam = url.searchParams.get("datap");
                        console.log('paypalbutton datap= '+dataparam)
                        const formData = new FormData();
                        formData.append('datap',dataparam)
                        formData.append('orderID',data.orderID);
                        formData.append('payerID',data.payerID);
                        axios.post(serverInfo.name+"/postPagamentoWp", formData)
                        .then(response=>{
                            console.log(response);
                            setRisposta(response.data)
                            localStorage.removeItem("dapagare")
                            return response.data;
                                
                        });
                    });
                }}
            />
    
    );
}

export default function PypalButtons(props) {
    const [risposta, setRisposta] = useState("");
	return (
        <>
        {
        risposta?
        <div>
            {risposta}
        </div>
        :
		props.clientid&&props.clientid!==''&&props.clientid!=='XXX-XXX'&&
            <div style={{ maxWidth: "750px", minHeight: "200px" }}>
                ***{props.clientid}***
            <PayPalScriptProvider
                options={{
                    "client-id": props.clientid,
                    //"AY1HUZ4tfOyl86yRPANEROT0Xj_bRxN-5_J8RqkFA99uqodfS9aNh6J9ov9h85cWpb8vq_IeHvDodndv",<==gifa75 com
                    //"AURHjP4FGs8ZUYqpTKHnqS7WDbCCivk3DJ4CCvPLr-JB9qqaXxWZC85Ozs8bub5fhMmrIN12X1pfNPP1",<== physicolab2 (prove)
                   // "Aazq6bzsBsyYjVFYb3RCkQ-sZ7T-Ce1BAd398qvkqgJCpw-4fuWX3Zgjg4V95CDuVPEo2M3qNrHzaNUF", <== physicolab (originale)
                    //"Afk91TpiCUa1XANPw1AwbzAIOpK97nnlz7yLrEepHpFnCQ2fXvQosCWZT5Os5yAUicSs42mVTV4roIII",<==gifa75 it
                    components: "messages,buttons",
                    "enable-funding":"paylater"
                    //"merchant-id"
                }}
                dapagare={props.dapagare?props.dapagare:0}
            >
				<ButtonWrapper
                    currency={currency}
                    showSpinner={true}
                    dapagare={props.dapagare?props.dapagare:0}
                />
			</PayPalScriptProvider>
		</div>}
        </>
	);
}