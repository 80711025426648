import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import RicevutaCliente from "./RicevutaCliente";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function RicevuteCliente (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [idabbonamento, setidabbonamento] = useState(props.idabbonamento)
    
    const [idpagamento, setidpagamento] = useState(props.idpagamento)
    const [ricevute, setricevute] = useState(props.selected)
	const [totRicevute, settotRicevute] = useState(0)
	const [showModalRicevute, setshowModalRicevute] = useState(false)


useEffect(() =>{
	if (props.ricevute
		&&Array.isArray(props.ricevute)
		&&props.ricevute.length>=0){
			setricevute (props.ricevute);
	}
	else{
				const formData = new FormData();
				formData.append('idabbonamento',props.idabbonamento);
				formData.append('website', global.config.domain);
				formData.append('email',email);
				formData.append('lic','000');
				formData.append('palestra',palestra.id);
				axios.post(serverInfo.name+"/getRicevute", formData)
			.then(response=>{
				setricevute(response.data);
			}).catch(err => {
				if (err.response){
				alert("Ricevute: "+err.response.data.message);
				}
				else{
				alert("Ricevute: "+err.response);
				}
			})
		}
	},[palestra, email])

    return (
    		<span>
	    		<div className="row">
		    		{
		    			(!ricevute||ricevute.length===0)?
		    					<div>Nessuna ricevuta</div>
		    					:
                                <div>
                                    <h4>Ricevute</h4>
                                {ricevute.map(item =>(
                                    <RicevutaCliente key={item.id} selected={item} email={email} idUtente={props.idUtente}/>
                                    )
                                )}
                            </div>
		    		}
	    		</div>
    		</span>
    );
}