/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { cercaPalestre } from "../DAO/palestraDAO";
import { getutente, insAnagrafica } from "../DAO/anagraficaDAO";
import { useSelector } from "react-redux";

export default function AssociaPalestraAdUtente({email}) {
    const token = useSelector(state => state.gestoreUtente.token);
    const [nome, setnome] = useState('')
    const [cognome, setcognome] = useState('')
    const [telefono, settelefono] = useState('')

    const [ cerca, setcerca] = useState('');
    const [ listaatt, setlistaatt] = useState([]);
    const [attselected, setattselected]=useState()
    const [ute, setute]=useState()

    function cercatt(){
        async function cercaattivita(){
            const result=await cercaPalestre(cerca)
            setlistaatt(result)
            setattselected(null)
        }
        cercaattivita()
    }

    function selectatt(att){
        setattselected(att)
        getdatiute()
    }

    function getdatiute(){
        async function leggidatiute(){
            const result = await getutente(email, token)
            console.log(result,"utente")
            setute(result)
            setnome(result.nome)
            setcognome(result.cognome)
            settelefono(result.telefono)
        }
        leggidatiute()
    }

    function conferma(){
        async function conf(){
            const anagrafica={email: email, nome: nome, cognome: cognome, telefono: telefono}
            const result = await insAnagrafica(email, attselected.id, anagrafica)
            console.log(result,"utente")
            if (result&&result.id){
                alert('Cliente creato con successo')
            }
            setute(result)
        }
        conf()
    }

    return (
        <div className="container">
            <h4>Diventa Cliente</h4>
            <h5>Seleziona Attività Commerciale</h5>
            <div className="row">
                <div className="col">
                <input type="text" 
                    placeholder="Cerca Attività Commerciale" 
                    name="cerca" value={cerca} onChange={(e) => setcerca(e.target.value)} />
                    <button onClick={cercatt}>Cerca</button>
                    {
                        listaatt.map(att => (
                            <p key={att.id}>
                                <img src={att.logo} style={{height:60}}/>
                            <h6>{att.palestra}<button onClick={() => selectatt(att)}>Seleziona</button></h6>
                            <i>{att.descrizioneattivita}</i><br/> 
                            {att.calendari}
                            </p>
                        ))
                    }
                </div>
                <div className="col">
                {
                attselected&&attselected.id&&ute&&
                <div>
                    <h5>I tuoi dati</h5>
                    <p>Email: {ute.email} Name: {ute.name}</p>
                    <p>Nome: <input type="text" 
                    placeholder="Nome" 
                    name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
                    </p>
                    <p>Cognome: <input type="text" 
                    placeholder="Cognome" 
                    name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} /></p>
                    <p>Tel.: <input type="text" 
                    placeholder="Tel." 
                    name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} /></p>
                    <button onClick={conferma}>Conferma registrazione come cliente di {attselected.palestra}</button>
                </div>
               
            }
                </div>
            </div>
            

            
        </div>
    )
}