module.exports = global.config = {
    domain : localStorage.getItem('domain')?localStorage.getItem('domain'):window.location.hostname,
    server:localStorage.getItem('server')?'http://localhost:8080/wp':'https://be.annotatio.online/wp',
    url:'/wp-content/plugins/annotatio-prenoa/widget/build'
    //server:'http://localhost:8080/wp',
    //server:'http://192.168.178.33:8080/wp',
    //domain : 'borlotto.it'
};

module.exports = global.variables = {
    palestraSelezionata : 'palselezionata',
    anagraficaSelezionata:'idanagrafica',
    palestraAnagraficaAassociata:'palassociata',
    anagraficaAssociata:'objanagrafica'
};

module.exports = global.localstorage = {
    palestraSelezionata : localStorage.getItem('palselezionata'),
    anagraficaSelezionata:localStorage.getItem('idanagrafica'),
    palestraAnagraficaAassociata:localStorage.getItem('palassociata'),
    anagraficaAssociata: JSON.parse(localStorage.getItem('objanagrafica')),
    calendario:localStorage.getItem('calendario'),
    palestracalendario:localStorage.getItem('palestracalendario')
};