import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  documentiricevuti, documentisalvati,stampaIncarico, stampaInformativa } from "../DAO/DocumentiDAO";
import Bottone from "../util/UI/Bottone";
import ProtoUscitaAnag from "./protocolliUscitaAnag";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProtoEntrataAnag from "./protocolliEntrataAnag";
import { confermaincarico, confermainfprivacy } from "../DAO/anagraficaDAO";
import Text from "../util/UI/Text";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function DocumentiUtente(){
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
   
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
    
    const [apriincarico, setapriincarico]=useState(false)
    const [apriprivacy, setapriprivacy]=useState(false)

   

    function salvapresavisioneincarico(){
        async function salvapvincarico(){
            const result = await confermaincarico(palestra.id, anagrafica.id);
            setapriincarico(false)
        }
        salvapvincarico()
    }

    function salvapresavisioneprivacy(){
        async function salvapvprivacy(){
            const result = await confermainfprivacy(palestra.id, anagrafica.id);
            setapriprivacy(false)
        }
        salvapvprivacy()
    }

    

    function stampainformativa(){
        async function stampainc(){
           await stampaInformativa(palestra.id, anagrafica.id)
        }
        if (palestra){
            stampainc()
        }
        else{
            alert('no palestra')
        }
    }

    function stampaincarico(){
        async function stampainc(){
            await stampaIncarico(palestra.id, anagrafica.id)
        }
        if (palestra){
            stampainc()
        }
        else{
            alert('no palestra')
        }
    }

    

    


   

    return(
        <div key={keytorefresh} className="container-fluid">
            <div className="row">
                <h4>Documenti </h4>
            </div>
        <div className="row">
            <div className="col-md-6">
            <Bottone bootstrapsize={"col-md-12"}
            bootstrapstyle={"info"}
            label={"Lettera di incarico"}
            name={"presavisioneincarico"}
            onClick={stampaincarico}
           value={1} />
            {anagrafica.dataincarico?
            <Text bootstrapsize={"col-md-12"}
            label={"Data conferma incarico"}
            value={anagrafica.dataincarico}
             /> 
            :<Bottone bootstrapsize={"col-md-12"}
            bootstrapstyle={"success"}
            label={"Conferma la presa visione e la conferma dell'incarico"}
            name={"presavisioneincarico"}
            onClick={() => setapriincarico(true)}
           value={1} />}
            
            </div>
            <div className="col-md-6">
            <Bottone bootstrapsize={"col-md-12"}
            bootstrapstyle={"info"}
            label={"Informativa Privacy"}
            name={"presavisioneincarico"}
            onClick={stampainformativa}
           value={1} />
 {anagrafica.dataconsensoprivacy?
            <Text bootstrapsize={"col-md-12"}
            label={"Data consenso informativa privacy"}
            value={anagrafica.dataconsensoprivacy}
             /> 
            :<Bottone bootstrapsize={"col-md-12"}
            bootstrapstyle={"success"}
            label={"Conferma la presa visione sul trattamento dei tuoi dati personali"}
            name={"presavisioneprivacy"}
            onClick={() => setapriprivacy(true)}
           value={1} />}
            </div>
         </div>
         <hr />
        <div className="row">
            <div className="col">
                <Tabs>
                    <TabList>
                        <Tab>Inviati</Tab>
                        <Tab>Ricevuti</Tab>
                    </TabList>
                    <TabPanel>
                    <ProtoEntrataAnag />

				</TabPanel>
				<TabPanel>
                <ProtoUscitaAnag />
				</TabPanel>
			</Tabs>

            </div>
        </div>
        <hr />

<Dialog open={apriincarico}>
<DialogTitle>Conferma Incarico</DialogTitle>
<div className="container">
    <div className="row">
        <div className="col">
        Cliccando sul bottone &apos;Conferma&apos; dichiara di aver preso visione della lettera di incarico e di affidarsi a <b>{palestra.palestra }</b>
    </div>
    </div>
    <div className="row">
        
        <Bottone bootstrapsize={"col-md-3"}
        bootstrapstyle={"success"}
        label={"Conferma"} onClick={salvapresavisioneincarico} />
     <Bottone bootstrapsize={"col-md-3"}
        bootstrapstyle={"info"}
        label={"Chiudi"} onClick={() => setapriincarico(false)} />
    </div>
</div>
</Dialog>

<Dialog open={apriprivacy}>
<DialogTitle>Consenso Trattamento dati personali</DialogTitle>
<div className="container">
    <div className="row">
        <div className="col">
        Cliccando sul bottone &apos;Conferma&apos; dichiara di aver preso visione del trattamento dei propri dati personali
    </div>
    </div>
    <div className="row">
        
        <Bottone bootstrapsize={"col-md-3"}
        bootstrapstyle={"success"}
        label={"Conferma"} onClick={salvapresavisioneprivacy} />
     <Bottone bootstrapsize={"col-md-3"}
        bootstrapstyle={"info"}
        label={"Chiudi"} onClick={() => setapriprivacy(false)} />
    </div>
</div>
</Dialog>
        </div>
    );
}