import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { settipoprodotto } from "../STORE/reducers/gestoreTipiProdotto";
import { navigate } from "hookrouter";
export default function TipiCorsoHoriz(props){
	const tipi = useSelector(state => state.gestoreTipiProdotto.tipi);
	const tipo = useSelector(state => state.gestoreTipiProdotto.tipo);

	const dispatch = useDispatch();

	function showCalendar(tipo) {
		dispatch(settipoprodotto(tipo));
	  }

	    return (
			<div className="table-responsive">
			<table className="table table-striped">
				<tbody><tr>   
				{
					tipi?
					tipi.map((item,index) =>(
						<td key={index} style={{backgroundColor:parseInt(item.id)===parseInt(tipo.id)?'#d1c6b0':'white',
						color:parseInt(item.id)===parseInt(tipo.id)?'white':'black'}}
						onClick={()=>{showCalendar(tipi[index]); navigate('/anntt-client-calendario/')}}>
							{/* <Link to={"/calendario"}> */}
						<img className="rounded" src={item.foto} alt={item.nome} style={{height:'3em'}} /><br/>
						{item.nome}
						{/* </Link> */}
						</td>
					)
					)
					:null
	    		}
				</tr></tbody>
				</table>
				</div>
	    );
	}