import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import AbbonamentoCliente from "./AbbonamentoCliente";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { navigate } from 'hookrouter'

export default function AbbonamentiCliente (props){
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

  const [abbonamenti, setabbonamenti]=useState([])
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

  function abbonati(){
    navigate('/annotatioc-newabb/',true)
  }

  useEffect(() =>{
    
    if (palestra&&palestra.id&&anagrafica&&anagrafica.id){
       
        const formData = new FormData();
        formData.append('website', palestra.website);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('id',anagrafica.id)
        axios.post(global.config.server+"/getAbbonamenti", formData)
        .then(response=>{
          //console.log(response.data)
          setabbonamenti(response.data);
        })

		  .catch(err => {
              if (err.response){
                alert("abbonamenti: "+err.response.data.message);
              }
              else{
                alert("abbonamenti: "+err.response);
              }
		  });
    }
      },[palestra, anagrafica, keytorefresh]);

    return (
        <span key={keytorefresh}>
            <h2 className="entry-title">
                <h3>Abbonamenti <Button onClick={abbonati}>Nuovo Abbonamento</Button></h3>
            </h2>
            {abbonamenti
            &&Array.isArray(abbonamenti)
            &&abbonamenti.length>0?
            abbonamenti.map(abb=>(
                <AbbonamentoCliente key={abb.id} abbonamento={abb} link={props.link} email={email} setkeytorefresh={setkeytorefresh}/>
            ))
          :
          <div>
            Nessun abbonamento trovato
          </div>}
      </span>
      );
}