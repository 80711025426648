import React, { useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import { disdici, getCalendario, prenota } from '../DAO/calendarioDAO';
import { getPrenotazioni } from '../DAO/prenotazioniDAO';
import { setcalendariocliente } from '../STORE/reducers/gestoreCalendari';
import { setprenotazionicliente } from '../STORE/reducers/gestorePrenotazioniCliente';

export default function RigaCalendarioGiornaliero(props) {
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const token = useSelector(state => state.gestoreUtente.token);

  const [dialogPrenoVisible, setDialogPrenoVisible] = useState(false);
  const [dialogDisdVisible, setDialogDisdVisible] = useState(false);
  const [showdettaglioattivita, setshowdettaglioattivita] = useState(false);
  const tipo = useSelector(state => state.gestoreTipiProdotto.tipo);

  const dispatch = useDispatch();

  function confermaPreno(idschedulazione) {
    async function confermaPrenotazione(idschedulazione) {
      try {
        const msgPrenotazione = await prenota(
          anagrafica.email,
          anagrafica.idpalestra,
          idschedulazione,
          anagrafica.id,
        );
        alert(msgPrenotazione.message);
        caricaCalendario();
      } catch (error) {
        alert(error.response.data.message);
      }
    }
    confermaPrenotazione(idschedulazione);
  }

  function confermaDisd(idschedulazione, idprenotazione) {
    async function confermaDisdetta(idschedulazione, idprenotazione) {
      const msgPrenotazione = await disdici(
        anagrafica.email,
        anagrafica.idpalestra,
        idschedulazione,
        idprenotazione,
        anagrafica.id,
      );
      alert(msgPrenotazione);
      caricaCalendario();
    }
    confermaDisdetta(idschedulazione, idprenotazione);
  }

  const alertPreno = () => {
    return (
      <div style={{flex: 1}}>
        
          {dialogPrenoVisible&&<div >
            <div >
              <p>
                {props.app.description} ({props.app.location})
              </p>
              <button onClick={() => setDialogPrenoVisible(false)}>
                Chiudi
              </button>
              <button
                onClick={() => {
                  confermaPreno(props.app.schedulazione.id);
                  setDialogPrenoVisible(false);
                }}>
                PRENOTA
              </button>
            </div>
          </div>}
      </div>
    );
  };

  const alertDisdetta = () => {
    return (
      <div>
       
          {dialogDisdVisible&&<div >
            <div >
              <p>
                {props.app.description} ({props.app.location})
              </p>
              <button onClick={() => setDialogDisdVisible(false)}>
                Chiudi
              </button>
              <button
                onClick={() => {
                  confermaDisd(
                    props.app.schedulazione.id,
                    props.app.idPrenotazione,
                  );
                  setDialogDisdVisible(false);
                }}>
                Disdici
              </button>
            </div>
          </div>}
      </div>
    );
  };

  function caricaCalendario() {
    async function caricaCalend() {
      const calendariodb = await getCalendario(
        anagrafica.email,
        anagrafica.idpalestra,
        tipo.id,
        anagrafica.id,
        token
      );
      //console.log('calendariodb');
      //console.log(calendariodb);
      dispatch(setcalendariocliente(calendariodb));
      
      const prenotazionidb = await getPrenotazioni(
        anagrafica.email,
        null,
        anagrafica.idpalestra,
        anagrafica.id,
        null
      );
      dispatch(setprenotazionicliente(prenotazionidb));
    }
    caricaCalend();
  }

  const dettaglioattivita = () => {
    return (
      <div style={{flex: 1}}>
        
          {showdettaglioattivita&&<div >
            <div >
            {props.app.schedulazione&&props.app.schedulazione.urlcorso&&
            <>
            <div>{props.app.schedulazione.urlcorso}</div>
             
              <button onClick={() => setshowdettaglioattivita(false)}>
                Chiudi
              </button>
              </>
            }
            </div>
            </div>}
      </div>
    );
  };

  console.log(props)

  return (
    <div>
      {alertPreno()}
      {alertDisdetta()}
      {dettaglioattivita()}
      <div key={props.app.id}>
        <div
          style={{
            flex: 2,
            flexDirection: 'column',
            marginBottom: 10,
            marginLeft: 10,
            minHeight: 30,
            backgroundColor: props.app.backgroundColor,
          }}>
            <button onClick={() =>  props.app.schedulazione&& props.app.schedulazione.urlcorso && setshowdettaglioattivita(true)}>
          <p  >
            {props.app.schedulazione && props.app.schedulazione.ora} {props.app.schedulazione && props.app.schedulazione.descrizione}{' '}
            ({ props.app.schedulazione && props.app.schedulazione.durata} min.)
          </p>
          {props.app.backgroundColor !== 'red' ? (
            <p style={{ textAlign: 'center'}}>
              {props.app.actual} / {props.app.max}
            </p>
          ) : null}
          </button>
        </div>

        <div style={{flex: 1}}>
          {props.app.backgroundColor !== 'red' &&
          props.app.backgroundColor !== 'gray' &&
          !props.app.idPrenotazione ? (
            <button
              style={{backgroundColor: 'white', color: 'blue', borderRadius: 0}}
              onClick={() => setDialogPrenoVisible(true)}>
              Prenota!
            </button>
          ) : null}
          {props.app.backgroundColor !== 'red' &&
          props.app.backgroundColor !== 'gray' &&
          props.app.idPrenotazione ? (
            <button
              onClick={() => {
                setDialogDisdVisible(true);
              }}>
             {props.app.riserva?"Riserva n. "+props.app.riserva+" - ":null} Disdici 
            </button>
          ) : null}
          {props.app.backgroundColor === 'red' ||
          props.app.backgroundColor === 'gray' ? (
            <p style={{fontSize: 11, marginLeft:10}}>{props.app.description}</p>
          ) : null}
        </div>
      </div>
      <hr />
    </div>
  );
}