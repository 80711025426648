import React, { useEffect, useState } from "react";
import '../util/config'
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getMessaggiOrdine } from "../DAO/messaggiDAO";
import { annullaordine, inviamsgcliente, modificaoraconsegna } from "../DAO/ordiniDAO";

export default function Ordine(props) {

    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

    const [ordine, setordine] = useState(props.ordine);
    const [messaggi, setmessaggi] = useState(null);
    const [nuovaoraconsegna, sertnuovaoraconsegna] = useState(("0"+ordine.oraconsegna.split(":")[0]).slice(-2)+":"+("0"+ordine.oraconsegna.split(":")[1]).slice(-2));
    const [messaggio, setmessaggio] = useState('');

    function getmessaggi(){
        async function readmsg(){
            const msg=getMessaggiOrdine(anagrafica.email, palestra.id, ordine.id);
            setmessaggi(msg);
        }
        if (anagrafica&&anagrafica.id){
            readmsg()
        }
    }

    function modificaOraConsegna(e){
        async function modora(){
            const result=modificaoraconsegna(anagrafica.email, palestra.id, ordine.id, nuovaoraconsegna);
            alert(result);
        }
        if (anagrafica&&anagrafica.id){
            modora();
        }
      }

      function inviaMessaggio(){
        async function invia(){
            const result = inviamsgcliente(anagrafica.email, palestra.id, ordine.id, messaggio, anagrafica.id);
            alert(invia);
        }
        if (anagrafica&&anagrafica.id){
            invia();
        }
      }


      function annulla(){
            async function annord(){
                const result=annullaordine(anagrafica.email, palestra.id,ordine.id);
                props.refresh()
            }
            if (anagrafica&&anagrafica.id){
                if (window.confirm('Sei sicuro di voler annulare l\'ordine seelzionato?')){
                    annord();
                }
            }
          
      }

      useEffect(() => {
        getmessaggi(ordine.id)
      }, [ordine])

        return (
            ordine.id?
        <div className="card-text">
            <div className="row">
                <div className="col-md-6">
                    <div>{ordine.id}, Stato: {ordine.status}
                    {
                        ordine.status
                        &&ordine.status==='A_NUOVO'
                        &&ordine.totalePagato===0
                        ?
                        <div>
                                Ora di consegna desiderata: <input type="time" name="nuovaoraconsegna" value={nuovaoraconsegna} onChange={(e) => sertnuovaoraconsegna(e.target.value) } />
                                <Button onClick={modificaOraConsegna}>Aggiorna ordine</Button>
                        </div>
                        :null
                    }
                    </div>

                    {
                        ordine.prenotazioni&&Array.isArray(ordine.prenotazioni)&&ordine.prenotazioni.length>0?
                        ordine.prenotazioni.map(prenotazione=>(
                            <div key={prenotazione.id}>
                                {prenotazione.corso} - 
                                {prenotazione.dataOra && <span>Prenotazione per le {prenotazione.dataOra} - </span>}
                                Data e ora di prenotazione: {prenotazione.dataOraPrenotazione} - 
                                Quantita: {prenotazione.quantita}
                            {
                                prenotazione.ingredienti && JSON.parse(prenotazione.ingredienti).length>0?
                                JSON.parse(prenotazione.ingredienti).map((ingtemp, index) => (
                                    <p key={index}> - {ingtemp.quantita} {ingtemp.categoria} {ingtemp.nome}</p>
                                ))
                                :null
                            }
                            </div>
                        )

                        )
                        :
                        <div>Nessuna prenotazione trovata per questo ordine</div>
                    }
                    <div>Totale {ordine.totaleEuro}</div>
                    <div>Pagato {ordine.totalePagato}</div>
                    {ordine.sconto&&ordine.sconto>0&&<div>Sconto {ordine.descrSconto}</div>}
                    <div>Da Pagare {ordine.totaleEuro-ordine.totalePagato}</div> 
                    {ordine.totaleEuro-ordine.totalePagato>0&&<button onClick={() => window.open((ordine.pagaoraurl?ordine.pagaoraurl:"https://annotatio.online/pagamento-utente-popup")+"?datap=ord_"+ordine.numero, "_blank")}>Paga ora</button>}
                </div>
                <div className="col-md-6">
                <div>{ordine.anagrafica.nome} {ordine.anagrafica.cognome} </div>
                <div>{ordine.anagrafica.email} {ordine.anagrafica.telefono}</div>
                <div>Ora consegna desiderata: <strong>{ordine.oraconsegna}</strong></div>
                <div>Consegna a domicilio: {ordine.consegnaDomicilio} -  {ordine.consegnaDomicilio?"SI":"NO"}</div>
                {ordine.consegnaDomicilio?
                    <div>
                        <div>DA CONSEGNARE A </div>
                        <div>{ordine.anagrafica.nome} {ordine.anagrafica.cognome} </div>
                        <div>{ordine.anagrafica.indirizzo}</div>
                        <div> {ordine.anagrafica.citta}</div>
                    </div>
                :
                    <div>Take away</div>
                }
                <div>Note: <strong>{ordine.note}</strong></div>
                {
                        ordine.status
                        &&ordine.status==='A_NUOVO'
                        &&ordine.totalePagato===0
                        ?
                        <div>
                                <Button onClick={annulla} className="btn btn-danger">Annulla ordine</Button>
                        </div>
                        :null
                    }
                </div>
                {ordine.status!=='X_ANNULLATO'
                &&ordine.status!=='Z_CONSEGNATO'?
                <div>
                    <label>Invia un messaggio a {palestra.palestra}</label>
                    <textarea name="messaggio" onChange={(e) => setmessaggio(e.target.value)} value={messaggio} />
                    <Button onClick={inviaMessaggio}>Invia messaggio</Button>
                    </div>
                :null}
            <div>
                {ordine.messaggi?
                ordine.messaggi.map(messaggio=>(

                    <p key={messaggio.id} style={{textAlign:messaggio.mittente?'left':'right'}}>{messaggio.messaggio}
                    <br />
                    <span style={{fontSize:'xx-small'}}>{messaggio.data}</span>
                    </p>
                ))
                
                :null}
            </div>
            </div>
            <hr />
        </div>
        :"NIENTE"
    );
}