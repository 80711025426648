import {createSlice} from '@reduxjs/toolkit';

export const gestoreCalendariSlice = createSlice({
  name: 'gestoreCalendari',
  initialState: {
    calendariocliente: [],
  },
  reducers: {
    setcalendariocliente: (state, calendario) => {
      console.log('setcalendariocliente ' + calendario.payload);
      state.calendariocliente = calendario.payload;
    },
  },
});

export const {setcalendariocliente} = gestoreCalendariSlice.actions;

export default gestoreCalendariSlice.reducer;
