import React, { useEffect, useState } from "react";
import AlimentoSchedadieta from "./AlimentoSchedadieta";
import InputText from "../../util/UI/InputText";
import SelectText from "../../util/UI/SelectText";
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons'
import Text from "../../util/UI/Text";

const listapasti=[
    {name:'COLAZIONE', value:'colazione'},
    {name:'SPUNTINO', value:'spuntino'},
    {name:'PRANZO', value:'pranzo'},
    {name:'CENA', value:'cena'},
]

export default function DettGiornoSchedadieta({giornoscheda, handleChangeGiorni, indexgiorno, handleChangeAlimenti, addriga, listaalimenti, deletegiorno, totkcalpasto}){

    const [alimenti, setalimenti] = useState(giornoscheda.alimenti)

    return (
        
            <tr >
                <td>
                <Text 
                id={'giorno'} label={'Giorno'} name={'giorno'} 
                placeholder={'Num. Giorno'} type={'text'}
                value={giornoscheda.giorno} 
                onChange={(evnt)=>(handleChangeGiorni(indexgiorno, evnt))}
                 />
                 </td>
                 <td>
                 
                <Text
                id={'pasto'} name={'pasto'} 
               
                value={giornoscheda.pasto} 
               
                lista={listapasti}
                 />
                </td>
                <td>
                
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Alimento</th>
                                <th>Qta gr</th>
                                <th>kcal</th>
                                <th>prot.</th>
                                <th>carbo</th>
                                <th>zuccheri</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                {
                    alimenti.map((alimento, indexG) => (
                        <AlimentoSchedadieta key={indexG} alimento={alimento}
                        listaalimenti={listaalimenti}
                        />
                    ))
                }
                
                </tbody>
                </table>
                
                </td>
            </tr>

    );
}