/* eslint-disable react/prop-types */
import React from "react";
import { useEffect } from "react";

export default function Allergeni(props){
    const listaallergeni=["arachidi","crostacei", "fruttasecca", "glutine","latte", "lupini", "molluschi", "pesce", "sedano", "senape"]

    function allergeneCHecked(allergtemp){
        if (props.allergeni&&props.allergeni.length>0){
            return props.allergeni&&props.allergeni.length>0&&props.allergeni.split(',').filter(alle=> alle===allergtemp).length>0
        }
    }

    useEffect(() =>{
       
    },[props.allergeni])

    return(
        <div style={{fontSize:'x-small'}}>
{props.allergeni&&<div>Allergeni</div>}
            {props.allergeni&&listaallergeni.map(allergene => (
               <span key={allergene}>
               {allergeneCHecked(allergene)?
                <span key={allergene}>
                    <img src={"https://annotatio.online/wp-content/uploads/2023/02/"+allergene+".png"} style={{width:20}} alt={allergene} title={allergene} /> &nbsp;
                </span>
            :""}
            </span>
            )
            
            )}
            
        </div>
    );
}