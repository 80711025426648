import axios from "axios";
import serverInfo from '../util/globals';

export async function getcorsi(email,idpalestra, tipocorso){
    //console.log("getcorsi ",email, idpalestra, tipocorso)
    const formData = new FormData();
    formData.append('website', 'annotatio.online');
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',idpalestra);
    formData.append('tipocorso',tipocorso);
    const response = await axios.post(serverInfo.name+"/getCorsiRest",
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );
    //console.log(response.data);
    return response.data;
}

export async function aggiornaCorso(idcorso, nome, descrizione, idtipocorso, utenti, durata, duratagiornaliera,
    luogo, abb, calendariopubblico, prezzo, disponibileda, disponibilea, disponibile, foto1, file, email, palestra){
    const formData = new FormData();
    formData.append('id', idcorso);
    formData.append('nome', nome);
    formData.append('descrizione', descrizione);
    formData.append('tipoCorso', idtipocorso);
    formData.append('utenti', utenti);
    formData.append('durata', durata);
    formData.append('duratagiornaliera', duratagiornaliera);
    formData.append('luogo', luogo);
    formData.append('abb', abb);
    formData.append('calendariopubblico', calendariopubblico);
    formData.append('prezzo', prezzo);
    formData.append('disponibileda', disponibileda);
    formData.append('disponibilea', disponibilea);
    formData.append('disponibile', disponibile);

    formData.append('foto1', foto1?foto1:'');
    formData.append('filename', file&&file.name?file.name:'');
    formData.append('filetype', file&&file.type?file.type:'');
    formData.append('fotoTemp', file&&file.base64?'data:image/png;base64,'+file.base64:'');

    formData.append('website', 'annotatio.online');
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    const response = await axios.post(serverInfo.name+'/aggiornaCorsoRest/'+idcorso,
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}

export async function eliminaCorso(email, palestra, idcorso){
    const formData = new FormData();
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('website','annotatio.online');
    const response = await axios.post(serverInfo.name+'/eliminaCorsoRest/'+idcorso,
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}

export async function cercaCorsi(email, palestra, nomeprodotto){
    const formData = new FormData();
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('website','annotatio.online');
    formData.append('descr',nomeprodotto);
    const response = await axios.post(serverInfo.name+'/cercaCorsi',
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}

export async function caricaIngredienti(email, palestra, idprodottopadre){
    const formData = new FormData();
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('website','annotatio.online');
    formData.append('idpadre',idprodottopadre);
    const response = await axios.post(serverInfo.namesec+'/arch/caricaIngredienti',
    formData,
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
      },
    },
    );

    return response.data;
}

export async function aggiungiIngrediente(email, palestra, idcorsopadre, idcorso){
    const formData = new FormData();
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('website','annotatio.online');
    formData.append('idcorsopadre',idcorsopadre);
    formData.append('idcorso',idcorso);
    formData.append('minvolte',0);
    formData.append('maxvolte',1);
    formData.append('prezzo',0);
    const response = await axios.post(serverInfo.name+'/aggiungiIngrediente',
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}

export async function eliminaIngrediente(email, palestra, idingrediente){
    const formData = new FormData();
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('website','annotatio.online');
    formData.append('idingrediente',idingrediente);
    const response = await axios.post(serverInfo.name+'/eliminaIngrediente',
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}

export async function salvaDatiIngrediente(id, prezzo, minvolte, maxvolte, idcorso, idcorsopadre, palestra){
    const formData = new FormData();
    // formData.append('email',email);
    // formData.append('lic','00000000');
    // formData.append('palestra',palestra);
    // formData.append('website','annotatio.online');
    formData.append('id',id);
    formData.append('prezzo',prezzo);
    formData.append('minvolte',minvolte);
    formData.append('maxvolte',maxvolte);
    formData.append('idcorso',idcorso);
    formData.append('idcorsopadre',idcorsopadre);
    formData.append('palestra',palestra);
    const response = await axios.post(serverInfo.name+'/salvaDatiIngrediente',
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        },
    },
    );

    return response.data;
}