/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPalestra } from "../DAO/palestraDAO";
import { setanagrafica, setPalestraAnagrafica } from "../STORE/reducers/gestoreUtente";
import '../util/config'

export default function AnagraficaAssociata(props){
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const token = useSelector(state => state.gestoreUtente.token);

  const dispatch=useDispatch();

  useEffect(() =>{
        if (props.apriDettaglio
          &&props.apriDettaglio===true){
          apriAnag()
        }
        if (anagrafica
          &&anagrafica.id===props.anagrafica.id){
          selectPalestra();
        }
      },[palestra, email]);

  function apriAnag(){
    async function getpalestreassociate(){
      const paltemp=await getPalestra(props.anagrafica.idpalestra, token);
      console.log(props.anagrafica)
      dispatch(setPalestraAnagrafica(paltemp))
    }
    localStorage.setItem('anagrafica', JSON.stringify(props.anagrafica))
      dispatch(setanagrafica(props.anagrafica));
      getpalestreassociate()
      if (props.link){
        window.location.href=props.link
      }
      }

      function selectPalestra(){
        async function getpalestraassociate(){
          const paltemp=await getPalestra(props.anagrafica.idpalestra, token);
          console.log(props.anagrafica)
          dispatch(setPalestraAnagrafica(paltemp))
        }
        getpalestraassociate();
          }
    
    return (
        <div>
          {props.anagrafica?
          <span>
          <a style={{cursor:'pointer'}} onClick={()=>{ apriAnag();}}>{props.anagrafica.palestra+ ' - ' +props.anagrafica.nome+' '+props.anagrafica.cognome+' ('+props.anagrafica.id+')'}</a>
          
            {anagrafica
            &&anagrafica.id===props.anagrafica.id?
            <span>
              <img alt="selected" src="https://annotatio.online/wp-content/uploads/2022/07/checkedGreen.png" style={{width:"20px"}} />
              </span>
            :null
            }
          </span>
          :null}
        </div>
        );
}