import axios from 'axios';
import '../util/config'
import serverInfo from '../util/globals';

export async function getPlaces(idtipo) {
  const formData = new FormData();
  formData.append('idtipo', idtipo);
  const response = await axios.post(
    serverInfo.name+'/getPlacesPerTipo',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}

export async function getAllPlaces(idpalestra) {
  const formData = new FormData();
  formData.append('palestra', idpalestra);
  formData.append('website', 'annotatio.online');
  const response = await axios.post(
    serverInfo.name+'/getPlaces',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}

export async function getTipi(palestra, filtroidtipi, filtrolnomicalendario, visibili) {
  console.log('gettipi '+palestra)
  const formData = new FormData();
  formData.append('palestra', palestra);
  if (filtroidtipi){
    formData.append('idtipi', filtroidtipi);
  }
  if (filtrolnomicalendario){
    formData.append('nomicalendario', filtrolnomicalendario); //lista di nomi di calendario completi e non parziali
  }
  if (visibili&&visibili===1){
    formData.append('visibile', visibili);
  }
  if (visibili&&visibili===0){
    formData.append('visibile', visibili);
  }
  const response = await axios.post(serverInfo.name+'/getTipiCorsoFilter',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function aggiornaTipoProdotto(email, palestra, idtipo, nome, descrizione, nomecalendario, visibile, ordine, foto, file){

  const formData = new FormData();
  formData.append('id', idtipo);
  formData.append('nome', nome);
  formData.append('descrizione', descrizione);
  formData.append('nomecalendario', nomecalendario?nomecalendario:'');
  formData.append('visibile', visibile&&visibile===true?"1":visibile===false||visibile==='0'?"0":"1");
  formData.append('website', 'annotatio.online');
  formData.append('ordine', ordine?ordine:100);
  formData.append('foto', foto?foto:'');
  //formData.append('fotoTemp', file?(Platform.OS === 'ios' ? file.uri.replace('file://', '') : file.uri):'');
  formData.append('filename', file&&file.fileName?file.fileName:'');
  formData.append('filetype', file&&file.type?file.type:'');
  formData.append('fotoTemp', file&&file.base64?'data:image/png;base64,'+file.base64:''
  //{
    // name: file.name,
    // type: file.type,
    //uri: 
    
    // Platform.OS === 'ios' ? 
    //      file.uri.replace('file://', '')
    //      : file.uri,
  //}
  );
  formData.append('email',email);
  formData.append('lic','00000000');
  formData.append('palestra',palestra);
  const response = await axios.post(serverInfo.name+'/aggiornaTipologiaCorsoRest/'+idtipo,
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}


export async function getNomiCalendari(email, palestra){
  const formData = new FormData();
  formData.append('email',email);
  formData.append('website', 'annotatio.online');
  formData.append('palestra',palestra);
  formData.append('lic','00000000');
  const response = await axios.post(serverInfo.name+"/getNomiCalendari",
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);

return response.data;
}

export async function eliminaTipoProdotto(idtipo, email, palestra){
  const formData = new FormData();
	formData.append('id', idtipo);
	formData.append('website', 'annotatio.online');
	formData.append('email',email);
	formData.append('lic','00000000');
	formData.append('palestra',palestra);
	const response = await axios.post(serverInfo.name+'/eliminaTipologiaCorsoRest/'+idtipo,
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);

return response.data;
}