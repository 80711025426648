/* eslint-disable react/prop-types */
import React from 'react';

import '../util/config'
import { Button } from 'react-bootstrap';

export default class CalendarioMenuClienteItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantita:0,
    }
    this.addQuantita=this.addQuantita.bind(this)
    this.delQuantita=this.delQuantita.bind(this)
    this.update=this.update.bind(this)
  }

  update(e) {
    if(e.target.name==='quantita'
    &&e.target.value>=0){
      this.setState({ [e.target.name]: e.target.value },()=>{
      if (this.state.quantita>=0){
      localStorage.setItem('app-'+this.props.app.id,this.state.quantita)
        this.props.aggiornaCarrello(this.state)
      }
      })
    }
  }

  addQuantita(){
    this.setState({quantita:this.state.quantita+1},()=>{
      if(this.state.quantita>=0){
        localStorage.setItem('app-'+this.props.app.id,this.state.quantita)
        this.props.aggiornaCarrello(this.state)
      }
  })
    
  }

  delQuantita(){
    if (this.state.quantita>0){
    this.setState({quantita:this.state.quantita-1},()=>{
      if (this.state.quantita>=0){
      localStorage.setItem('app-'+this.props.app.id,this.state.quantita)
        this.props.aggiornaCarrello(this.state)
      }
    })
    }
  }

  componentDidMount(){
    this.setState({...this.props})
    this.setState({quantita:localStorage.getItem('app-'+this.props.app.id)?localStorage.getItem('app-'+this.props.app.id):0})
  }

  render() {
    return (
    <div className="col-md-6">
            <div className="card shadow-lg p-3 bg-body rounded" style={{marginBottom:'40px', width:'100%'}}>
              <div className="text-center">
                <img className="card-img-center" 
                src={this.props.app.schedulazione.immagine?this.props.app.schedulazione.immagine:"https://borlotto.it/wp-content/uploads/2022/05/cropped-logoborlotto-1.jpeg"} 
                alt={this.props.title} 
                style={{textAlign:'center', height:'20em', maxWidth:'220px', objectFit:'cover'}} />
                </div>
                {this.props.app?
                <div className="card-body flex-column">
                    <span className="card-title float:right" style={{clean:'all',float:'right',textDecoration:this.props.backgroundColor==='gray'?'line-through':'none'}}>{this.props.app.schedulazione.prezzo}&euro;</span>
                    <h5 className="card-title" style={{textDecoration:this.state.backgroundColor==='gray'?'line-through':'none'}}>{this.props.app.title}</h5>
                    <p style={{fontSize:'smaller', borderTopWidth:'1px', borderTopStyle:'dotted'}}>(open {this.props.app.start})</p>
                    
                    <p className="card-text" >
                    <span dangerouslySetInnerHTML={{ __html: this.props.app.schedulazione.descrizione }} />
                      </p>
                </div>
                :null}
                <div className="card-footer">
                {this.props.email&&this.props.app?
                    <span style={{clean:'all',float:'right'}}>
                         <Button key={this.props.app.delQuantita+'+'} onClick={this.delQuantita}>-</Button>
                          <input style={{width:"3em", padding:'0px', textAlign:'right'}} type="number" name="quantita" min={0} value={this.state.quantita} onChange={this.update} /> 
                        <Button key={this.props.app.delQuantita+'-'} onClick={this.addQuantita}>+</Button>
                    </span>
                    :null}
                </div>
            </div>
            </div>
    );
  }

 
}