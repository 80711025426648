import React from "react";
import { Button } from "react-bootstrap";
import axios from 'axios';
import '../util/config'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class PrenotazioneCliente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                prenotazioni:null
        };
        this.disdici=this.disdici.bind(this)
      }

      componentDidMount(){
        this.setState({...this.props})
      }

      disdici(e){
        e.preventDefault()
        //alert(this.state.idPrenotazione)
         const formData1 = new FormData();
         formData1.append('website', global.config.domain);
         formData1.append('palestra', this.props.palestra.id);
         formData1.append('email',this.props.email);
         formData1.append('lic','000');     
        formData1.append('idAnagrafica',this.props.anagrafica.id);
         formData1.append('idPrenotazione',this.state.prenotazione.id);
         axios.post(serverInfo.namesec+"/abb/annullaPrenotazione",formData1,
         {
             headers: {
               Accept: 'application/json',
               'Content-Type': 'multipart/form-data',
               'Access-Control-Allow-Origin': '*',
               'Access-Control-Allow-Methods': 'POST',
               'Access-Control-Allow-Headers': 'Content-Type, Authorization',
               'Access-Control-Allow-Credentials': 'true',
               'Authorization' :'Bearer '+window.$t
             },
           },)
         .then(response1=>{
             alert(response1.data)
         }).catch(err => {
             if (err.response){
               alert(err.response.data.message);
             }
             else{
               alert(err.response);
             }
        });
    }

render() {
    const rosso={
        backgroundColor:'red', 
        color:'white'
    }
    const verde={
        backgroundColor:'green', 
        color:'yellow'
    }
    return (
        <span>
            {this.state.prenotazione?
                <p className="card-text">
                    <div className="container-fluid form-row">
                        <div className="form-group col-md-4">
                            {this.state.prenotazione.corso+" del "+ this.state.prenotazione.dataOra}
                        </div>
                        <div className="form-group col-md-4">
                            <label>Data e ora prenotazione</label>
                            <input type="text" style={this.state.prenotazione.dataAnnullamento?rosso:verde} className="form-control" value={this.state.prenotazione.dataOraPrenotazione} />
                           {this.state.prenotazione.annullabile?
                                 <Button onClick={this.disdici}>ANNULLA</Button>:
                            "NON ANNULLABILE"}
                        </div>
                        {this.state.prenotazione.dataAnnullamento?
                            <div className="form-group col-md-4">
                                <label>&nbsp;</label>
                                <span style={rosso} className="form-control" >{this.state.prenotazione.dataAnnullamento?"Annullato il "+this.state.prenotazione.dataAnnullamento:""}</span>
                            </div>
                                :""}
                    </div>
                    <hr style={{border: '5px solid blue', borderRadius: '5px'}} />
                </p>
                :null}

        </span>
    );
}
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraAnagrafica,
        email: state.gestoreUtente.emailloggato,
        anagrafica: state.gestoreUtente.anagrafica
    }
  }
  
  export default connect(mapStateToProps, null)(PrenotazioneCliente)