/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import RigaCalendarioGiornaliero from './RigaCalendarioGiornaliero';

export default function CalendarioGiornaliero(props) {

  useEffect(() => {
    var oggi=new Date();
    var oggiString=("0" + oggi.getDate()).slice(-2) + '/' + (("0" + (oggi.getMonth() + 1)).slice(-2))+'/'+oggi.getFullYear();
    if (oggiString===props.data){
      //console.log(oggiString+ " ---- " +props.data)
      //console.log('setindex: '+props.index)
      props.setIndex(props.index);
    }

  },[])


  return (
    <div style={{flexDirection: 'column'}}>
     
      {props.calendario.map(appuntamento =>
        appuntamento.data === props.data ? (
          <RigaCalendarioGiornaliero key={appuntamento.id} app={appuntamento} />
        ) : null,
      )}
    </div>
  );
}
