/* eslint-disable no-cond-assign */
import React, { useEffect, useState } from "react";
import {  getultimascheda} from "../../DAO/schededietaDAO";
import { useSelector } from "react-redux";
import Text from "../../util/UI/Text";
import DettGiornoSchedadieta from "./DettGiornoSchedadieta";
import { getlistaalimenti } from "../../DAO/alimentiDAO";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DettaglioSchedadieta(props){
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

    const [expanded, setExpanded] = useState(false);
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };
 
    const [scheda, setscheda]=useState()
    const [data, setdata]=useState('')
    const [giorni, setgiorni]=useState([])

    let totgiorno =null;

    const [listaalimentiselect, setlistaalimentiselect] = useState([])

    let totkcalpasto = 0

    const [giornidistinti, setgiornidistinti] = useState(new Map())

    function getsommekcal(giorni){
        let sommakcal=0;
        let sommaprote=0;
        let sommacarbo=0;
        let sommazucc=0;
        if (giorni){
            if(Array.isArray(giorni)){
                const uniquegg = [...new Set(giorni.map(item => item.giorno))];
                let mappa=new Map();
                for (var it = uniquegg.values(), val= null; val=it.next().value; ) {
                    let sommagiornokcal=0;
                    let sommagiornoprote=0;
                    let sommagiornocarbo=0;
                    let sommagiornozucc=0;
                    for (let giorno in giorni){
                        if(giorno.giorno===it){
                            sommagiornokcal+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal),0):0).toFixed(2)
                            sommagiornoprote+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine),0):0).toFixed(2)
                            sommagiornocarbo+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati),0):0).toFixed(2)
                            sommagiornozucc+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi),0):0).toFixed(2)
                        }
                    }
                    mappa.set(it,{kcal:sommagiornokcal, prote: sommagiornoprote, carbo:sommagiornocarbo, zucc:sommagiornozucc});
                }
                setgiornidistinti(...mappa)
                //calcola tot
                giorni.map( giorno=> {
                    sommakcal+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2)
                    sommaprote+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2)
                    sommacarbo+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2)
                    sommazucc+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2)
                })
            }
            else{
                sommakcal=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2)
                sommaprote=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2)
                sommacarbo=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2)
                sommazucc=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2)

            }
        }
        return {kcal:sommakcal, prote:sommaprote, carbo:sommacarbo, zucc:sommazucc}
    }

    function caricalistaalimenti(){
        async function caricalista(){
            const result = await getlistaalimenti(palestra.id)

            //let listaalimentiselecttemp=[]
            //result.map(alim =>listaalimentiselecttemp.push({name:alim.nome, value:alim.id}) )
            setlistaalimentiselect(result)
        }
        caricalista()
    }

    function cariacscheda(){
        async function carica(){ 
            const result=await getultimascheda(anagrafica.id)
            setscheda(result)
            setdata(result.data)
            console.log(result.pianoalimentare)
            const resultgiorni=JSON.parse(result.pianoalimentare)

            if (resultgiorni&&Array.isArray(resultgiorni)&&resultgiorni.length>0){
                setgiorni(resultgiorni)
            }
            console.log(resultgiorni)
        }
        carica()
    }

    function stamptotgiorno(index){
        totgiorno=null
        if (index>=0&&(index===giorni.length-1||giorni[index].giorno!==giorni[index+1].giorno)){
                    let sommakcal=0;
                    let sommaprote=0;
                    let sommacarbo=0;
                    let sommazucc=0;
                    
                giorni.map( giorno=> {
                    if (giorno.giorno===giorni[index].giorno){
                        console.log('sommakcal '+sommakcal)
                        sommakcal=parseFloat(sommakcal)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2))
                        sommaprote=parseFloat(sommaprote)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2))
                        sommacarbo=parseFloat(sommacarbo)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2))
                        sommazucc=parseFloat(sommazucc)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2))
                    }
                })
                totgiorno={giorno: giorni[index].giorno, kcal:sommakcal, prote:sommaprote, carbo:sommacarbo, zucc:sommazucc}
        }
        else{
            console.log('niente')
        }
    }

    useEffect(() => {
        cariacscheda()
        caricalistaalimenti()
    },[props.id])

    return(
        <div className="container-fluid">
            <h4>Dettaglio Scheda Dieta</h4>
            {scheda&&
            <div>
            
            <div className="row">
                <Text bootstrapsize={"col-md-3"} id={"data"} label={"Id Scheda"} value={scheda.id} />
                <Text bootstrapsize={"col-md-4"} id={"data"} label={"Anagrafica"} value={anagrafica.nome + anagrafica.cognome} />
            </div>
            <div className="row">
                <Text bootstrapsize={'col-md-4'}
                id={'data'} label={'Data'} name={'data'} 
                placeholder={'Data'} onChange={(e) => setdata(e.target.value)} type={'date'}
                value={data} 
                 />
            </div>
           
                
            {giorni&&Array.isArray(giorni)&&giorni.map((giornoscheda, index) => (
                <span key={index}>
                <Accordion key={index} expanded={expanded === index} onChange={(event,isExpanded) => handleChange(index, event, isExpanded)}>
                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={index+"bh-content"}
                                                    id={index+"bh-header"}>
                                                        <Typography sx={{ width: '33%', flexShrink: 0, backgroundColor:'yellowgreen' }}>
                                                        Giorno {giornoscheda.giorno} {giornoscheda.pasto}
                                                        </Typography>
                                                        <Typography sx={{ width: '60%', flexShrink: 0, backgroundColor:'yellowgreen' }}>
                                                            <div className="row">
                                                        <Text label="kcal" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda).kcal} /> 
                                                        <Text label="prote" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda).prote} /> 
                                                        <Text label="carbo" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda).carbo} />
                                                        <Text label="zuccheri" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda).zucc} />
                                                        </div>
                                                        </Typography>
                                                        
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                <DettGiornoSchedadieta key={index} giornoscheda={giornoscheda} 
                                    listaalimenti={listaalimentiselect}
                                   indexgiorno={index} 
                                    
                                    totkcalpasto={totkcalpasto}/>
                                </AccordionDetails>
                </Accordion>
                {stamptotgiorno(index)}
                <Text label={totgiorno&&totgiorno.giorno?"TOT. GIORNO "+totgiorno.giorno+": kcal "+totgiorno.kcal+", proteine: "+totgiorno.prote+", carbo: "+totgiorno.carbo+", zucc:"+totgiorno.zucc:''} />
                </span>
            ))}
            
          
        </div>}
        </div>
    );
}