import React from "react";

export default function Text({bootstrapsize, label, id, value, onClick}){
 
    return (
        <div className={"form-group "+bootstrapsize} onClick={onClick}>
            {label&&<label htmlFor={id}>{label} &nbsp;</label> }
            {label?
            <strong><i>
                {value}
            </i></strong>
            :value
            }
        </div>
    );
}