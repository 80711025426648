/* eslint-disable no-inner-declarations */
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acquista } from '../DAO/carrelloDAO';
import { aggiornaprodotti, setprodotti } from '../STORE/reducers/gestoreCarrello';
import LoadMainThings from '../util/LoadMainThings';

export default function Carrello(props){

    const [showLoadingState,setShowLoadingState] = useState(false)
    const prodotti = useSelector(state => state.gestoreCarrello.prodotti);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

    const [consegna, setconsegna] = useState('sede');
    const [nome, setnome] = useState(anagrafica.nome);
    const [cognome, setcognome] = useState(anagrafica.cognome);
    const [indirizzo, setindirizzo] = useState(anagrafica.indirizzo);
    const [cap, setcap] = useState(anagrafica.cap);
    const [citta, setcitta] = useState(anagrafica.citta);
    const [oraconsegna, setoraconsegna] = useState(null);
    const [note, setnote] = useState('');
    const [showdomicilio, setshowdomicilio] = useState(false);
    const [mostrariepilogoordine, setmostrariepilogoordine] =useState(false);
    const [ordinedapagare, setordinedapagare] = useState({});
    const dispatch=useDispatch();

    function setquantita(prodotto, quantita){
        const prodottoincarrello={prodotto: prodotto, quantita: quantita, idanagrafica:anagrafica.id}
        dispatch(aggiornaprodotti(prodottoincarrello));
    }

    function setquantitaProdPers(prodottopersonalizzato, quantita){
        const prodottoincarrello={prodpers: prodottopersonalizzato, quantita: quantita, idanagrafica:anagrafica.id}
        dispatch(aggiornaprodotti(prodottoincarrello));
    }

    useEffect(() => {
       setnome(anagrafica.nome);
        setcognome(anagrafica.cognome);
        setindirizzo(anagrafica.indirizzo);
        setcap(anagrafica.cap);
        setcitta(anagrafica.citta);
    },[anagrafica])

    function checkoraconsegna(){
        //l'ora di consegna deve essere come minimo 20min dopo l'apertura
        //20 minuti dopo l'orario attuale
        //20 min prima della chiusura
        //se l'ora attuale è inferiore all'ora di apertura allora ora di consenga minima = ora di apertura + 20min
        //se l'ora attuale é superiore all'(ora di chiusura - 20min) allora ordine non possibile

    const orarioconsegnaMinMattina=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura1)+ 20*60000)
    const orarioconsegnaMaxattina=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura1)- 20*60000)
    const orarioconsegnaMinSera=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura2)+ 20*60000)
    const orarioconsegnaMaxSera=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura2)- 20*60000)

    //get orario attuale
    const now = new Date();
    const consegnadaora = new Date(Date.parse('01/01/2000 '+ now.getHours() + ':' + now.getMinutes()+':00')+ 20*60000)
    const oradesiderata = Date.parse('01/01/2000 '+ oraconsegna+':00')
    if(
        (oradesiderata<orarioconsegnaMinMattina||oradesiderata>orarioconsegnaMaxattina)
        &&(oradesiderata<orarioconsegnaMinSera||oradesiderata>orarioconsegnaMaxSera)
        )
    {
      alert(
      'Orario desiderato non valido: gli ordini vengono eseguiti il mattino tra le '+orarioconsegnaMinMattina.getHours()+':'+orarioconsegnaMinMattina.getMinutes()+
      ' e le '+orarioconsegnaMaxattina.getHours()+':'+orarioconsegnaMaxattina.getMinutes()+' e la sera tra le '
      +orarioconsegnaMinSera.getHours()+":"+orarioconsegnaMinSera.getMinutes()+' e le '+orarioconsegnaMaxSera.getHours()+":"+orarioconsegnaMaxSera.getHours());
      setShowLoadingState(false);
      return false;
    }


    if(oradesiderata<consegnadaora){
      alert('Orario di ordinazione minimo: '+consegnadaora.getHours()+":"+consegnadaora.getMinutes())
      setShowLoadingState(false);
      return false;
    }
    return true;
  }

  

  function mostraRiepilgoOrdine(){
    return (
        <div style={{flex:1}}>
        {mostrariepilogoordine&& 
            <div 
            
            style={{flex:1}}
            >
                    <p>Ordine numero {ordinedapagare.numero} effettuato correttamente</p>
                    <button onClick={() => window.location.href=((ordinedapagare.pagaoraurl?ordinedapagare.pagaoraurl:"https://annotatio.online/pagamento-utente-popup")+"?datap=ord_"+ordinedapagare.numero)}>Paga ora</button>
                    <button onClick={() => window.location.href=('/ordini')}>Vai ai tuoi ordini</button>
                    <button icon="door-open" onClick={() => setmostrariepilogoordine(false)}>Chiudi</button>
            </div>
        }
        </div>
    );
  }

    function acquistadacarrello(){
        setShowLoadingState(true);
        if (prodotti&&Array.isArray(prodotti)&&prodotti.length>0){
            if (!oraconsegna || oraconsegna===''){
                alert('Ora di consegna desiderata non specificata');
                setShowLoadingState(false);
                return;
            }
            
            if (checkoraconsegna()){
                
                    async function compra(){
                        try{
                        const ordine = await acquista(anagrafica.email, 
                        anagrafica.idpalestra, 
                        JSON.stringify(prodotti), 
                        consegna, 
                        nome, 
                        cognome,
                        indirizzo, 
                        cap, 
                        citta, 
                        note, 
                        oraconsegna);
                        //console.log(ordine);
                        if (ordine && ordine.id){

                            setordinedapagare(ordine);
                            setmostrariepilogoordine(true);
                            localStorage.removeItem('carrello');
                            dispatch(setprodotti([]));
                        }
                        setShowLoadingState(false);
                    
                } catch (error) {
                    //console.log('Errore: ');
                    //console.log(error.response.data.message);
                    alert(
                    error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : error,
                    );
                    setShowLoadingState(false);
                }}
                    compra();
                            
            }
        
         }
         
    }

    function elimina(prodotto){
        var prodottifiltrato;
        if (prodotto.prodotto){
            prodottifiltrato=prodotti.filter(prod =>prod.prodpers||prod.schedulazione||prod.prodotto.id!==prodotto.prodotto.id)
            dispatch(setprodotti(prodottifiltrato));
        }
        else if (prodotto.schedulazione){
            prodottifiltrato=prodotti.filter(prod =>prod.prodpers||prod.prodotto||prod.schedulazione.id!==prodotto.schedulazione.id)
            dispatch(setprodotti(prodottifiltrato));
        }
        else{
            prodottifiltrato=prodotti.filter(prod =>prod.prodotto||prod.schedulazione||prod.prodpers.id!==prodotto.prodpers.id)
            dispatch(setprodotti(prodottifiltrato));
        }
    }
    
    //function calcolatotale(){
        let totale=0;
        
        prodotti.map(prod => {
            if (prod.prodotto){
                totale+=prod.prodotto.prezzo*prod.quantita;
            }
            else if (prod.schedulazione){
                totale+=prod.schedulazione.schedulazione.prezzo;
            }
            else if(prod.prodpers){
                totale+=getTOTProdPers(prod);
            }
            
        }
        );
    //}

    function getTOTProdPers(prod){
        let sum=0;
        let i=0;
        //console.log(prodpers);
        for (i=0; i<prod.prodpers.ingredienti.length;+i++){
            //console.log(prodpers.ingredienti[i]);
            sum+=prod.prodpers.ingredienti[i].prezzo*prod.prodpers.ingredienti[i].quantita;
        }
        //sum = prodpers.ingredienti.map(ingr => sum+= parseFloat(parseFloat(ingr.prezzo)*parseFloat(ingr.quantita)));
        sum = sum*(prod.quantita?(prod.quantita):1);
        return sum
    }
    
    function getModalDomicilio(){
        return (
            <div>
            {consegna==='domicilio'&&showdomicilio&&<div 
            >
            <div style={{width:'100%'}}>
                    <div style={{width:'100%'}}>
                        <label style={{display: 'inline-block',width: 100}}>Nome</label>
                        <input type="text"  value={nome} onChange={(e) => setnome(e.target.value)} />
                        
                    </div>
                    <div >
                    <label style={{display: 'inline-block',width: 100}}>Cognome</label>
                        <input type="text"  name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />
                        
                    </div>
                    <div >
                        <label style={{display: 'inline-block',width: 100}}>Indirizzo</label>
                    <textarea type="text" label="Indirizzo" name="indirizzo" value={indirizzo} onChange={(e) => setindirizzo(e.target.value)} />
                    
                    </div>
                        <div >
                            <label style={{display: 'inline-block',width: 100}}>cap</label>
                        <input type="text" label="cap" name="cap" value={cap} onChange={(e) => setcap(e.target.value)}/>
                        
                        </div>
                        <div >
                        <label style={{display: 'inline-block' ,width: 100}}>citta</label>
                        <input type="text" label="citta" name="citta" value={citta} onChange={(e) => setcitta(e.target.value)} />
                        
                        </div>

                    <button icon="door-open" onClick={()=> setshowdomicilio(false)}>Chiudi</button>
                    </div>
            </div>}
            </div>
        );
    }

    function gerOraConsegnaMinima(){
        const now = new Date();
        let consegnadaora = new Date(Date.parse('01/01/2000 '+ now.getHours() + ':' + now.getMinutes()+':00')+ 20*60000)
        if (palestra&&palestra.id&&palestra.orarioapertura1){
            const orarioconsegnaMinMattina=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura1)+ 20*60000)
            const orarioconsegnaMaxattina=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura1)- 20*60000)
            const orarioconsegnaMinSera=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura2)+ 20*60000)
            const orarioconsegnaMaxSera=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura2)- 20*60000)
    
            if (consegnadaora<orarioconsegnaMinMattina){
                consegnadaora=orarioconsegnaMinMattina
            }
            else if (consegnadaora>orarioconsegnaMaxattina&&consegnadaora<orarioconsegnaMinSera){
                consegnadaora=orarioconsegnaMinSera
            }
            else if (consegnadaora>orarioconsegnaMaxSera){
                consegnadaora=orarioconsegnaMaxSera
            }
        }
        setoraconsegna(consegnadaora.getHours()+":"+consegnadaora.getMinutes())
        return ""+consegnadaora.getHours()+":"+consegnadaora.getMinutes()
      }

    useEffect(()=> {
        gerOraConsegnaMinima();
    },[palestra])
    
    return (
        <span>
            <LoadMainThings email={props.email} nascondicarrello={true} />
            <div>
            {mostraRiepilgoOrdine()}            

            {!prodotti || prodotti.length===0 ? 
            <span>Carrello vuoto</span>
            :
            <span>
                <div className='row'>
                    <div className='col'>
                        <fieldset><legend>Carrello</legend>
                        <table style={{borderWidth: 2, borderColor: '#c8e1ff', borderStyle:'solid'}} >
                            <thead>
                                <tr>
                                    <th style={{textAlign:'center'}}>Prodotto</th>
                                    <th style={{textAlign:'center'}}>Q.ta</th>
                                    <th style={{textAlign:'center'}}>Tot.</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {prodotti.map((prodotto,index) => 
                            <tr key={index}>
                                {prodotto.schedulazione && 
                                <>
                                    <td>{prodotto.schedulazione.title}</td>
                                    <td style={{textAlign:'center'}}>{prodotto.quantita}</td>
                                    <td style={{textAlign:'right'}}>{(prodotto.schedulazione.schedulazione.prezzo)*prodotto.quantita+'€'}</td>
                                    <td><button className="fas fa-trash" style={{float:'right'}} onClick={() => elimina(prodotto)}></button></td>
                                </>}

                                {prodotto.prodotto && 
                                <>
                                <td>{prodotto.prodotto.nome}</td>
                                <td style={{textAlign:'center'}}>
                                    {prodotto.quantita>1&&<button onClick={()=>setquantita(prodotto.prodotto,prodotto.quantita-1)}>-</button>}
                                        {prodotto.quantita}
                                        <button onClick={()=>setquantita(prodotto.prodotto,prodotto.quantita+1)}>+</button>
                                </td>
                                <td style={{textAlign:'right'}}>{(prodotto.prodotto.prezzo)*prodotto.quantita+'€'}</td>
                                <td><button className="fas fa-trash"  style={{float:'right'}} onClick={() => elimina(prodotto)}></button></td>
                                </>}

                                {prodotto.prodpers && 
                                <>
                                <td>
                                    {prodotto.prodpers.ingredienti[0].nomepadre}
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ingr.</th>
                                                <th>prezzo</th>
                                                <th>q.ta</th>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            prodotto.prodpers.ingredienti.map(ingr=>
                                                <tr key={ingr.id}>
                                                    <td>{ingr.nome}</td>
                                                    <td>{ingr.prezzo}</td>
                                                    <td>{ingr.quantita}</td>
                                                </tr>
                                        )}
                                        </tbody>                                       
                                    </table>
                                </td>
                                <td>
                                {prodotto.quantita>1&&<button onClick={()=>setquantitaProdPers(prodotto.prodpers,prodotto.quantita-1)}>-</button>}
                                    {prodotto.quantita}
                                    <button onClick={()=>setquantitaProdPers(prodotto.prodpers,prodotto.quantita+1)}>+</button>
                                </td>
                                <td>{getTOTProdPers(prodotto)+'€'}</td>
                                <td><button className="fas fa-trash" onClick={() => elimina(prodotto)}></button></td>
                                </>
                                }
                                </tr>
                            )}
                            <tr><td></td><td>TOTALE</td><td>{totale+'€'}</td><td></td></tr>
                            </tbody>
                        </table>
                        </fieldset>
                    </div>
                    <div className='col'>
                            <fieldset>
                                <legend>Consegna</legend>
                                <label>Ritiro in sede
                                <input type={'radio'} name="consegna" value="sede" checked={consegna==='sede'} onChange={() => setconsegna('sede') } />
                                </label>
                                <label>Consegna a domicilio
                                <input type={'radio'} name="consegna" value="domicilio" checked={consegna==='domicilio'} onChange={() => {setconsegna('domicilio'); }} />
                                </label>
                                <br />
                                <label>Ora consegna desiderata
                                <input type="time" value={oraconsegna} onChange={(e) => {setoraconsegna(e.target.value)}}  />
                                </label>
                            </fieldset>
          {consegna==='domicilio' && <div style={{backgroundColor:'white', borderColor:'black', borderStyle:'solid', borderWidth:1}}>
                <p> {nome} {cognome} </p>
                <p> {indirizzo}</p>
                <p> {cap} {citta} </p>
                <button onClick={() =>setshowdomicilio(!showdomicilio)}>Modifica domicilio</button>
                {getModalDomicilio()}
                </div>
            }
          <div>
            <label>Note
                <textarea rows={2} style={{width:'100%'}}  value={note} onChange={(e) => setnote(e.target.value)}>{note}</textarea>
                </label>
          </div>
            
       </div>
       </div>
       <div className="row">
        <div className='col'>
        {
            !showLoadingState && 
            <div>
                <h4>Si prega di specificare nel campo &quot;Note&quot; eventuali intolleranze e allergie alimentari</h4>
            <button onClick={() => acquistadacarrello()}><i className="fas fa-utensils"></i>Conferma ordine</button>
            <button onClick={() =>window.location.href='/'}>Continua con gli acquisiti</button>
            </div>
            }
             {
            showLoadingState && 
            <div>
            <h4>ATTENDERE PREGO ... Registrazione dell&apos;ordine in corso ...</h4>
            </div>
            }
        </div>
       </div>
        </span>
        }
        </div>
        </span>
    );
}