/* eslint-disable no-inner-declarations */
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acquista } from '../DAO/carrelloDAO';
import { aggiornaprodotti, setprodotti } from '../STORE/reducers/gestoreCarrello';
import {navigate} from "hookrouter"
import Allergeni from '../calendario/Allergeni';
import { caricasconto } from '../DAO/scontoDAO';
export default function CarrelloPreview(){

    const [showLoadingState,setShowLoadingState] = useState(false)
    const prodotti = useSelector(state => state.gestoreCarrello.prodotti);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

    const [consegna, setconsegna] = useState('sede');
    const [nome, setnome] = useState(anagrafica.nome);
    const [cognome, setcognome] = useState(anagrafica.cognome);
    const [indirizzo, setindirizzo] = useState(anagrafica.indirizzo);
    const [cap, setcap] = useState(anagrafica.cap);
    const [citta, setcitta] = useState(anagrafica.citta);
    const [telefono, settelefono] = useState(anagrafica.telefono);
    const [oraconsegna, setoraconsegna] = useState(null);
    const [note, setnote] = useState('');
    const [showcarrello, setshowcarrello] = useState(false);
    const [keytorefresh, setketorefresh] = useState(new Date().getTime())
    const [codicesconto, setcodicesconto] = useState()
    const [sconto, setsconto] = useState()

    const dispatch=useDispatch();

    function setquantita(prodotto, quantita){
        const prodottoincarrello={prodotto: prodotto, prezzo: prodotto.prezzo, quantita: quantita, idanagrafica:anagrafica.id}
        dispatch(aggiornaprodotti(prodottoincarrello));
    }

    function setquantitaProdPers(prodottopersonalizzato, prodotto,quantita){
        const prodottoincarrello={prodpers: prodottopersonalizzato,prezzo: prodotto.prezzo,quantita: quantita, idanagrafica:anagrafica.id}
        dispatch(aggiornaprodotti(prodottoincarrello));
    }

    function applicasconto(){
        async function getsconto(){
            const result=await caricasconto(anagrafica.email, palestra.id,codicesconto, anagrafica.id)
            if (result){
                setsconto(result)
            }
            else{
                alert("Codice sconto "+codicesconto+" non valido")
            }
        }
        getsconto();
    }

    useEffect(() => {
       setnome(anagrafica.nome);
        setcognome(anagrafica.cognome);
        setindirizzo(anagrafica.indirizzo);
        setcap(anagrafica.cap);
        setcitta(anagrafica.citta);
    },[anagrafica])

    function checkoraconsegna(){
        //l'ora di consegna deve essere come minimo 20min dopo l'apertura
        //20 minuti dopo l'orario attuale
        //20 min prima della chiusura
        //se l'ora attuale è inferiore all'ora di apertura allora ora di consenga minima = ora di apertura + 20min
        //se l'ora attuale é superiore all'(ora di chiusura - 20min) allora ordine non possibile

    const orarioconsegnaMinMattina=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura1)+ 20*60000)
    const orarioconsegnaMaxattina=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura1)- 20*60000)
    const orarioconsegnaMinSera=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura2)+ 20*60000)
    const orarioconsegnaMaxSera=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura2)- 20*60000)

    //get orario attuale
    const now = new Date();
    const consegnadaora = new Date(Date.parse('01/01/2000 '+ now.getHours() + ':' + now.getMinutes()+':00')+ 20*60000)
    const oradesiderata = Date.parse('01/01/2000 '+ oraconsegna+':00')
    if(
        (oradesiderata<orarioconsegnaMinMattina||oradesiderata>orarioconsegnaMaxattina)
        &&(oradesiderata<orarioconsegnaMinSera||oradesiderata>orarioconsegnaMaxSera)
        )
    {
      alert(
      'Orario desiderato non valido: gli ordini vengono eseguiti il mattino tra le '+orarioconsegnaMinMattina.getHours()+':'+orarioconsegnaMinMattina.getMinutes()+
      ' e le '+orarioconsegnaMaxattina.getHours()+':'+orarioconsegnaMaxattina.getMinutes()+' e la sera tra le '
      +orarioconsegnaMinSera.getHours()+":"+orarioconsegnaMinSera.getMinutes()+' e le '+orarioconsegnaMaxSera.getHours()+":"+orarioconsegnaMaxSera.getHours());
      setShowLoadingState(false);
      return false;
    }


    if(oradesiderata<consegnadaora){
      alert('Orario di ordinazione minimo: '+consegnadaora.getHours()+":"+consegnadaora.getMinutes())
      setShowLoadingState(false);
      return false;
    }
    return true;
  }

    function acquistadacarrello(){
        setShowLoadingState(true);
        if (prodotti&&Array.isArray(prodotti)&&prodotti.length>0){
            if (!oraconsegna || oraconsegna===''){
                alert('Ora di consegna desiderata non specificata');
                setShowLoadingState(false);
                return;
            }
            
            if (checkoraconsegna()){
                
                    async function compra(){
                        try{
                        const ordine = await acquista(anagrafica.email, 
                        anagrafica.idpalestra, 
                        JSON.stringify(prodotti), 
                        consegna, 
                        nome, 
                        cognome,
                        indirizzo, 
                        cap, 
                        citta, 
                        note, 
                        oraconsegna, telefono, codicesconto?codicesconto:'');
                        //console.log(ordine);
                        if (ordine && ordine.id){
                            
                            localStorage.removeItem('carrello');
                            navigate('/annotatioc-riepord/'+ordine.id+"/",true)
                            dispatch(setprodotti([]));
                        }
                        setShowLoadingState(false);
                    
                } catch (error) {
                    //console.log('Errore: ');
                    //console.log(error.response.data.message);
                    alert(
                    error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : error,
                    );
                    setShowLoadingState(false);
                }}
                if(consegna==='domicilio'){
                    if(!nome){
                        setShowLoadingState(false)
                        alert("Indicare il nome a cui effettuare la consegna")
                        
                        return;
                    }
                    if(!cognome){
                        setShowLoadingState(false)
                        alert("Indicare il cognome a cui effettuare la consegna")
                        
                        return;
                    }
                    if(!indirizzo){
                        setShowLoadingState(false)
                        alert("Indicare l'indirizzo a cui effettuare la consegna")
                        
                        return;
                    }
                }
                if(!telefono&&!anagrafica.telefono){
                    alert("Indicare il numero di telefono")
                    setShowLoadingState(false)
                    return;
                }
                compra();
                            
            }
        
         }
         
    }

    function elimina(prodotto){
        var prodottifiltrato;
        if (prodotto.prodotto){
            prodottifiltrato=prodotti.filter(prod =>prod.prodpers||prod.schedulazione||prod.prodotto.id!==prodotto.prodotto.id)
            dispatch(setprodotti(prodottifiltrato));
        }
        else if (prodotto.schedulazione){
            prodottifiltrato=prodotti.filter(prod =>prod.prodpers||prod.prodotto||prod.schedulazione.id!==prodotto.schedulazione.id)
            dispatch(setprodotti(prodottifiltrato));
        }
        else{
            prodottifiltrato=prodotti.filter(prod =>prod.prodotto||prod.schedulazione||prod.prodpers.id!==prodotto.prodpers.id)
            dispatch(setprodotti(prodottifiltrato));
        }
    }
    
    //function calcolatotale(){
        let totale=0;
        let totalescontato=0;
        prodotti.map(prod => {
            if (prod.prodotto){
                totale+=prod.prodotto.prezzo*prod.quantita;
            }
            else if (prod.schedulazione){
                totale+=prod.schedulazione.schedulazione.prezzo;
            }
            else if(prod.prodpers){
                totale+=getTOTProdPers(prod);
            }

            if (sconto&&sconto.id){
                totalescontato=totale-(totale*sconto.valoreSconto/100);
            }
        }
        );
    //}

    function getTOTProdPers(prod){
        let sum=0;
        let i=0;
        //console.log(prodpers);
        //for (i=0; i<prod.prodpers.ingredienti.length;+i++){
            //console.log(prodpers.ingredienti[i]);
            //sum+=((prod.prodpers.ingredienti[i].prezzo*prod.prodpers.ingredienti[i].quantita));
            
        //}
        sum+=prod.prodpers.totale
        //sum+=prod.prodpers.prodotto.prezzo;
        //sum = prodpers.ingredienti.map(ingr => sum+= parseFloat(parseFloat(ingr.prezzo)*parseFloat(ingr.quantita)));
        sum = sum*(prod.quantita?(prod.quantita):1);
        return sum
    }
    
    function getModalDomicilio(){
        return (
            <div>
            {consegna==='domicilio'&&<div 
            >
            <div style={{width:'100%'}}>
                    <div style={{width:'100%'}}>
                        <label style={{display: 'inline-block',width: 100}}>Nome</label>
                        <input type="text"  value={nome} onChange={(e) => setnome(e.target.value)} />
                        
                    </div>
                    <div >
                    <label style={{display: 'inline-block',width: 100}}>Cognome</label>
                        <input type="text"  name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />
                        
                    </div>
                    <div >
                    <label style={{display: 'inline-block',width: 100}}>Telefono</label>
                        <input type="text"  name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} />
                        
                    </div>
                    <div >
                        <label style={{display: 'inline-block',width: 100, verticalAlign:'top'}}>Indirizzo</label>
                    <textarea type="text" label="Indirizzo" name="indirizzo" value={""+indirizzo} onChange={(e) => setindirizzo(e.target.value)} />
                    
                    </div>
                        <div >
                            <label style={{display: 'inline-block',width: 100}}>cap</label>
                        <input type="text" label="cap" name="cap" value={70021} disabled={true} />
                        
                        </div>
                        <div >
                        <label style={{display: 'inline-block' ,width: 100}}>citta</label>
                        <input type="text" label="citta" name="citta" value={"Acquaviva delle Fonti"} disabled={true} />
                        
                        </div>
                    </div>
            </div>}
            </div>
        );
    }

    function gerOraConsegnaMinima(){
        const now = new Date();
        let consegnadaora = new Date(Date.parse('01/01/2000 '+ now.getHours() + ':' + now.getMinutes()+':00')+ 20*60000)
        if (palestra&&palestra.id&&palestra.orarioapertura1){
            const orarioconsegnaMinMattina=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura1)+ 20*60000)
            const orarioconsegnaMaxattina=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura1)- 20*60000)
            const orarioconsegnaMinSera=new Date(Date.parse('01/01/2000 '+ palestra.orarioapertura2)+ 20*60000)
            const orarioconsegnaMaxSera=new Date(Date.parse('01/01/2000 '+ palestra.orariochiusura2)- 20*60000)
    
            if (consegnadaora<orarioconsegnaMinMattina){
                consegnadaora=orarioconsegnaMinMattina
            }
            else if (consegnadaora>orarioconsegnaMaxattina&&consegnadaora<orarioconsegnaMinSera){
                consegnadaora=orarioconsegnaMinSera
            }
            else if (consegnadaora>orarioconsegnaMaxSera){
                consegnadaora=orarioconsegnaMaxSera
            }
        }
        setoraconsegna(consegnadaora.getHours()+":"+consegnadaora.getMinutes())
        return ""+consegnadaora.getHours()+":"+consegnadaora.getMinutes()
      }

    useEffect(()=> {
        gerOraConsegnaMinima();
    },[palestra])
    
    return (
        <span key={keytorefresh} style={{backgroundColor:'white'}}>
            <button className="fas fa-shopping-cart" style={{backgroundColor:'white'}} onClick={() => setshowcarrello(!showcarrello)}>Carrello</button>
            {
            showcarrello &&
            <div style={{backgroundColor:'white'}}>
                            

                {!prodotti || prodotti.length===0 ? 
                <span>Carrello vuoto</span>
                :
                <span>
                    <div className='row' style={{backgroundColor: 'white'}}>
                        <div className='col-md-7'>
                            <fieldset><legend>Carrello</legend>
                            
                            <table className="table table-striped " style={{borderWidth: 2, borderColor: '#c8e1ff', borderStyle:'solid', width:'100%'}} >
                                <thead>
                                    <tr>
                                        <th className='w-50' style={{textAlign:'center'}}>Prodotto</th>
                                        <th className='w-25'></th>
                                        <th className='w-25'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                prodotti.map((prodotto,index) => 
                                <>
                                    {
                                        prodotto.schedulazione && 
                                        <tr key={index}>
                                        <td className='w-50'>{prodotto.schedulazione.title}
                                            {prodotto.quantita}
                                        </td>
                                        <td style={{ verticalAlign:'top'}}>
                                        {(prodotto.schedulazione.schedulazione.prezzo)*prodotto.quantita+'€'}
                                        </td>

                                        <td style={{textAlign:'', verticalAlign:'top'}}><button className="fas fa-trash" style={{float:'right'}} onClick={() => elimina(prodotto)}></button></td>
                                        </tr>
                                    }

                                    {
                                        prodotto.prodotto && !prodotto.prodpers &&
                                        <tr key={index}>
                                        <td>
                                                {prodotto.prodotto.foto1 && <img src={prodotto.prodotto.foto1} style={{width:50}} />}{prodotto.prodotto.nome}<br/>
                                                <Allergeni allergeni={prodotto.prodotto.allergeni} />
                                        </td>
                                        
                                        <td style={{verticalAlign:'top'}}>
                                            <div className='row'>
                                            <div className="col-sm-8" >
                                                    {prodotto.quantita>1&&
                                                    <button onClick={()=>setquantita(prodotto.prodotto,prodotto.quantita-1)}>-</button>}
                                                        &nbsp;{prodotto.quantita}&nbsp;
                                                    <button onClick={()=>setquantita(prodotto.prodotto,prodotto.quantita+1)}>+</button>
                                                </div>
                                            <div className="col-sm-4" style={{textAlign:'right'}}>
                                            {(prodotto.prodotto.prezzo)*prodotto.quantita+'€'}
                                            </div>
                                            </div>
                                        </td>
                                        
                                        
                                        <td style={{ verticalAlign:'top',margin:5}}><button className="fas fa-trash"  onClick={() => elimina(prodotto)}></button></td>
                                        </tr>
                                    }

                                    {prodotto.prodpers && 
                                        <>
                                        <tr key={index}>
                                    <td>
                                    {prodotto.prodpers.prodotto.foto1 && <img src={prodotto.prodpers.prodotto.foto1} style={{width:50}} />}
                                        {prodotto.prodpers.ingredienti[0].nomepadre}
                                      
                                                
                                           
                                        </td>
                                        <td style={{verticalAlign:'top'}}>
                                        <div className='row'>
                                        <div className="col-sm-8" >
                                    {prodotto.quantita>1&&<button onClick={()=>setquantitaProdPers(prodotto.prodpers, prodotto,prodotto.quantita-1)}>-</button>}
                                        &nbsp;{prodotto.quantita}&nbsp;
                                        <button onClick={()=>setquantitaProdPers(prodotto.prodpers,prodotto,prodotto.quantita+1)}>+</button>
                                        </div>
                                        <div className="col-sm-4" style={{textAlign:'right'}}>
                                        {getTOTProdPers(prodotto)+'€'}
                                        </div>
                                        </div>
                                    </td>
                                    <td style={{margin:5, verticalAlign:'top'}}><button className="fas fa-trash" onClick={() => elimina(prodotto)}></button></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                        <table className="table-bordered table-warning w-100">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Ingr.</th>
                                                            <th scope="col"></th>
                                                            <th scope="col">q.ta</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        prodotto.prodpers.ingredienti.map(ingr=>
                                                            <tr key={ingr.id}>
                                                                <td>{ingr.nome}</td>
                                                                <td><Allergeni allergeni={ingr.allergeni} /></td>
                                                                <td>{ingr.quantita}</td>
                                                            </tr>
                                                    )}
                                                    </tbody>                                       
                                                </table>
                                        </td>
                                        
                                    </tr>
                                    </>
                                    }
                                    </>
                                )}
                                <tr>
                                    <td colSpan={3}>
                                        <div className='row'>
                                            <div className='col-sm-8'>
                                            Codice Sconto
                                    <input type="text" name="codicesconto" value={codicesconto} 
                                    onChange={(e) => setcodicesconto(e.target.value)} />
                                            </div>
                                            <div className='col-sm-4'>
                                            <button onClick={applicasconto}>Applica sconto</button>
                                            </div>
                                    </div>
                                    </td>
                                </tr>
                                <tr><td>TOTALE DA PAGARE</td><td style={{textAlign:'right'}}>{totale+'€'}</td><td></td></tr>
                                {totalescontato&&totalescontato>0?<tr><td>TOTALE SCONTATO</td><td>{totalescontato+'€'}</td><td></td></tr>:""}
                                </tbody>
                            </table>
                            </fieldset>
                        </div>
                        <div style={{backgroundColor:'#3B71CA', color:'white'}} className='col-md-5'>
                                <fieldset>
                                    <legend>Consegna</legend>

                                        <p>{anagrafica.nome + " "+anagrafica.cognome+" (tel. "+anagrafica.telefono+")"}</p>


                                    <label>Ritiro in sede
                                    <input type={'radio'} name="consegna" value="sede" checked={consegna==='sede'} onChange={() => setconsegna('sede') } />
                                    </label>
                                    <br />
                                    <label>Consegna a domicilio
                                    <input type={'radio'} name="consegna" value="domicilio" checked={consegna==='domicilio'} onChange={() => {setconsegna('domicilio'); }} />
                                    </label>
                                    {getModalDomicilio()}
                                    <br />
                                    <label>Ora consegna desiderata
                                    <input type="time" value={oraconsegna} onChange={(e) => {setoraconsegna(e.target.value)}}  />
                                    </label>
                                </fieldset>
            {consegna==='domicilio' && <div style={{backgroundColor:'white', borderColor:'black', borderStyle:'solid', borderWidth:1}}>
                    
                   
                    </div>
                }
            <div>
                <label>Note
                    <textarea rows={2} style={{width:'100%'}}  value={note} onChange={(e) => setnote(e.target.value)}>{note}</textarea>
                    </label>
            </div>
            <p>Si prega di specificare nel campo &quot;Note&quot; eventuali intolleranze e allergie alimentari</p>
            <div className="row">
            <div className='col'>
            {
                !showLoadingState && 
                <div>
                    <h4></h4>
                <button style={{backgroundColor:'#ffffff'}} onClick={() => acquistadacarrello()}><i className="fas fa-utensils"></i>Conferma ordine</button>
                <button style={{backgroundColor:'#ffffff'}} onClick={() =>setshowcarrello(false)}>Chiudi</button>
                </div>
                }
                {
                showLoadingState && 
                <div>
                    <h4>Attendere prego... Registrazione della prenotazione in corso ...</h4>
                </div>
                }
            </div>
        </div>
                
        </div>
        </div>
        
            </span>
        }
        </div>}
        </span>
    );
}