import React, { useState } from "react";
import InputText from "../../util/UI/InputText";
import Text from "../../util/UI/Text";
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function AlimentoSchedadieta({alimento, index, listaalimenti}){
    const [alim, setalim] = useState('');
    const [alimselected, setalimselected] = useState();
    const [showcerca, setshowcerca] = useState(!(alimento&&alimento.alimentobo));

    function calcolaproporz(riferimento, qta, valore){
        //riferimento:100=qta:X
        if (!riferimento) return valore;
        
        return parseFloat(((Number.parseFloat(qta)/Number.parseFloat(riferimento))*Number.parseFloat(valore))).toFixed(2);
    }
    return (
        <tr>
             <td>

            <Text
               label={''} 
               value={alimselected&&alimselected.nome?alimselected.nome:(alimento&&alimento.alimentobo?alimento.alimentobo.nome:'')} 
               onClick={() => setshowcerca(!showcerca)}
                 />
            </td>
            <td>
                <Text 
                id={'quantita'} label={''} name={'quantita'} 

                type={'number'}
                value={alimento.quantita} 
                 />
            </td>
            <td>
                 <Text 
               label={''} 
               value={alimselected&&alimselected.kcal?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.kcal):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.kcal):'0')} 
                 />
                 </td>
            <td>
                 <Text
               label={''} 
               value={alimselected&&alimselected.proteine?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.proteine):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.proteine):'')} 
                 />
                </td>
            <td>
                 <Text 
               label={''} 
               value={alimselected&&alimselected.carboidrati?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.carboidrati):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.carboidrati):'')} 
                 />
                 </td>
            <td>
                 <Text
               label={''} 
               value={alimselected&&alimselected.saturi?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.saturi):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.saturi):'')} 
                 />
                 </td>
                 <td> </td>

        </tr>
    );
}