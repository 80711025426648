import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import SchedaDiarioAlimentare from "./SchedaDiarioAlimentare";
import { useSelector } from "react-redux";

export default function ListaSchedeDiarioAlimentare(props) {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [schedeDA, setschedeDA] = useState([]);

	useEffect(() =>{
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('idanagrafica',anagrafica.id);
        axios.post(global.config.server+"/getSchedeDA", formData)
        .then(response=>{
            setschedeDA(response.data)
        });
    },[palestra, anagrafica]);

	    return (
            anagrafica.id&&palestra.id?
            <div className="container-fluid">

                {schedeDA&&schedeDA[0]&&<SchedaDiarioAlimentare scheda={schedeDA[0]} email={email} />}
                <hr />
                <h4>Storico misurazioni </h4>
                <div className="table-responsive">
                <table className="table table-striped" style={{borderWidth:'1px', borderColor:'black', borderStyle:'dashed'}}>
                    <thead className="thead-dark">
                    <tr >
                            <th rowSpan={2} scope="col">
                                Data
                            </th>
                            <th rowSpan={2}scope="col">
                                Peso
                            </th>
                            <th rowSpan={2} scope="col">
                                BMR
                            </th>
                            <th rowSpan={2} scope="col">
                                TDEE
                            </th>
                            <th rowSpan={2} scope="col">
                                Calorie giornaliere
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"gray"}}>
                                Proteine
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"green"}}>
                                Carboidrati
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"yellow", color:"black"}}>
                                Lipidi
                            </th>
                            <th scope="col" rowSpan={2}>
                                Calorie assunte giorno
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                %
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                %
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                %
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            schedeDA.map(scheda=>(
                        <tr key={scheda.id}>
                            <td>
                                {scheda.data}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.peso}
                            </td>
                            <td style={{textAlign:'right'}}>
                                {scheda.mediabmr}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.mediatdee}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.caloriedaassumeregiorno}</strong>
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinegrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinegrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinekcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteineperc}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratigrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratigrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratikcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratiperc}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidigrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidigrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidikcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidiperc}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.calorieassuntegiorno}</strong>
                            </td>
                        </tr>
                            ))
                        }
                    
                    </tbody>
                </table>
                </div>
			</div>
            :"E' necesssario selezionare un'anagrafica"
	    );
	}