/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import '../util/config'
import { useSelector } from 'react-redux';
import { gettipicorsobynomecalendario } from '../DAO/calendarioDAO';
import CalendarioMenuCliente from './CalendarioMenuCliente';
import Calendario from './Calendario';

export default function CalendarioMenuCompostoCliente ({nomecalendario, linkcalendario}) {

    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const token = useSelector(state => state.gestoreUtente.token);
   
    const [tipi, settipi] = useState([])

    function getTipiByNomeCalendario(){
      async function getTipi(){
        const result=await gettipicorsobynomecalendario(nomecalendario, palestra.id, token, 0);
        console.log(result)
        settipi(result)
      }
      getTipi()
    }

  useEffect(() =>{
   getTipiByNomeCalendario()
  },[])
 
    return (
      <div>
        {tipi&&Array.isArray(tipi)&&tipi.map(tipo => (
           tipo.visualizzazione&&tipo.visualizzazione==='menu'?
           <CalendarioMenuCliente email={anagrafica.email} 
           linkcalendario={linkcalendario}
           key={tipo.id} 
           tipo={tipo}
            />
            :
            tipo.visualizzazione&&tipo.visualizzazione==='menucomposto'&&tipo.nomecalendario!=='nomecalendario'?
            <CalendarioMenuCompostoCliente email={anagrafica.email} 
            nomecalendario={tipo.nomecalendario}
            key={tipo.id}
             />
     
              :
             <Calendario email={anagrafica.email} 
             key={tipo.id} 
             linkcalendario={linkcalendario}
             tipo={tipo} />
   
        ))}
      </div>
    );
}