/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import PypalButtons from "./PypalButtons";
import MyPosButtons from "./MyPosButtons";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function PagamentoUtentePopup () {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [pagamento, setpagamento] = useState();


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const datap = params.get("datap");
        console.log('datap '+datap)
        if (datap){
            //carica la lista dei listini della palestra
            const formData = new FormData();
            formData.append('datap',datap);
            
            axios.post(serverInfo.namesec+"/wp/prePagamentoWp", formData,
            {
            headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
            },
            },
            )
            .then(response=>{
                    setpagamento(response.data);
                    console.log(response);
            });
        }
	},[palestra, email, anagrafica])
  
    function handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('website', serverInfo.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        axios.post(serverInfo.name+'/salvaPagamento',formData)
        .then((result) => {
            console.log(result.data);
        }).catch(err => {
            if (err.response){
        alert(err.response.data.message);
            }
            else{
                alert(err)
            }
        });
    }

      return (
              <div>
               
                    {pagamento?
                    <span>
                    <div className="alert alert-success" role="alert">
                        Stai pagando {pagamento.totale}&euro; per aver acquistato {pagamento.descrizione}
                       
                    </div>
                    {pagamento.ppid&&
                        <div>
                            <PypalButtons dapagare={pagamento.totale} currency={"EUR"} clientid={pagamento.ppid} />
                        </div>
                    } 
                    {pagamento.myposid&&
                    <div>
                        <MyPosButtons dapagare={pagamento.totale}
                        urlnotify={pagamento.myposurlnotify}
                        urlok={pagamento.myposurlok}
                        urlko={pagamento.myposurlko}
                        orderid={pagamento.ordine.id} 
                        currency={"EUR"} 
                        clientid={pagamento.myposid} 
                        descrizione={pagamento.descrizione}
                        />
                    </div>                      
                    }
                    {!pagamento.myposid&&!pagamento.ppid&&
                        <div className="alert alert-dark" role="alert">È necessario effettuare il pagamento in struttura</div>
                    }
                <div>
                </div>
                        </span>
                    :"Non c'è niente da pagare"}
              </div>
      );
  }