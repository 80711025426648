import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import EsercizioSchedaCliente from "./EsercizioSchedaCliente";
import { connect, useSelector } from "react-redux";

export default function ListaSchedeCliente() {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
 

    const [scheda, setscheda] = useState();
    const [giornoSelected, setgiornoSelected] = useState();

	useEffect(() => {
        if (palestra&&palestra.id&&anagrafica&&anagrafica.id){
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email',email);
            formData.append('lic','000');
            formData.append('palestra',palestra.id);
            formData.append('idanag',anagrafica.id);

            axios.post(global.config.server+'/cercaSchedaCliente',formData)
            .then((result) => {
                console.log(result.data)
                setscheda(result.data)
	        });
        }
    },[anagrafica]);

	    return (
            <div className="container-fluid">
                {scheda&&scheda.idscheda?
                <div className="row">
                <div className="col-md-1">N. {scheda.idscheda}</div>
                <div className="col-md-8">{scheda.descrizione}</div>
                <div className="col-md-3">Validit&agrave; {scheda.validoDa} ... {scheda.validoA}</div>
                {scheda.giorniScheda?
                <div className="container-fluid">
                <div className="row">
                {scheda.giorniScheda.map(giorno =>(
                        <div key={giorno} className="col">
                    <button name="giorno" value={giorno} onClick={(e) => setgiornoSelected(e.target.value)} style={{width:'100%',backgroundColor:giorno===giornoSelected?'green':'blue'}}>{giorno}</button>
                    </div>
                    
                ))}
                </div>
                    </div>
                :null}
                {scheda.esercizi?
                scheda.esercizi.map((esercizio, index) => (
                    <EsercizioSchedaCliente key={index} esercizio={esercizio} giornoSelected={giornoSelected} />
                ))
            :null}
                </div>
                :"Nessuna scheda associata"}
            </div>
	    );
	}