import React from "react";
import axios from 'axios';
import '../../util/config'
import ReactSpeedometer from "react-d3-speedometer"
import { connect } from "react-redux";

class SchedaCompCorp extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            id:0,
            idanagrafica:0,
            sesso:'',
            altezza:0,
            peso:0,
            bmi:0,
            plbicipite:0,
            pltricipite:0,
            plsottoscapolare:0,
            plsovrailiaca:0,
            circvita:0,
            percmassagrassa:0,
            massagrassa:0,
            massamagra:0,
            palestra:0,
            data:'',
            idutente:0,
	    }
	  }

	componentDidMount(){
        if (this.props.scheda){
            this.setState({...this.props.scheda})
        }
        else{
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email',this.props.email);
            formData.append('lic','000');
            formData.append('palestra',this.props.palestra.id);
            formData.append('idanagrafica',this.props.anagrafica.id);
            axios.post(global.config.server+"/getUltimaSchedaCC", formData)
            .then(response=>{
                this.setState({...response.data, nuovaRigaVisible:true,
                    pltotale:(parseInt(response.data.plbicipite)+parseInt(response.data.pltricipite)+parseInt(response.data.plsottoscapolare)+parseInt(response.data.plsovrailiaca))
                })
            });
        }
    }

	render() {
	    return (
	    		<div className="container-fluid">
                    <h4>
                        Scheda Composizione Corporea {this.state.id===0?'NUOVA':''} di <u>{this.state.nome+' '+this.state.cognome+' ('+ this.state.sesso+')'}</u>
                    </h4>
                    <div className="row">
                    <h5>Data misurazione: {this.state.data}</h5>
                    </div>
                    <div className="row">
                        <h4>BMI</h4>
                    </div>
                    <div className="row form-group">
                        <div className="col-4">
                            <label>Altezza cm <br />{this.state.altezza}
                            </label>
                        </div>
                        <div className="col-4">
                            <label>Peso kg <br />
                            {this.state.peso}
                            </label>
                        </div>
                        <div className="col-4">
                            <label>BMI % <br />
                            {this.state.bmi}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">


                    <ReactSpeedometer
        width={300}
        maxValue={50}
        needleHeightRatio={0.7}
        value={parseFloat(this.state.bmi)}
        currentValueText="BMI"
        customSegmentStops={[0, 18.5, 25, 30, 35, 50]}
        segmentColors={['blue', 'green', 'yellow', 'orange', 'red']}
        customSegmentLabels={[
          {
            text: 'Underweight',
            position: 'INSIDE',
            color: 'white',
          },
          {
            text: 'Normal',
            position: 'OUTSIDE',
            color: 'black',
            fontSize: '19px',
          },
          {
            text: 'Overwheight',
            position: 'INSIDE',
            color: 'black',
          },
          {
            text: 'Obese',
            position: 'OUTSIDE',
            color: 'red',
          },
          {
            text: 'Extremly obese',
            position: 'INSIDE',
            color: 'black',
          },
        ]}
        ringWidth={47}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        needleColor={'black'}
        textColor={'black'}
      />
                        </div>
                        <div className="col-md-8">
                        {
                            this.state.bmi>0&&this.state.bmi<18.5?
                            this.state.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/sottopesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/sottopesodonna.jpg" />
                            :""
                        }
                        {
                            this.state.bmi>=18.5&&this.state.bmi<25?
                            this.state.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/normaleuomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/normaledonna.jpg" />
                            :""
                        }
                        {
                            this.state.bmi>=25&&this.state.bmi<30?
                            this.state.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/mediouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/mediodonna.jpg" />
                            :""
                        }
                        {
                            this.state.bmi>=30&&this.state.bmi<35?
                            this.state.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/obesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/obesodonna.jpg" />
                            :""
                        }
                        {
                            this.state.bmi>=35?
                            this.state.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/estremamenteobesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/estrmamenteobesodonna.jpg" />
                            :""
                        }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <h4>Plicometria</h4>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-2 form-floating">
                            <label htmlFor="plbicipite">Bicipite cm</label> <br />
                            {this.state.plbicipite}
                        </div>

                        <div className="col-md-2 form-floating">
                            <label htmlFor="pltricipite">Tricipite cm</label> <br />
                            {this.state.pltricipite}
                        </div>
                        <div className="col-md-2 form-floating">
                            <label htmlFor="plsottoscapolare">Sottoscapolare cm</label> <br />
                            {this.state.plsottoscapolare}
                        </div>
                        <div className="col-md-2 form-floating">
                            <label htmlFor="plsovrailiaca">Sovrailiaca cm</label> <br />
                            {this.state.plsovrailiaca}
                        </div>
                    
                        <div className="col-md-4 form-floating">
                            <label htmlFor="pltotale">Somma Pliche cm</label> <br />
                            {this.state.pltotale}
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-6">
                            <label>Circ. Vita<br/>{this.state.circvita}
                            </label>
                        </div>
                        <div className="col-6">
                            <label>Circ. Fianchi<br/>{this.state.circfianchi}
                            </label>
                        </div>
                        <div className="col-12">
<ReactSpeedometer
        width={300}
        maxValue={2}
        needleHeightRatio={0.7}
        value={parseFloat(this.state.circvita/this.state.circfianchi)}
        currentValueText={"WHR ("+Math.round(parseFloat(this.state.circvita/this.state.circfianchi)*100)/100+")"}
        customSegmentStops={[0, this.state.sesso&&this.state.sesso==='F'?0.85:0.95, 2]}
        segmentColors={['green','red']}
        customSegmentLabels={[
          {
            text: 'Rischio basso',
            position: 'INSIDE',
            color: 'black',
          },
          {
            text: 'Rischio alto',
            position: 'INSIDE',
            color: 'black',
          }
        ]}
        ringWidth={47}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        needleColor={'black'}
        textColor={'black'}
      />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-4">
                            <label>Massa Grassa % <br />
                            {this.state.percmassagrassa}
                            </label>
                        </div>
                        <div className="col-4">
                            <label>Massa Grassa kg <br />
                           {this.state.massagrassa}
                            </label>
                        </div>
                        <div className="col-4">
                            <label>Massa Magra kg <br />
                            {this.state.massamagra}
                            </label>
                        </div>
                    </div>
                    
{
    this.state.sesso==='D'?
    <div className="row">
                            <div className="row" style={{backgroundColor:(this.state.percmassagrassa>0&&this.state.percmassagrassa<14?'yellow':'white')}} >
                                <div className="col">DONNA GRASSO ESSENZIALE (&lt; 14)</div>
                                <div className="col">
                                    <img src="https://annotatio.online/wp-content/uploads/2022/07/donnaEssenziale.jpg" 
                                style={{opacity:(this.state.percmassagrassa>0&&this.state.percmassagrassa<14?'1':'0.2'), maxWidth:"150px"}} />
                                </div>
                            </div>
                            <div className="row" style={{backgroundColor:(this.state.percmassagrassa>=14&&this.state.percmassagrassa<20?'green':'white')}} >
                                <div className="col">DONNA ATLETICO (14 - 20)</div>
                                <div className="col">
                                <img src="https://annotatio.online/wp-content/uploads/2022/07/donnaAtletico.jpg" 
                                style={{opacity:(this.state.percmassagrassa>=14&&this.state.percmassagrassa<20?'1':'0.2'), maxWidth:"150px"}} /></div>
                            </div>
                            <div className="row" style={{backgroundColor:(this.state.percmassagrassa>=20&&this.state.percmassagrassa<24?'orange':'white')}} >
                                <div className="col">DONNA FITNESS (21 - 24)</div>
                                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaFitness.jpg" 
                                style={{opacity:(this.state.percmassagrassa>=20&&this.state.percmassagrassa<24?'1':'0.2'), maxWidth:"150px"}} /></div>
                            </div>
                            <div className="row" style={{backgroundColor:(this.state.percmassagrassa>=24&&this.state.percmassagrassa<31?'red':'white')}} >
                                <div className="col">DONNA PROMEDIO (24 - 31)</div>
                                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaPromedio.jpg" 
                                style={{opacity:(this.state.percmassagrassa>=24&&this.state.percmassagrassa<31?'1':'0.2'), maxWidth:"150px"}} /></div>
                            </div>
                            <div className="row" style={{backgroundColor:(this.state.percmassagrassa>=31?'#FF33FF':'white')}} >
                                <div className="col">DONNA OBESA (&gt; 31 %)</div>
                                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaPromedio.jpg" 
                                style={{opacity:(this.state.percmassagrassa>=31?'1':'0.2'), maxWidth:"150px"}} /></div>
                            </div>
                        </div>
    :
    <div>
        <div className="row">
<ReactSpeedometer
        width={300}
        value={parseFloat(this.state.percmassagrassa)}
        currentValueText="% GRASSO"
        maxValue={40}
        customSegmentStops={[0, 5, 13, 17, 24, 40]}
        segmentColors={['blue', 'green', 'yellow', 'orange', 'red']}
        customSegmentLabels={[
          {
            text: 'Essenziale',
            position: 'INSIDE',
            color: 'white',
          },
          {
            text: 'Atletico',
            position: 'OUTSIDE',
            color: 'black',
          },
          {
            text: 'Fitness',
            position: 'INSIDE',
            color: 'black',
          },
          {
            text: 'Promedio',
            position: 'OUTSIDE',
            color: 'red',
          },
          {
            text: 'Obeso',
            position: 'INSIDE',
            color: 'black',
          },
        ]}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        needleColor={'black'}
        textColor={'black'}
      />
            </div>
            <div className="row">
            <figure className="figure" style={{backgroundColor:(this.state.percmassagrassa>0&&this.state.percmassagrassa<5?'yellow':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoEssenzale.jpg" 
                style={{opacity:(this.state.percmassagrassa>0&&this.state.percmassagrassa<5?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="GRASSO ESSENZIALE (&lt; 5)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>GRASSO ESSENZIALE<br/>(&lt; 5)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(this.state.percmassagrassa>=5&&this.state.percmassagrassa<13?'green':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoAtletico.jpg"
                style={{opacity:(this.state.percmassagrassa>=5&&this.state.percmassagrassa<13?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO ATLETICO (5 - 13)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>ATLETICO<br/>(5 - 13)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(this.state.percmassagrassa>=13&&this.state.percmassagrassa<17?'orange':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoFitness.jpg"
                style={{opacity:(this.state.percmassagrassa>=13&&this.state.percmassagrassa<17?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO FITNESS (13 - 17)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>FITNESS<br/>(13 - 17)</figcaption>
            </figure>
            <figure className="figure" 
            style={{backgroundColor:(this.state.percmassagrassa>=17&&this.state.percmassagrassa<24?'red':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoPromedio.jpg" 
                style={{opacity:(this.state.percmassagrassa>=17&&this.state.percmassagrassa<24?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO PROMEDIO (17 - 24)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>PROMEDIO<br/>(17 - 24)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(this.state.percmassagrassa>24?'#FF33FF':'white')}} >
            <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoObeso.jpg" 
                                style={{opacity:(this.state.percmassagrassa>24?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO OBESO (&gt; 24)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>OBESO<br/>(&gt; 24)</figcaption>
            </figure>
                </div>
                           
                        </div>
}
<br />
			</div>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraAnagrafica,
            email: state.gestoreUtente.emailloggato,
            anagrafica: state.gestoreUtente.anagrafica
        }
      }
      
      export default connect(mapStateToProps, null)(SchedaCompCorp)