/* eslint-disable react/prop-types */
import React from "react"
import QRCode from "react-qr-code";

export default class Qrcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            code:null
        };
    }
        componentDidMount(){
            console.log("Qrcode")
        }
    
      componentDidCatch(error, info) {
            // You can also log the error to an error reporting service
            console.log(error, info);
          }
    
      render() {
        return (
            this.props.code?
            <span>
            <QRCode value={this.props.code} />
            </span>
            :localStorage.getItem('code')||localStorage.getItem('utente')?
            <span>
            <QRCode value={localStorage.getItem('code')?localStorage.getItem('code'):'SOCIO-'+localStorage.getItem('utente')} />
            </span>
            :null
          );
      }
    }