import React, { useEffect, useState } from "react";
import '../util/config'

import { useSelector } from "react-redux";
import { getordine } from "../DAO/ordiniDAO";
import { navigate } from "hookrouter";

export default function RiepilogoOrdine(props) {

    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

    const [ordine, setordine] = useState();
    
    function getriepordine(){
        async function riepordine(){
            const ordinetemp=await getordine(props.idordine);
            setordine(ordinetemp)
        }
        riepordine()
    }

      useEffect(() => {
        getriepordine()
      }, [props.idordine])

      return (
        <div >

                    {ordine && 
                    <span><p>Ordine numero {ordine.id} effettuato correttamente</p>
                    <button onClick={() => window.open((ordine.pagaoraurl?ordine.pagaoraurl:"https://annotatio.online/pagamento-utente-popup")+"?datap=ord_"+ordine.numero,"_blank")}>Paga ora</button>
                    <button onClick={() => navigate('/annotatioc-ordini/',true)}>Vai alla lista dei tuoi ordini</button>
                    </span>
                    }
        </div>
    );
}