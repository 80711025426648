import React from "react";
import axios from 'axios';
import '../../util/config'

export default class SchedaDiarioAlimentare extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            id:0,
            idanagrafica:0,
            sesso:'',
            proteinegrkg:0,
            proteinegrtot:0,
            proteinekcal:0,
            proteineperc:0,
            carboidratigrkg:0,
            carboidratigrtot:0,
            carboidratikcal:0,
            carboidratiperc:0,
            lipidigrkg:0,
            lipidigrtot:0,
            lipidikcal:0,
            lipidiperc:0,
            calorieassuntegiorno:0,
            mediabmr:0, 
            mediatdee:0,
            caloriedaassumeregiorno:0,
            palestra:0,
            data:'',
            idutente:0,
	    }
	  }

	componentDidMount(){
        if (this.props.scheda){
            this.setState({...this.props.scheda});
        }
    }

	render() {
	    return (
	    		<div className="container-fluid">
                    <h4>
                        Scheda Diario Alimentare {this.state.id===0?'NUOVA':''} di <u>{this.state.nome+' '+this.state.cognome+' ('+ this.state.sesso+')'}</u>
                    </h4>
                    <div className="row">
                    <h5>Data misurazione: {this.state.data} &nbsp;
                    Peso kg: {this.state.peso}</h5>
                    </div>
                    <br/>
                    <div className="row">
                        <h4>Valori medi diario alimentare</h4>
                    </div>
                    <div className="row form-group">
                        <div className="col-4">
                            <label>BMR (metabolismo basale) Kcal</label><br />
                            {this.state.mediabmr}
                            
                        </div>
                        <div className="col-4">
                            <label>TDEE (fabbisogno energetico giornaliero) Kcal</label><br />
                            {this.state.mediatdee}
                        </div>
                        <div className="col-4">
                            <label>Calorie da assumere giornalmente Kcal</label><br />
                            {this.state.caloriedaassumeregiorno}
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col-md-4">
                    <table className="table table-striped" style={{background:"gray", color:"white"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Proteine</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.state.proteinegrkg}
                                </td>
                                <td>
                                    {this.state.proteinegrtot}
                                </td>
                                <td>
                                    {this.state.proteinekcal}
                                </td>
                                <td>
                                    {this.state.proteineperc}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>

                    <table className="table table-striped" style={{background:"green", color:"white"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Carboidrati</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.state.carboidratigrkg}
                                </td>
                                <td>
                                    {this.state.carboidratigrtot}
                                </td>
                                <td>
                                    {this.state.carboidratikcal}
                                </td>
                                <td>
                                    {this.state.carboidratiperc}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>

                    <table className="table table-striped" style={{background:"yellow", color:"black"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Lipidi</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.state.lipidigrkg}
                                </td>
                                <td>
                                   {this.state.lipidigrtot}
                                </td>
                                <td>
                                   {this.state.lipidikcal}
                                </td>
                                <td>
                                    {this.state.lipidiperc}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
            </div>
            <br/>
            <h3>Calorie assunte giornalmente:<strong>{this.state.calorieassuntegiorno}</strong></h3>
            <br />
			</div>
	    );
	  }
	}