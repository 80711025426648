import {configureStore} from '@reduxjs/toolkit';
import gestoreOrdiniReducer from './reducers/gestoreOrdini';
import gestoreUtentiReducer from './reducers/gestoreUtente';
import gestoreTipiProdottoReducer from './reducers/gestoreTipiProdotto';
import gestoreCarrelloReducer from './reducers/gestoreCarrello';
import gestoreCalendariReducer from './reducers/gestoreCalendari';
import gestoreFattureReducer from './reducers/gestoreFattura';

export default configureStore({
  reducer: {
    gestoreOrdini: gestoreOrdiniReducer,
    gestoreUtente: gestoreUtentiReducer,
    gestoreTipiProdotto: gestoreTipiProdottoReducer,
    gestoreCarrello: gestoreCarrelloReducer,
    gestoreCalendari: gestoreCalendariReducer,
    gestoreFatture: gestoreFattureReducer,
  },
});
