import React, { useState } from 'react';

import CalendarioMenuCliente from './CalendarioMenuCliente';
import TipiCorsoHoriz from '../tipocorso/TipiCorsoHoriz';
import { useSelector } from 'react-redux';
import Calendario from './Calendario';
import CalendarioMenuCompostoCliente from './CalendarioMenuCompostoCliente';



export default function QualeTipologia(props){
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const tipo = useSelector(state => state.gestoreTipiProdotto.tipo);

  useState(() => {
    console.log("tipo ",tipo)
  },[anagrafica, tipo])

    return (
      <div>
        <TipiCorsoHoriz linkcalendario={props.linkcalendario} />

        {tipo.visualizzazione&&tipo.visualizzazione==='menu'&&
                <CalendarioMenuCliente
                linkcalendario={props.linkcalendario}
                key={tipo.id} 
                tipocorso={tipo}

                 />
        }
        {tipo.visualizzazione&&tipo.visualizzazione==='menucomposto'&&
               <CalendarioMenuCompostoCliente email={anagrafica.email} 
               nomecalendario={tipo.nomecalendario}
               linkcalendario={props.linkcalendario}
               tipocorso={tipo}
                />
        }
      {(!tipo.visualizzazione||tipo.visualizzazione===''||tipo.visualizzazione==='calendario')&&
                <Calendario email={anagrafica.email} 
                key={tipo.id} 
                linkcalendario={props.linkcalendario}
                tipocorso={tipo} />
        }
        </div>
    );
}