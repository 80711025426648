/* eslint-disable react/prop-types */
import React from "react";
import '../util/config'
import RigaCalendario from "../calendario/RigaCalendario";
import { useSelector } from "react-redux";

export default function ListaCalendariCliente (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  
      return (
          <span>
        {
            props.calendari?
            props.calendari.map(calendario=>(
              <RigaCalendario key={calendario} link={props.link} calendario={calendario} palestra={palestra} setcalendario={props.setcalendario} />
            ))
            :"Nessun calendario presente"
          }
          </span>
        );

  }