/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import * as MyPOSEmbedded from 'mypos-embedded-checkout';

export default function MyPosButton(props){

    // const mypos = require('@mypos-ltd/mypos')({
    //     isSandbox: false,
    //     logLevel: 'debug',                  // Logging level
    //     checkout: {                         // Checkout API specific configuration
    //         sid: '529692',                        // Stored ID
    //         lang: 'EN',                     // Preferred language
    //         currency: 'EUR',                   // Store currency 
    //         clientNumber: '40053889420',               // Available in the myPOS Account
    //         okUrl: 'https://annotatio.online/pagamento-utente-popup/?result=OK',                      // Redirect URL on successful operation
    //         cancelUrl: 'https://annotatio.online/pagamento-utente-popup/?result=KO',                  // Redirect URL on cancelled operation
    //         notifyUtr: 'https://be.annotatio.online/wp/postPagamentoWp2',                  // Callback URL to be notified on operation result
    //         cardTokenRequest: 0,            // View details at https://developers.mypos.eu/en/doc/online_payments/v1_4/21-purchase-with-payment-card-(api-call--ipcpurchase) 
    //         paymentMethod: 1,               // View details at https://developers.mypos.eu/en/doc/online_payments/v1_4/21-purchase-with-payment-card-(api-call--ipcpurchase)
    //         paymentParametersRequired: 3,   // https://developers.mypos.eu/en/doc/online_payments/v1_4/21-purchase-with-payment-card-(api-call--ipcpurchase)
    //         keyIndex: 1,                    // Key index for the particular store
    //         privateKey: '-----BEGIN RSA PRIVATE KEY-----\n' + // The private key for the particular store in PEM format
    //             'MIICXQIBAAKBgQCml1G1fkVzoT9EH+\/xeLUAgbYxPUYBaNX2bs7qIbx5Y4bAjNe8\nkRDy\/DX+PSPuVCuzeZl3MQgeMsBHSdSvGEmbiae0IXxYEAFaRvprJc0Wx7cg4ALT\nAljTrSq4\/FEtuxYgtZlcFrSdww7SdyNPpP+2mHazD4PYndc4oBZpGYz+FwIDAQAB\nAoGBAINF\/GozTZE3gD4zmIVEpAT5MdONQCv+K5ScbsqKuRVCuz3h\/mKmQZ\/n5Lgp\n\/7VfeGxeJvOInKgHQAMtB77OOblkKTEJ75epeYuht6ksV4Z8q10yyp9ZLnCOC8Yr\nXEVw+GLauKLPP9Vha1l9EPwBAkSbwiRZjfSaubUFg6u5MnnRAkEA2DmGHdXtyu+m\nC9Z3tRRP0HJcSFfww5TmNOB5bvWiSjHnzxFB5ZhdXNhsUh4LtmWNDgIIMmhXgwKp\nIzId4jwZSQJBAMU8ca7hXzwlNGuqBDmeu8B\/jhGE5Exjdqzg3S7BAf53pwwFFAzw\nqmBFL7+JSacPUguVr6ssR0RPUxbe+8s+vF8CQQCtCKu5aJ+ZTVrWElVNxelxaHNN\nOYLlIV1wZ0sGbu\/m7DUT0j2n3NyqFk\/pDz5UQsQ0t4+Tt0iBAToVfl\/H0kxxAkBd\n86breOuWiNpplrkggV5KGfigm77sLEzxmMODa+j5QCHQdfLRHyrpZMuT31+4NnIM\nlqbxUMw529dmg51JeX7dAkB3IJhVZXSjSrhtS30WJmUrbCjeJqqblHczKu1Eo2Xy\ntWtIJnEyp3ZiXFksYHiX4qIX\/n\/vA01RMrwxMtOSWsK8\n' +
    //             '-----END RSA PRIVATE KEY-----'
    //     }
    // });

    // const purchaseParams = {
    //     orderId: Math.random().toString(36).substr(2, 9), // A unique reference
    //     amount: 23.45,
    //     cartItems: [
    //         {
    //             name: 'HP ProBook 6360b sticker',
    //             quantity: 2,
    //             price: 10.00
    //         },
    //         {
    //             name: 'Delivery',
    //             quantity: 1,
    //             price: 3.45
    //         }
    //     ], 
    //     customer: {
    //         email: 'name@website.com',
    //         firstNames: 'John',
    //         familyName: 'Smith',
    //         phone: '+23568956958',
    //         country: 'DEU',
    //         city: 'Hamburg',
    //         zipCode: '20095',
    //         address: 'Kleine Bahnstr. 41'
    //     },
    //     note: 'Some note'
    // };

var paymentParams = {
    sid: props.clientid.split('___')[0],//'529692', //'283213',
    //sid: '000000000000010', //TEST
    ipcLanguage: 'it', //Checkout page language (ISO 2 format).
    walletNumber: props.clientid.split('___')[1],//'40053889420', //'40263860948',
    //walletNumber:'61938166610',
    amount: props.dapagare,
    currency:'EUR',
    orderID: props.orderid+'-'+Math.random().toString(36).substr(2, 9),
    urlNotify: props.urlnotify,
    urlOk: props.urlok, // Optional - required only for iDeal
    urlCancel: props.urlko, // Optional - required only for iDeal
    keyIndex: 1,
    cartItems: [
        {
             article: props.descrizione,
             quantity: 1,
             price: props.dapagare,
             currency: 'EUR',
        },

    ],
    
};

var callbackParams = {
    isSandbox: false, //<== metere a false quando si e' pronti per la produzione
    //isSandbox: true,
    onSuccess: function (data) {
        console.log('success callback');
        console.log(data);
    },
    onError: function () {
        console.log('error');
    },
};

useEffect(() => {
    MyPOSEmbedded.createPayment(
        'myPOSEmbeddedCheckout',
        paymentParams,
        callbackParams
    );
    //mypos.checkout.purchase(purchaseParams);
})


    return (
        <div>
            <div id="myPOSEmbeddedCheckout"></div>
        </div>
    );

}