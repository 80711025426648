import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function MessaggiClienti() {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [messaggi, setmessaggi] = useState([]);


    useEffect(() => {
        if (palestra&&palestra.id&&anagrafica&&anagrafica.id){
        const formData = new FormData();
        formData.append('email',email);
		formData.append('website', global.config.domain);
		formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('id',anagrafica.id);
		  axios.post(serverInfo.namesec+"/msg/listaMessaggiRest",formData,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
            },
          },)
		  .then(response=>{
			console.log("Messaggi: ")
			  console.log(response.data)
			  setmessaggi(response.data);
		  })
		  .catch(err => {
              if (err.response){
                alert("Messaggi: "+err.response.data.message);
              }
              else{
                alert("Messaggi: "+err.response);
              }
		});
    }
      },[palestra, anagrafica]);

      
    return (
        <span>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            {messaggi&&Array.isArray(messaggi)&&messaggi.length>0?
                            messaggi.map(messaggio=>(
                            <p key={messaggio.id} className="card-text">
                                <div className="form-row" style={messaggio.mittente&&parseInt(messaggio.mittente)===parseInt(global.localstorage.anagraficaAssociata.id)?{textAlign:'right',backgroundColor:'yellow'}:{textAlign:'left', backgroundColor:'greenyellow'}}>
                                    {messaggio.mittente&&parseInt(messaggio.mittente)===parseInt(anagrafica.id)?
                                    <div className="form-group col-4">

                                    </div>
                                    :null}
                                    <div className="form-group col-8">
                                        {messaggio.messaggio}
                                    </div>
                                    {messaggio.mittente===null||parseInt(messaggio.mittente)!==parseInt(anagrafica.id)?
                                    <div className="form-group col-4">

                                    </div>
                                    :null}
                                    <div style={{float:'right'}} className="form-group">
                                            {messaggio.data} {messaggio.letto?" (LETTO)":" (Non Letto)"}
                                    </div>
                                </div>
                                <hr />
                            </p>
                        ))
                        :"Nessun messaggio trovato"}
                        </div>
                    </div>
                </div>
    </div>
    
</span>
);
}