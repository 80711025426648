import axios from 'axios';
import '../util/config'
import serverInfo from '../util/globals';

export async function caricasconto(email, palestra, codicesconto, idanagrafica) {
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('codice', codicesconto);
  formData.append('idanagrafica', idanagrafica);
  const response = await axios.post(
    serverInfo.namesec+'/scon/checkvalido',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}