import React from "react";
import axios from 'axios';
import '../util/config'
export default class Prodotti extends React.Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		    	corsi:[]
		    };
		  }
	 
	 componentDidMount(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('lic',localStorage.getItem('key'));
		formData.append('palestra',localStorage.getItem('palestracalendario')&&localStorage.getItem('palestracalendario')!=='undefined'?localStorage.getItem('palestracalendario'):localStorage.getItem('palselezionata'));
        formData.append('tipocorso',localStorage.getItem('tipocorso')?localStorage.getItem('tipocorso'):'');
		  axios.post(global.config.server+"/getSoloCorsiRest",formData,
		  {
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+window.$t
			  },
			},)
		  .then(response=>{
			console.log("corsi: ")
			  console.log(response.data)
			  this.setState({corsi: response.data});
		  })
		  .catch(err => {
			alert("Lista corsi: "+err);
		});
	  }

	render() {
	    return (
			<div className="row">
				{
					this.state.corsi?
					this.state.corsi.map((item,index) =>(
						<div key={index} className="card mb-3" style={{maxWidth: '540px', margin:'25px'}}>
                            <div className="row g-0">
                                <div className="col-md-4">
                                    {item.foto1?
									<div className="embed-responsive embed-responsive-16by9">
										<img src={item.foto1} alt={item.nome} /> 
									</div>
                                :null}
                                </div>
                                <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">{item.nome}</h5>
                                    <p className="card-text">{item.descrizione}</p>
                                    <p className="card-text"><small className="text-muted">{item.luogo}</small></p>
                                </div>
                                </div>
                            </div>
                            </div>
					)
					)
					:null
	    		}
				</div>
	    );
	  }
    }