/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect,  useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import { caricaIngredienti } from '../DAO/corsiDAO';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { aggiornaprodotti } from '../STORE/reducers/gestoreCarrello';
import DettIngrediente from './DettIngrediente';
import Allergeni from './Allergeni';

export default function CalendarioMenuItemNew({corso}) {

    /*
    corso.configCategorie =
    [
        {
            "max":3,
            "min":0
            "categoria":"Proteine",
            "compresiinprezzo":1,
        },
        {
            "max":3,
            "categoria":"Salse",
            "compresiinprezzo":3
        },
        {
            "max":"2",
            "categoria":"Verdure",
            "compresiinprezzo":2
        },
        {
            "max":"2",
            "categoria":"Spezie e semi",
            "compresiinprezzo":2
        }
    ]

    prodottopersonalizzato=
    [
        {
            id: ingrediente.id, 
            nome: ingrediente.nome, 
            nomepadre: ingrediente.nomepadre, 
            prezzo:ingrediente.prezzo, 
            quantita: quantita, 
            categoria: ingrediente.categoria, 
            minvolte:ingrediente.minvolte,
            maxvolte:ingrediente.maxvolte,
            allergeni: ingrediente.allergeni
        },
        {
            id: ingrediente.id, 
            nome: ingrediente.nome, 
            nomepadre: ingrediente.nomepadre, 
            prezzo:ingrediente.prezzo, 
            quantita: quantita, 
            categoria: ingrediente.categoria, 
            minvolte:ingrediente.minvolte,
            maxvolte:ingrediente.maxvolte,
            allergeni: ingrediente.allergeni
          }
    ]
    */

  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

  const [ingredienti, setingredienti] =useState([]);
  const [prodottopersonalizzato, setprodottopresonalizzato] =useState({ingredienti:[]});
  const [idpp] =useState(new Date().getTime());//deve essere quanto piu univoco
  const [categorie, setcategorie] = useState([]);
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const [messaggio, setmessaggio] = useState('');  
  const [totprezzoingr, settotprezzoingr] = useState(0)
  const [quantita,setquantita]=useState(0);

  function aggiornapersonalizzato(ingrediente, quantita){
    //console.log(ingrediente,quantita)
    const categselezionata=categorie.filter(cattemp => cattemp.categoria===ingrediente.categoria)[0]

    let ingredienticategtemp = prodottopersonalizzato.ingredienti.filter(ingr => ingr.categoria===ingrediente.categoria&&ingr.id!= ingrediente.id)
    
    let totingrcateg=quantita
    ingredienticategtemp.map(ing=> totingrcateg+=ing.quantita)

    if(categselezionata.max<totingrcateg){
        alert('Superato il massimale di ingredienti per la categoria '+categselezionata.categoria)
        return;
    }

    ingredienticategtemp = prodottopersonalizzato.ingredienti.filter(ingr => ingr.id!= ingrediente.id)
    totingrcateg=quantita
    ingredienticategtemp.map(ing=> totingrcateg+=ing.quantita)
    if (totingrcateg>corso.maxingredienti){
      alert('Superato il massimale di ingredienti per questo prodotto ')
      return;
    }

    let ingredientitemp=[];
    let prodperstemp={...prodottopersonalizzato};
    if (prodperstemp&&prodperstemp.id&&prodperstemp.ingredienti){
      ingredientitemp=prodperstemp.ingredienti.filter(ingrtemp => ingrtemp.id!= ingrediente.id)
    }
    else{
      prodperstemp={id: idpp, idanagrafica: anagrafica.id, idcorso:corso.id, prodotto: corso}
    }

    if (quantita>0){
        
        ingredientitemp.push(
            {
            id: ingrediente.id, 
            nome: ingrediente.nome, 
            nomepadre: ingrediente.nomepadre, 
            prezzo:ingrediente.prezzo, 
            quantita: quantita, 
            categoria: ingrediente.categoria, 
            minvolte:ingrediente.minvolte,
            maxvolte:ingrediente.maxvolte,
            allergeni: ingrediente.allergeni
            })
    }
    if (ingredientitemp.length>0){
        
        let tottemp=corso.prezzo;
        for (let c=0; c<categorie.length; c++){
            let compresiinprezzo=categorie[c].compresiinprezzo;
            for (let i=0; i<ingredientitemp.length; i++){
                if (ingredientitemp[i].categoria===categorie[c].categoria){
                    let qtadapagare=ingredientitemp[i].quantita
                    if (compresiinprezzo>0){
                        if (compresiinprezzo>=qtadapagare){
                            compresiinprezzo-=qtadapagare
                            qtadapagare=0
                        }
                        else{
                            qtadapagare-=compresiinprezzo
                            compresiinprezzo=0
                        }
                    }
                    tottemp+=ingredientitemp[i].prezzo*qtadapagare;
                }
            }
        }
        
        settotprezzoingr(tottemp)
        prodperstemp.ingredienti=ingredientitemp;
        prodperstemp.totale=tottemp
        setprodottopresonalizzato(prodperstemp)
        setkeytorefresh(new Date().getTime())
      }
      else{
        setprodottopresonalizzato({ingredienti:[]})
        setkeytorefresh(new Date().getTime())
      }
  
      //console.log("prodottopersonalizzato ",prodperstemp)
      return prodperstemp;


  }

  function getIngredienti(){
    async function caricaingr(){
      const result=await caricaIngredienti(emailloggato,anagrafica.idpalestra, corso.id);
      //console.log(result)
      setingredienti(result);
    }
    if (emailloggato&&anagrafica&&anagrafica.idpalestra){
      caricaingr();
    }
   }

   function aggiornaQuantitaCarrello(value){
    setquantita(value);
    let prodottoincarrello;
    if(prodottopersonalizzato&&prodottopersonalizzato.id&&prodottopersonalizzato.ingredienti&&prodottopersonalizzato.ingredienti.length>0){
      prodottoincarrello={prodpers: prodottopersonalizzato, prezzo:corso.prezzo, quantita: value, idanagrafica:anagrafica.id}
    }
    else{
      prodottoincarrello={prodotto: corso, prezzo:corso.prezzo, quantita: value, idanagrafica:anagrafica.id}
    }
    
    dispatch(aggiornaprodotti(prodottoincarrello));
    setmessaggio('Carrello aggiornato con successo');
    
   }
  
   useEffect(() => {
    if (corso.configCategorie){
        const categorietemp = JSON.parse(corso.configCategorie);
        setcategorie(categorietemp)
    }
    getIngredienti();
   },[corso.id])
   
  return (
    <div className='container' key={keytorefresh} style={{width:'100%'}} >
      {messaggio&&messaggio.length>0 && 
        <div style={{marginLeft:'40%'}}>
            {messaggio}
        </div>
      }
        <div className='row'>
            <div className='col-md-4 text-center'>
                <img src={corso.foto1&&corso.foto1!==''?corso.foto1:palestra.logo} 
                style={{textAlign:'center', height:'10em', maxWidth:'100%', objectFit:'cover'}} />
                </div>
            <div className='col-md-8'>

              <h4>{corso.nome}</h4>
              {corso.descrizionehtml && 
                <div style={{width:'100%', margin:10}}>
                  <div dangerouslySetInnerHTML={{ __html: corso.descrizionehtml }}></div>
                </div>
                
              }
              <Allergeni allergeni={corso.allergeni}/>
              <b>{prodottopersonalizzato&&prodottopersonalizzato.ingredienti&&prodottopersonalizzato.ingredienti.length>0?totprezzoingr:corso.prezzo} &euro;</b>
            <div>

              {
              /*Questi sono gli ingredienti già scelti dall'utente
              */
            }
            {anagrafica&&anagrafica.id&&prodottopersonalizzato && prodottopersonalizzato.ingredienti && 
            <table style={{width:'100%'}}>
              <tbody>
              {
                prodottopersonalizzato.ingredienti.map(ingr => (
                  <tr key={ingr.id} style={{borderBottomStyle:'dotted', borderBottomColor:'black', borderBottomWidth:1}}>
                    <td>{ingr.nome+" ("+ingr.categoria+")"}</td>
                   <td>
                    {emailloggato&&
                    <div className="row">
                      <div className='col'>
                      <a onClick={()=>aggiornapersonalizzato(ingr, parseInt(parseInt(ingr.quantita)-1))}>-</a>
                      <span>{ingr.quantita}</span>
                      <a onClick={()=>aggiornapersonalizzato(ingr,parseInt(parseInt(ingr.quantita)+1)) }>+</a>
                      </div>
                      <div className='col'>
                      <a onClick={()=>aggiornapersonalizzato(ingr,0) }>Elimina</a>
                      </div>
                    </div>
                    }
                    </td>
                  </tr>
                ))
              }
              </tbody>
              </table>
              }
            </div>

            {/**
            Questi sono gli ingredienti che l'utente può scegliere
             */}
            {ingredienti && ingredienti.length>0 && <div>
              <p style={{marginTop:10}}>Seleziona gli ingredienti</p>
              {
                categorie && categorie.map(categ => (
                  <Accordion key={categ.categoria} expanded={expanded === categ.categoria} onChange={(event,isExpanded) => handleChange(categ.categoria, event, isExpanded)}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={categ.categoria+"bh-content"}
										id={categ.categoria+"bh-header"}>
											<Typography sx={{ width: '33%', flexShrink: 0 }}>
												{categ.categoria}
											</Typography>
									</AccordionSummary>
									<AccordionDetails>
                    {ingredienti.map(ingrediente => (
                       ingrediente.categoria===categ.categoria && 
                      <DettIngrediente key={ingrediente.id} ingrediente={ingrediente} prodottopersonalizzato={prodottopersonalizzato} aggiornapersonalizzato={aggiornapersonalizzato} />
                    ))}
                  </AccordionDetails>
                  </Accordion>
              ))
              }
            </div>
            }


        {anagrafica&&anagrafica.id&&emailloggato&&ingredienti && prodottopersonalizzato 
        && prodottopersonalizzato.ingredienti 
        &&Array.isArray(prodottopersonalizzato.ingredienti) 
        && prodottopersonalizzato.ingredienti.length>0 && 
        <div className="row">
          <div className="col-1" style={{margin:0, padding:0, textAlign:'right'}}>
          <button onClick={()=>parseInt(quantita)>0?setquantita(parseInt(parseInt(quantita<=0?1:quantita)-1)):setquantita(0)}>-</button>
          </div>
          <div className="col-1" style={{margin:0, padding:0}}>
          <input type="number" step={1} value={""+quantita<=0?1:quantita} size={3} style={{textAlign:'center', margin:0, padding:0, width:"100%"}} onChange={(e)=>setquantita(e.target.value)} />
          </div>
          <div className="col-1" style={{margin:0, padding:0}}>
          <button onClick={()=>{setquantita(parseInt(parseInt(quantita<=0?1:quantita)+1))} }>+</button>
          </div>
          <button onClick={()=>{aggiornaQuantitaCarrello(parseInt(quantita<=0?1:quantita)); setprodottopresonalizzato({ingredienti:[]}); setquantita(1); } }>Inserisci nel carrello</button>
        </div>
        }
        {anagrafica&&anagrafica.id&&emailloggato ?
          (!ingredienti || ingredienti.length===0) && 
          <div className="row">
            <div className="col-1" style={{margin:0, padding:0, textAlign:'right'}}>
              <button onClick={()=>parseInt(quantita)>0?aggiornaQuantitaCarrello(parseInt(parseInt(quantita)-1)):aggiornaQuantitaCarrello(0)}>-</button>
              </div>
              <div className="col-1" style={{margin:0, padding:0}}>
              <input type="number" step={1} value={""+(quantita)} size={3} style={{textAlign:'center', margin:0, padding:0, width:"100%"}} onChange={(e)=>setquantita(e.target.value)} />
             </div>
             <div className="col-1"style={{margin:0, padding:0}} >
              <button onClick={()=>{aggiornaQuantitaCarrello(parseInt(parseInt(quantita)+1))} }>+</button>
            </div>
            
          </div>
      :"Eseguire l'accesso per prenotare questo prodotto"  
      }
        </div>
        </div>
        <hr style={{margin:20}} />
        
    </div>
  );
}