import React from "react";

export default function InputText({bootstrapsize, label, id, type, placeholder,name, value, onChange}){

    return (
        <div className={"form-group "+bootstrapsize}>
            {label&&<label htmlFor={id}>{label}</label>}
            <input type={type}
            id={id?id:Math.floor(Math.random() * 100)}
            className={label?"form-control":''}
            placeholder={placeholder} name={name} value={value} onChange={onChange} />
        </div>
    );
}