/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import serverInfo from '../util/globals';
import global from '../util/config'

export default function NuovaAnagrafica () {

    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
   
    const [nome, setnome]= useState('')
    const [cognome, setcognome]= useState('')
    const [sesso, setsesso]= useState('')
    const [dataNascita, setdataNascita]= useState('')
    const [luogonascita, setluogonascita]= useState('')
    const [codiceFiscale, setcodiceFiscale]= useState('')
    const [indirizzo, setindirizzo]= useState('')
    const [citta, setcitta]= useState('')
    const [cap, setcap]= useState('')
    const [telefono, settelefono]= useState('')
    const [cognomeNomeGenitore, setcognomeNomeGenitore]= useState('')
    const [dataNascitaGenitore, setdataNascitaGenitore]= useState('')
    const [codiceFiscaleGenitore, setcodiceFiscaleGenitore]= useState('')
    const [indirizzoGenitore, setindirizzoGenitore]= useState('')
    const [cittaGenitore, setcittaGenitore]= useState('')
    const [capGenitore, setcapGenitore]= useState('')
        
    function handleSubmit(e){
          e.preventDefault();
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra', palestra.id);
        formData.append('nome', nome);
        formData.append('cognome', cognome);
        formData.append('sesso', sesso);
        formData.append('dataNascita', dataNascita);
        formData.append('luogonascita', luogonascita);
        formData.append('codiceFiscale', codiceFiscale);
        formData.append('indirizzo', indirizzo);
        formData.append('citta', citta);
        formData.append('cap', cap);
        formData.append('telefono', telefono);
        formData.append('emailanag', email);
        formData.append('cognomeNomeGenitore', cognomeNomeGenitore);
        formData.append('dataNascitaGenitore', dataNascitaGenitore);
        formData.append('codiceFiscaleGenitore', codiceFiscaleGenitore);
        formData.append('indirizzoGenitore', indirizzoGenitore);
        formData.append('cittaGenitore', cittaGenitore);
        formData.append('capGenitore', capGenitore);
        axios.post(serverInfo.namesec+'/user/insAnagraficaSelezionata', formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },)
        .then((result) => {
			console.log(result.data)
            if (result.data
                &&!isNaN(result.data)){
                    alert('Anagrafica inserita correttamente')
                }
                else{
        	        alert(result.data)
                }
        });
	  }

      return (
        <form id="formAnag" onSubmit={handleSubmit}>
            <button className="elementor-button elementor-size-sm">
            <FontAwesomeIcon icon={faSave} />
			</button>

        
        <div className="row">
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Nome</label>
            <input placeholder="Nome" className="form-control" type="text" size="15" name="nome" value={nome} required="required" onChange={(e) => setnome(e.target.value)}/>
            </div>
            </div>
            
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Cognome</label>
            <input placeholder="Cognome" className="form-control" type="text" size="14" name="cognome" value={cognome} required="required" onChange={(e) => setcognome(e.target.value)}/>
            </div>
            </div>
            <div className="col-md-1">
            <div className="form-group">
            <label className="control-label">Sesso</label>
            <select className="form-control" name="sesso" placeholder="sesso" value={sesso}  onChange={(e) => setsesso(e.target.value)}>
                    <option></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                    </select>
            </div>
            </div>
            
        </div>
        <div className="row">
        <div className="col-md-4">
            <div className="form-group">
               <label>Data di nascita <small className="text-muted">yyyy-mm-dd</small></label>
               <input type="date" name="dataNascita" className="form-control date-inputmask" id="date-mask" placeholder="Enter Date" value={dataNascita} required="required" onChange={(e) => setdataNascita(e.target.value)}/>
           </div>
           <div className="form-group">
               <label>Luogo nascita</label>
               <input type="text" name="luogonascita" className="form-control" placeholder="luogonascita" value={luogonascita} onChange={(e) => setluogonascita(e.target.value)}/>
           </div>
            </div>
            <div className="col">
            <div className="form-group">
            <label className="control-label">Codice fiscale</label>
            <input type="text" className="form-control" name="codiceFiscale" maxLength="16" value={codiceFiscale}  onChange={(e) => setcodiceFiscale(e.target.value)}/>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
            <div className="form-group">
            <label className="control-label">Indirizzo</label>
            <input placeholder="via" type="text" name="indirizzo" className="form-control" value={indirizzo} maxLength="255" onChange={(e) => setindirizzo(e.target.value)}/>
            </div>
            </div>
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Città</label>
            <input className="form-control" placeholder="Citt&agrave;" type="text" name="citta" value={citta} maxLength="100" onChange={(e) => setcitta(e.target.value)}/>
            </div>
            </div>
            <div className="col">
            <div className="form-group">
            <label className="control-label">CAP</label>
            <input className="form-control" type="text" size="8" maxLength="5" name="cap" value={cap} onChange={(e) => setcap(e.target.value)}/>
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col">
        <div className="form-group">
        <label className="control-label">Tel</label>
        <input className="form-control" type="tel" value={telefono} maxLength="24" name="telefono" required="required" placeholder="Telefono" onChange={(e) => settelefono(e.target.value)}/>
        </div></div>
        <div className="col">
        <div className="form-group">
        <label className="control-label">Email </label>
        &nbsp;<strong>{email}</strong>
        </div>
        </div>
        </div>
        
        
        <div className="row">
        <div className="col">
        <h4>Eventuali dati del genitore/tutore</h4>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <label className="control-label">Cognome e Nome</label>
        <input className="form-control" type="text" name="cognomeNomeGenitore" value={cognomeNomeGenitore} onChange={(e) => setcognomeNomeGenitore(e.target.value)}/>
        </div>
        <div className="col">
            <div className="form-group">
            <label>Data nascita </label>
            <input type="date" className="form-control date-inputmask" id="date-mask" placeholder="Data nascita genitore" name="dataNascitaGenitore" value={dataNascitaGenitore} onChange={(e) => setdataNascitaGenitore(e.target.value)}/>
        </div>
            </div>
        <div className="col-md-4">
        <div className="form-group">
        <label className="control-label">Codice fiscale/P. IVA</label>
        <input className="form-control" type="text" name="codiceFiscaleGenitore" value={codiceFiscaleGenitore} onChange={(e) => setcodiceFiscaleGenitore(e.target.value)}/>
        </div></div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <div className="form-group">
        <label className="control-label">Indirizzo</label>
        <input className="form-control" type="text" name="indirizzoGenitore" value={indirizzoGenitore} onChange={(e) => setindirizzoGenitore(e.target.value)}/>
        </div>
        </div>
        <div className="col-md-4">
        <div className="form-group">
        <label className="control-label">Città</label>
        <input className="form-control" type="text" name="cittaGenitore" value={cittaGenitore} onChange={(e) => setcittaGenitore(e.target.value)}/>
        </div>
        </div>
        <div className="col">
        <div className="form-group">
        <label className="control-label">CAP</label>
        <input className="form-control" type="text" name="capGenitore" value={capGenitore} onChange={(e) => setcapGenitore(e.target.value)}/>
        </div>
        </div>
        </div>
        
        </form>
        );
  }