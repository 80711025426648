import React, { useEffect } from "react";
import '../util/config'
import { useDispatch, useSelector } from "react-redux";
import { getTipi } from "../DAO/tipiprodottiDAO";
import { settipiprodotto, settipoprodotto } from "../STORE/reducers/gestoreTipiProdotto";
import { navigate } from "hookrouter";

export default function TipiCorsoCard() {
  	const tipi = useSelector(state => state.gestoreTipiProdotto.tipi);
	const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
	const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

	  const dispatch = useDispatch();

	  function caricaTipiProdotto() {
	
		//console.log('carico lista tipi');
		async function caricaTipi() {
		  const tipidb = await getTipi(
			palestra.id,
			'','',1
		  );
		  //console.log(tipidb);
		  dispatch(settipiprodotto(tipidb));
		  //dispatch(settipoprodotto({id:0}));
		}
		if (palestra&&palestra.id){
		  caricaTipi();
		}
		
	  }
	
	  useEffect(() => {
		console.log("TipiCorsoCard ")
		caricaTipiProdotto();
	  },[palestra, anagrafica]);

	  function showCalendar(tipo) {
		console.log("tipo selezionato: ",tipo)
		dispatch(settipoprodotto(tipo));
	  }
	  
	    return (
			<div className="container">
			<div className="row">
				<div className="col-12">
				</div>
				{
					tipi?
					tipi.map((item,index) =>(
						<div key={index} className="col-md-6 " style={{marginBottom:'20px'}}
						onClick={()=>{showCalendar(tipi[index]); navigate('/anntt-client-calendario/')}}>
							{/* <Link to={props.linkcalendario} > */}
						<div className="card " key={index}>
							<div>
										<img className="card-img-top" src={item.foto} style={{height:'20em', maxWidth:'20em', objectFit:'cover'}} alt={item.nome} />
							</div>
							<div className="card-body flex-column">
							<h5 className="card-title">{item.nome}</h5>
							<p className="card-text">{item.descrizione}</p>
							</div>
							<div className="card-footer">
							<small className="text-muted">{item.place} {item.nomecalendario}</small>
							</div>
						</div>
						{/* </Link> */}
						</div>
					)
					)
					:null
	    		}
				</div>
				</div>
	    );
	}