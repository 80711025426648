/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import '../util/config'
import AnagraficaAssociata from "../anagrafica/AnagraficaAssociata";
import { useSelector } from "react-redux";
import { getAnagraficheCorrelate } from "../DAO/anagraficaDAO";

export default function AnagraficheAssociate ({showlistanag, link}) {
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const token = useSelector(state => state.gestoreUtente.token);

  const [anags, setanags] = useState([]);
  const [toggle, settoggle] = useState(false)
  const [key, setkey] = useState(new Date().getTime())

  function getAnagAss(){
    async function getAnags(){
      if (token&&email){
        const result = await getAnagraficheCorrelate(email, palestra.id, token)
        console.log(result, "getAnagraficheCorrelate")
        setanags(result)
        //setkey(new Date().getTime())
      }
    }
    getAnags()
  }

  useEffect(() =>{
        getAnagAss()
	},[email, palestra, token]);

      return (
        <div>
        {(email||(localStorage.getItem('email')&&localStorage.getItem('email')!==''))&&
          <div key={key} className="container">
            <div className="row">
              <div className="col">
                <h3 onClick={() => settoggle(!toggle)} style={{cursor:'pointer',color:'black'}}>Entra come cliente</h3>
              </div>
              <div className="col">
                <button onClick={() => window.location.href="/annotatioc-insanag"}>Registrati come cliente</button>
              </div>
            </div>

            <div className="row">
              {
              anags&&Array.isArray(anags)&&anags.length>1&&
                <div className="col-md">
                
                
                {(toggle||showlistanag)&&anags&&Array.isArray(anags)&&anags.map(anagrafica =>(
                  <AnagraficaAssociata key={anagrafica.id}
                  anagrafica={anagrafica} link={link} />
                )
                )}
              
                </div>
              }
              {
                anags
                &&Array.isArray(anags)
                &&anags.length===1&&
                <div className="col-md">
                {anags||showlistanag&&Array.isArray(anags)&&anags.map(anagrafica =>(
                  <AnagraficaAssociata key={anagrafica.id}
                  anagrafica={anagrafica} link={link}/>
                )
                )}
                </div>
              }
                
                {!anags||(Array.isArray(anags)&&anags.length===0)&&
                <div className="col">
                  Sembra che al momento non sei cliente
                </div>}
          </div>
          </div>
                }

              </div>
        );
  }