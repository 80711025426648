import axios from 'axios';
import serverInfo from '../util/globals';


export async function caricatemplates(palestra) {
    const formData = new FormData();
    formData.append('palestra', palestra);
    const response = await axios.post(
        serverInfo.namesec+'/doc/getListaDoc',
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
    );
    return response.data;
}

export async function savetemplate(palestra, id, descrizione, contenuto, idanag) {
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('id', id?id:0);
    formData.append('descrizione', descrizione);
    formData.append('contenuto', contenuto);
    formData.append('idanag', idanag);
    const response = await axios.post(
        serverInfo.namesec+'/doc/salvaTemplateDoc',
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
    );
    return response.data;
}

export async function eliminatemplate(palestra, id) {
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('id', id?id:'');
    const response = await axios.post(
        serverInfo.namesec+'/doc/eliminaDoc/'+id,
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
    );
    return response.data;
}

export async function stampadochtml(palestra, email, html,idanag){
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('email', email);
    formData.append('html', html);
    formData.append('idanag', idanag);
    axios.post(
        serverInfo.namesec+'/doc/stampahtml', 
        formData,
        {
        withCredentials: true,
        responseType: 'blob',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
      ).then((response) => {
        window.open(URL.createObjectURL(response.data));
      })
}

export async function stampaIncarico(palestra, idanag){
    console.log('stampaIncarico '+palestra, idanag)
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('idanag', idanag);
    axios.post(
        serverInfo.namesec+'/doc/stampaIncarico', 
        formData,
        {
        withCredentials: true,
        responseType: 'blob',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
      ).then((response) => {
        window.open(URL.createObjectURL(response.data));
      })
}


export async function stampaInformativa(palestra, idanag){
    console.log('stampaIncarico '+palestra, idanag)
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('idanag', idanag);
    axios.post(
        serverInfo.namesec+'/doc/stampaInformativa', 
        formData,
        {
        withCredentials: true,
        responseType: 'blob',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
      ).then((response) => {
        window.open(URL.createObjectURL(response.data));
      })
}
export async function salvadoc(palestra, email, id, titolo, html,idanag, tipo, idtipomovimento){
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('palestra', palestra);
    formData.append('email', email);
    formData.append('html', html);
    formData.append('idanag', idanag);
    formData.append('oggetto', titolo);
    formData.append('descrizione', titolo);
    formData.append('id', id);
    formData.append('tipo', tipo);
    formData.append('idtipomovimento', idtipomovimento);
    const response = await axios.post(
        serverInfo.namesec+'/proto/inserisciProto', 
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
        );
      return response.data
}

export async function documentisalvati(palestra, idanag){
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('idanag', idanag);
    const response = await axios.post(
        serverInfo.namesec+'/proto/getprotouscitaanag', 
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
        );
      return response.data
}

export async function documentiricevuti(palestra, idanag){
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('idanag', idanag);
    const response = await axios.post(
        serverInfo.namesec+'/proto/getprotoentrataanag', 
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
        );
      return response.data
}

export async function eliminadoc(id, palestra, email){
    const formData = new FormData();
    formData.append('id', id);
    formData.append('palestra', palestra);
    formData.append('website', global.config.domain);
    formData.append('email', email);
    const response = await axios.post(
        serverInfo.namesec+'/proto/eliminaProto/'+id, 
        formData,
        {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
        },
        );
      return response.data
}

export async function handleMostraFile(id, email,palestra){
    const formData = new FormData();
  formData.append('id', id);
  formData.append('website', global.config.domain);
  formData.append('email',email);
  formData.append('lic','000');
  formData.append('palestra',palestra);
  const response = await axios.post(serverInfo.namesec+'/proto/caricaFilesProtocolloRest/'+id, 
        formData,
      {
        withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
      },
    )
    return response.data
  }

  export async function handleEliminaFile(idfile,email, palestra) {
    const formData = new FormData();
    formData.append('id', idfile);
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra);
    const response = await axios.post(serverInfo.namesec+'/proto/eliminaFile/'+idfile, 
          formData,
          {
            withCredentials: true,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
            },
          },
    )
    return response.data
}

export async function handleUploadFile(id, file, email, palestra) {
   
    const config = {
        withCredentials: true,
        headers: {
            'content-type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
        },
    }
    const formData = new FormData();
    formData.append('id', id);
    formData.append('file',file)
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra);
    const response = await axios.post(serverInfo.namesec+'/proto/salvaFileProtocolloRest/'+id, 
          formData, 
          config
  )
  return response.data
   
}