import React from "react";
import { connect } from "react-redux";
import '../util/config'

class RicevutaCliente extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		anno: this.props.selected.anno,
	    		data: this.props.selected.data,
	    		importo:this.props.selected.importo,
	    		numero:this.props.selected.numero,
				idpagamento:this.props.selected.idpagamento,
				idabbonamento:this.props.selected.idabbonamento,
	    		visible: false,
	    		messaggio:null
	    	}
	    this.openRicevuta=this.openRicevuta.bind(this);
	  }


	
	
	openRicevuta(event){
		event.preventDefault()

		let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", global.config.server+'/stampaRicevuta');
		
		form.setAttribute("target", windowName);
		
		var email = document.createElement("input"); 
		email.setAttribute("type", "hidden");
		email.setAttribute("name", "email");
		email.setAttribute("value", this.props.email);
		form.appendChild(email);

        var palestra = document.createElement("input"); 
		palestra.setAttribute("type", "hidden");
		palestra.setAttribute("name", "palestra");
		palestra.setAttribute("value", this.props.palestra.id);
		form.appendChild(palestra);

		var website = document.createElement("input"); 
		website.setAttribute("type", "hidden");
		website.setAttribute("name", "website");
		website.setAttribute("value", global.config.domain);
		form.appendChild(website);

		var idRicevuta = document.createElement("input"); 
		idRicevuta.setAttribute("type", "hidden");
		idRicevuta.setAttribute("name", "id");
		idRicevuta.setAttribute("value", this.state.id);
		form.appendChild(idRicevuta);

		document.body.appendChild(form);
		
		window.open('', windowName,'_blank');
		
		form.submit();
	}

	

	
	render() {
	    return (
	    		<div className="row">
					<div className="col-sm-12">
						
						<div className="row">
							<div className="col-sm">
								<label className="control-label">Anno</label>
								<input className="form-control" type="text" maxLength="5" readOnly={true} value={this.state.anno} />
							</div>
							<div className="col-sm">
								<label className="control-label">Data</label>
								<input className="form-control" type="date"  maxLength="50" name="data" value={this.state.data} readOnly={true} />
							</div>
							<div className="col-sm">
								<label className="control-label">Importo</label>
								<input className="form-control" type="number" name="importo" value={this.state.importo} readOnly={true} />
							</div>
								<div className="col-sm">
								<label className="control-label">Numero</label>
								<input className="form-control" type="text" name="numero" value={this.state.numero} readOnly={true} />
							</div>

							<div className="col">
								<a className="btn btn-warning" style={{color:"white"}} onClick={this.openRicevuta}>Visualizza</a>
							</div>
						</div>
					</div>
					<div className="row">
						<hr />
					</div>
				</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraAnagrafica,
			email: state.gestoreUtente.emailloggato
		}
	  }
	  
	  export default connect(mapStateToProps, null)(RicevutaCliente)