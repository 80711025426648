import React from "react";
import axios from 'axios';
import '../util/config'
import ReactSvgTimer from 'react-svg-timer'
import sound from '../audio/sveglia.mp3'
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

var audio = new Audio(sound)

class EsercizioSchedaCliente extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
               esercizio:null,
               resetRequested:false,
               timerIsComplete:false,
               logMilliseconds:false,
                play:false
	    }
      
        this.update=this.update.bind(this)
	  }

      onComplete = (status) => {
        console.log(status)
        if (status){
          var that=this
          if (audio){
            audio.play();
          }
        
          setTimeout(function(){
            that.setState({resetRequested:true});
          }, 3000);
        }
        else{
          //that.setState({resetRequested:false});
          return
        }
      }
      
      onReset = () => {
        console.log('onreset')
        if (this.state.resetRequested){
          console.log('resetRequested true')
        if (audio){
          audio.pause()
          
        }
        this.setState({resetRequested:false});
      }
        
      }
      
      timerValue = (value) => {
        if (this.state.logMilliseconds) {
          console.log(value);
        }
      }
      
      onResetRequest = () => {
        console.log('onResetRequest')
        //if (audio){
        //  audio.pause()
        //}
        //this.setState({resetRequested:true});
      }

      update(e) {
        const self = this;
        this.setState({ [e.target.name]: e.target.value })
        if(this.timeout) clearTimeout(this.timeout);
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
         }
        
        this.timeout = setTimeout(() => {
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email',this.props.email);
            formData.append('lic','000');
            formData.append('palestra',this.props.palestra.id);
            formData.append('id', this.state.idschedaesercizi);
            formData.append('peso', this.state.peso?this.state.peso:'');
            formData.append('recupero', this.state.recupero?this.state.recupero:0);
            formData.append('ripetizioni', this.state.ripetizioni?this.state.ripetizioni:'');
            formData.append('serie', this.state.serie?this.state.serie:'');
            formData.append('tut', this.state.tut?this.state.tut:'');
            formData.append('ordine', this.state.ordine?this.state.ordine:1);
            formData.append('numeroGiorno', this.state.numeroGiorno?this.state.numeroGiorno:'');
            formData.append('note', this.state.note?this.state.note:'');
            formData.append('idesercizio', this.state.idesercizio.idesercizio);

	      axios.post(global.config.server+'/aggiornaEsercizioScheda/'+this.state.idschedaesercizi,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				alert(result.data)
	      }).catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
          })
        }, 2000);
    }

      getImgEsercizio(esercizio){
          var fileextension=esercizio.link.split('.').pop()
        if (fileextension==='png'
            ||fileextension==='gif'){
                return (
                    <img style={{width:'100%'}} className={"card-img-top"} src={this.props.anagrafica
                      &&this.props.anagrafica.sesso==='F'
                      &&esercizio.link2?esercizio.link2:esercizio.link} alt={esercizio.descrizione} />
                )
        }
      }

	componentDidMount(){
        console.log(this.props.esercizio)
        this.setState({...this.props.esercizio})
        audio.addEventListener('ended', () => this.setState({ play: false }));
    }

	render() {
        const styleInputEser={
            border: 'none',
            borderBottom: '1px solid blue',
            width:'4em'
        }
	    return ( 
            this.state.idschedaesercizi
            &&this.state.numeroGiorno===this.props.giornoSelected?
                   <div className="container-fluid">
                       <div className="row">
                       <div className="col-md-12">{this.state.ordine} ) {!this.state.esercizio2?this.state.idesercizio.descrizione:
                       "MULTISET"}</div>

                       <div className="row mb-3 d-flex align-items-stretch">
                        <div className={this.state.esercizio2?"card col-6 p-3 bg-body":"card col-12 p-3 bg-body"}>
                        {this.getImgEsercizio(this.state.idesercizio)}
                          <div className="card-body flex-column">{this.state.esercizio2?this.state.idesercizio.descrizione:null}</div>
                            <div className="card-footer"><small className="text-muted">
                              <div className="row">
                              <div className="col-6"><label>Ripet.<br /><input type="text" name="ripetizioni" value={this.state.ripetizioni} onChange={this.update} style={styleInputEser} /></label></div>
                              <div className="col-6"><label>Peso<br /><input type="text" name="peso" value={this.state.peso} onChange={this.update} style={styleInputEser} /></label></div>
                              </div>
                              </small></div>
                         
                        </div>
                        {
                          this.state.esercizio2?
                        <div className="card col-6 p-3 bg-body">
                        {this.getImgEsercizio(this.state.esercizio2)}
                          <div className="card-body flex-column">{this.state.esercizio2.descrizione}</div>
                            <div className="card-footer"><small className="text-muted">
                              <div className="row">
                              <div className="col-6"><label>Ripet.<br /><input type="text" name="ripetizioni2" value={this.state.ripetizioni2} onChange={this.update} style={styleInputEser} /></label></div>
                              <div className="col-6"><label>Peso<br /><input type="text" name="peso2" value={this.state.peso2} onChange={this.update} style={styleInputEser} /></label></div>
                              </div>
                              </small></div>
                          
                        </div>
                        :null}

                        {this.state.esercizio3?
                        <div className="card col-6 p-3 bg-body">
                        {this.getImgEsercizio(this.state.esercizio3)}
                        <div className="card-body flex-column">{this.state.esercizio3.descrizione}</div>
                        <div className="card-footer"><small className="text-muted">
                              <div className="row">
                              <div className="col-6"><label>Ripet.<br /><input type="text" name="ripetizioni3" value={this.state.ripetizioni3} onChange={this.update} style={styleInputEser} /></label></div>
                              <div className="col-6"><label>Peso<br /><input type="text" name="peso3" value={this.state.peso3} onChange={this.update} style={styleInputEser} /></label></div>
                              </div>
                              </small>
                              </div>
                          </div>
                        :null}

                        {this.state.esercizio4?
                        <div className="card col-6 p-3 bg-body">
                        {this.getImgEsercizio(this.state.esercizio4)}
                        <div className="card-body flex-column">{this.state.esercizio4.descrizione}</div>
                        <div className="card-footer"><small className="text-muted">
                              <div className="row">
                              <div className="col-6"><label>Ripet.<br /><input type="text" name="ripetizioni4" value={this.state.ripetizioni4} onChange={this.update} style={styleInputEser} /></label></div>
                              <div className="col-6"><label>Peso<br /><input type="text" name="peso4" value={this.state.peso4} onChange={this.update} style={styleInputEser} /></label></div>
                              </div>
                              </small></div>
                          </div>
                        :null}

                        {this.state.esercizio5?
                        <div className="card col-6 p-3 bg-body">
                        {this.getImgEsercizio(this.state.esercizio5)}
                        <div className="card-body flex-column">{this.state.esercizio4.descrizione}</div>
                        <div className="card-footer"><small className="text-muted">
                              <div className="row">
                              <div className="col-6"><label>Ripet.<br /><input type="text" name="ripetizioni5" value={this.state.ripetizioni5} onChange={this.update} style={styleInputEser} /></label></div>
                              <div className="col-6"><label>Peso<br /><input type="text" name="peso5" value={this.state.peso5} onChange={this.update} style={styleInputEser} /></label></div>
                              </div>
                              </small>
                          </div>
                        </div>
                        :null}

                       </div>
                       </div>
                        <div className="row">
                       <div className="col-4"><label>Serie<br /><input type="text" name="serie" value={this.state.serie} onChange={this.update} style={styleInputEser} /></label></div>
                       
                       <div className="col-4"><label>Recupero<br /><input type="text" name="recupero" value={this.state.recupero} onChange={this.update} style={styleInputEser} />
                       </label>
                       {this.state.recupero&&this.state.recupero>0?
                       <ReactSvgTimer 
                       timerCount={this.state.recupero}
                       countdownColor="green"
                       innerColor="#fff"
                       outerColor="#000"
                       
                       resetTimer={this.onReset}
                       completeTimer={this.onComplete}
                       resetTimerRequested={this.state.resetRequested}
                       timerDuration={this.timerValue(this.state.recupero)}
                       displayCountdown={true}/>
                       :null}
                       </div>
                       <div className="col-4"><label>T.U.T.<br /><input type="text" name="tut" value={this.state.tut} onChange={this.update} style={styleInputEser} /></label></div>
                       </div>
                       <hr />
                   </div>
                   :
                   null
	    );
	  }
	}

  const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraAnagrafica,
        email: state.gestoreUtente.emailloggato,
        anagrafica:state.gestoreUtente.anagrafica,
    }
  }
  
  export default connect(mapStateToProps, null)(EsercizioSchedaCliente)