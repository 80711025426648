/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import '../util/config'
import CalendarioMenuPlaceCliente from './CalendarioMenuPlaceCliente';
import { getPlaces, getTipi } from '../DAO/tipiprodottiDAO';
import { useSelector } from 'react-redux';
import { getCalendario } from '../DAO/calendarioDAO';

export default function CalendarioMenuCliente ({tipo, calendario, linkcalendario, aggiornaCarrello}) {

	const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const tiposessione = useSelector(state => state.gestoreTipiProdotto.tipo);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
	const calendarioselezionato = useSelector(state => state.gestoreCalendari.calendariocliente);
  const token = useSelector(state => state.gestoreUtente.token);
  const [appuntamenti, setappuntamenti] = useState([]);
  const [places, setplaces] = useState([]);
  const [tipi, settipi] = useState([]);

  function getPlacesTipo(){
    async function readplaces(){
      const placestemp = await getPlaces(tipo?tipo.id:tiposessione.id);
      console.log(placestemp)
      setplaces(placestemp);
    }
    readplaces();
  }

  function getTipologieCorsoCalendarioRest(){
    async function readplaces(){
      const tipitemp = await getTipi(palestra.id, tipo?tipo.id:tiposessione.id,
      calendario?calendario:'',0);
      settipi(tipitemp);
    }
    readplaces();
  }

  function calendarioCliente(){
    async function read(){
      const calendario = await getCalendario(anagrafica.email, palestra.id, tipo?tipo.id:tiposessione.id,anagrafica.id, token);
      setappuntamenti(calendario);
    }
    if (anagrafica&&anagrafica.id){
      read();
    }
  }

  useEffect(() =>{
    getPlacesTipo();
    getTipologieCorsoCalendarioRest();
    calendarioCliente();
  },[calendarioselezionato])
 
    return (
      <div>
          {places?
          places.map(place =>(
              <CalendarioMenuPlaceCliente key={place.id} 
              dataISO={new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2)}
              place={place} 
              linkcalendario={linkcalendario}
              tipicorso={tipi} 
              tipocorso={tipo?tipo:tiposessione}
              appuntamenti={appuntamenti} 
              aggiornaCarrello={aggiornaCarrello} 
              showplace={places.length>1}
              />
          ))
        :""}
      </div>
    );
}