/* eslint-disable react/prop-types */
import React from "react";
import { useRoutes, A } from "hookrouter";
import Toolbar from "./toolbar/Toolbar";
import Intestazione from "./toolbar/Intestazione";
import LoadMainThings from "./util/LoadMainThings";
import { routes } from "./util/routes";

const showDebugInfo=localStorage.getItem('debug')?true:false
function AppRoute(props) {
    const routeResult = useRoutes(routes);

  return (
    <div className="App">
     { showDebugInfo &&
     <div>
     <A href="/annotatioc-prods/">Categorie</A> - 
      <A href="/annotatioc-carrello/">Carrello</A> - 
      <A href="/annotatioc-ordini/">Ordini</A> -
      </div>
      }
      {props.showheader&&<Intestazione />}
      {props.showtoolbar&&<Toolbar />}
      <LoadMainThings showDebugInfo={showDebugInfo} email={props.email} t={props.t}
      nome={props.nome} cognome={props.cognome} telefono={props.telefono} />
      {routeResult}
    </div>
  );
}

export default AppRoute;
