import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import SchedaCompCorp from "./SchedaCompCorp";
import ListaSchedeDiarioAlimentare from "../diarioalimentare/ListaSchedeDiarioAlimentare";
import 'react-tabs/style/react-tabs.css';
import { connect, useSelector } from "react-redux";

export default function ListaSchedeCompCorp() {
    const [schedeCC, setschedeCC] = useState([]);
    const [selectedItem, setselectedItem] = useState('CC');

    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

	useEffect(() => {
        if (palestra&&palestra.id&&anagrafica&&anagrafica.id){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('idanagrafica',anagrafica&&anagrafica.id?anagrafica.id:'');

        axios.post(global.config.server+"/getSchedeCC", formData)
        .then(response=>{
            setschedeCC(response.data)
        });
    }
    },[palestra,anagrafica]);

	    return (
            <div className="container">
            <div className="row">
            <div className={"card col-6 " +(selectedItem==='CC'?"border-primary":null)} onClick={()=>{setselectedItem('CC')}} >
            <img className="card-img-top" src="https://annotatio.online/wp-content/uploads/2022/08/bmi.jpeg" alt="BMI"/>
            <div className={"card-body "+(selectedItem==='CC'?"text-primary":null)}>
            <h5 className="card-title">Composizione Corporea</h5>
            </div>
            </div>
            <div className={"card col-6 "+(selectedItem==='DA'?"border-primary":null)} onClick={()=>{setselectedItem('DA')}}>
            <img className="card-img-top" src="https://annotatio.online/wp-content/uploads/2022/08/diarioAlimentare.jpeg" alt="Diario alimentare" />
            <div className={"card-body "+(selectedItem==='DA'?"text-primary":null)}>
            <h5 className="card-title">Diario Alimentare</h5>
            </div>
            </div>
            </div>
            
            {palestra.id&&anagrafica.id?
      
                
				selectedItem==='CC'?
				<div>
                <SchedaCompCorp scheda={schedeCC[0]} email={email} />
                <hr />
                <h4>Storico misurazioni</h4>
                <div className="table-responsive">
                <table className="table table-striped" style={{borderWidth:'1px', borderColor:'black', borderStyle:'dashed'}}>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">
                                Data
                            </th>
                            <th scope="col">
                                Altezza
                            </th>
                            <th scope="col">
                                Peso
                            </th>
                            <th scope="col">
                                BMI
                            </th>
                            <th scope="col">
                                Bicipite
                            </th>
                            <th scope="col">
                                Tricipice
                            </th>
                            <th scope="col">
                                Sottoscapolare
                            </th>
                            <th scope="col">
                                Sovrailiaca
                            </th>
                            <th scope="col">
                                TOT. PLICHE
                            </th>
                            <th scope="col">
                                Circ.Vita
                            </th>
                            <th scope="col">
                                Circ.Fianchi
                            </th>
                            <th scope="col">
                                WHR
                            </th>
                            <th scope="col">
                                % massa grassa
                            </th>
                            <th scope="col">
                                kg massa grassa
                            </th>
                            <th scope="col">
                                kg massa magra
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            schedeCC.map(scheda=>(
                        <tr key={scheda.id}>
                            <td>
                                {scheda.data}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.altezza}
                            </td>
                            <td style={{textAlign:'right'}}>
                                {scheda.peso}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.bmi}</strong>
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plbicipite}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.pltricipite}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plsottoscapolare}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plsovrailiaca}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plbicipite+scheda.pltricipite+scheda.plsottoscapolare+scheda.plsovrailiaca}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.circvita}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.circfianchi}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{Math.round(parseFloat(scheda.circvita/scheda.circfianchi)*100)/100}</strong>
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.percmassagrassa}</strong>
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.massagrassa}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.massamagra}
                            </td>
                        </tr>
                            ))
                        }
                    
                    </tbody>
                </table>
                </div>
			</div>    
    :selectedItem==='DA'?
				<div>
                <ListaSchedeDiarioAlimentare email={email} />
				</div>
                :null
            :"Nessuna anagrafica selezionata"}
            </div>
        );
	}