/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CalendarioGiornaliero from './CalendarioGiornaliero';
import {useDispatch, useSelector} from 'react-redux';
import { getCalendario } from '../DAO/calendarioDAO';
import { setcalendariocliente } from '../STORE/reducers/gestoreCalendari';

export default function Calendario() {
  var days = ['DOM', 'LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'];

  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const token = useSelector(state => state.gestoreUtente.token);

  const tipo = useSelector(state => state.gestoreTipiProdotto.tipo);
  const calendario = useSelector(
    state => state.gestoreCalendari.calendariocliente,
  );
  const dispatch = useDispatch();

  const [index, setIndex] = React.useState(0);

  const [giornoSelezionato, setGiornoSelezionato] = useState(new Date());
  const [week, setWeek] = useState([]);
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState(null);
  const [lastDayOfTheWeek, setLastDayOfTheWeek] = useState(null);

  function getWeekDays(day) {
    var daytemp=new Date();
    var firstday = new Date(daytemp.setDate(day.getDate() - (day.getDay())));
    var lastday = new Date(daytemp.setDate(day.getDate() - (day.getDay())+6));
    firstday.setDate(firstday.getDate()+1);
    lastday.setDate(lastday.getDate()+1);
    setFirstDayOfTheWeek(firstday);
    setLastDayOfTheWeek(lastday);

    let days = [];
    for (let i = 0; i < 7; i++) {
      var dataTemp = new Date(firstday);
      days.push(new Date(dataTemp.setDate(firstday.getDate() + i)));
    }
    setWeek(days);
  }

  let calnd;

  function caricaCalendario() {
    async function caricaCalend() {
      const calendariodb = await getCalendario(
        anagrafica.email,
        anagrafica.idpalestra,
        tipo.id,
        anagrafica.id,
        token
      );
      //console.log('calendariodb');
      calnd=calendariodb;
      dispatch(setcalendariocliente(calendariodb));
    }
    caricaCalend();
  }

  useEffect(() => {
   
    getWeekDays(giornoSelezionato);
    caricaCalendario();
  }, [tipo]);

  function addWeek(numberOfDays) {
    var giornoTemp = new Date(giornoSelezionato);
    giornoTemp.setDate(giornoTemp.getDate() + numberOfDays);
    setGiornoSelezionato(giornoTemp);
    getWeekDays(giornoTemp);
  }

  return (
    <div style={{flex:1}} >
        {/* <TipiCorsoHoriz /> */}
      
        <Tabs>
          <TabList>

        {week.map(day => (
          <Tab
            key={day.getDate()}>
            {days[day.getDay()]} {("0" + day.getDate()).slice(-2) + '/' + (("0" + (day.getMonth() + 1)).slice(-2))}
            </Tab>
        ))}
        </TabList>
        {week.map((day,indice) => (
          <TabPanel
            key={day.getDate()}> 
            <CalendarioGiornaliero calendario={calendario}
            setIndex={setIndex}
            index={indice}
             data={("0" + day.getDate()).slice(-2) + '/' + (("0" + (day.getMonth() + 1)).slice(-2))+'/'+day.getFullYear()} />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
}