import {createSlice} from '@reduxjs/toolkit';

export const gestorePrenotazioniClienteSlice = createSlice({
  name: 'gestorePrenotazioniCliente',
  initialState: {
    prenotazioni: [],
  },
  reducers: {
    setprenotazionicliente: (state, prenotazioni) => {
      console.log('setprenotazionicliente ' + prenotazioni.payload);
      state.prenotazioni = prenotazioni.payload;
    },
  },
});

export const {setprenotazionicliente} = gestorePrenotazioniClienteSlice.actions;

export default gestorePrenotazioniClienteSlice.reducer;
