import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { documentisalvati, handleMostraFile } from "../DAO/DocumentiDAO";
import InputText from "../util/UI/InputText";
import ListaFilesProto from "./listaFilesProto";
import Text from "../util/UI/Text";

export default function ProtoUscitaAnag(){
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [docsalvati, setdocsalvati] = useState([])

    const [id, setid] = useState(0)
    const [titolo, settitolo] = useState('')
    const [html, sethtml] = useState('')
    const [tipo, settipo] = useState('E')
    const [idtipomovimento, setidtipomovimento] = useState('')
    const [files, setfiles]=useState([])

    function docricevutianag(){
        async function carica(){
            let result = await documentisalvati(palestra.id, anagrafica.id);
            console.log(result)
            setdocsalvati(result)
        }
        carica();
    }

    function selectdocinviato(index){
        setid(docsalvati[index].id)
        sethtml(docsalvati[index].htmlreadable)
        settitolo(docsalvati[index].oggetto)
        setidtipomovimento(docsalvati[index].idtipomovimento)
        settipo(docsalvati[index].tipo)
        async function caricaFiles(){
            const result = await handleMostraFile(docsalvati[index].id, email, palestra.palestra.id)
            setfiles(result)
        }
        caricaFiles()
        //setkeytorefresh(new Date().getTime())
    }

    function stampahtmlpers() {
        var divContents = document.getElementById("printarea").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > ');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }


    useEffect( ()=> {
        docricevutianag()
    },[palestra])

    return (
        <div className="col-md-12">
       Documenti RICEVUTI da {palestra.palestra}
            <table className="table">
                    <thead>
                        <tr>
                        <th>Numero</th>
                            <th>Data</th>
                            <th>Titolo</th>
                        </tr>
                    </thead>
                    <tbody>
                         {docsalvati&&Array.isArray(docsalvati)&&docsalvati.length>0&&
                            docsalvati.map((doc,index) =>(
                            <tr key={index} onClick={() => selectdocinviato(index)}>
                                <td>{doc.numero}/{doc.anno}</td>
                                <td>{doc.dataarrivo}</td>
                                <td>{doc.oggetto}</td>
                            </tr>
                            ))}
                    </tbody>
                </table>

                <div className="row" >
            <div className="col-md-9" style={{borderColor:'black', borderStyle:'dashed', borderWidth:1}}>

            <Text bootstrapsize={"col-md-12"} 
            id={"oggetto"}
            label={"Titolo"}
            name={"titolo"}
            onChange={(e) => settitolo(e.target.value)}
            value={titolo} />
            
<div id="printarea">
            { <div dangerouslySetInnerHTML={{ __html: html }} /> }
            </div>
        </div>
        
        </div>
        <div className="row">
        <button className="btn btn-success" onClick={stampahtmlpers}>STAMPA</button>
            
        </div>
        <div className="row">
				<ListaFilesProto files={files} allega={false} />
		</div>
        </div>
    )

}