import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faUserPlus, faCalendarAlt, faChartLine, faTicketAlt, faDoorOpen, faDumbbell, faEuroSign, faPlusCircle, faWeight, faArchive } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'hookrouter'
import { useSelector } from "react-redux";
export default function AnnotatioModules(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    return(
        <div className="container-fluid">
            {email&&anagrafica&&anagrafica.id>0&&
            <div className="row">
            <div className="col-md-4" >
                    <div className="card border-secondary" style={{margin:10}} onClick={()=> navigate('/annotatioc-prods/')}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faCalendarAlt} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Calendari</h5>
                        <p className="card-text">Effettuare una prenotazione</p>
                    </div>
                    <div className="card-footer">

                    </div>
                    </div>
                </div>
                <div className="col-md-4" >
                    <div className="card border-secondary" style={{margin:10}} onClick={()=> navigate('/annotatioc-preno/')}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faTicketAlt} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Prenotazioni effettuate</h5>
                        <p className="card-text">Visualizza le tue prenotazioni o effettua una cancellazione</p>
                    </div>
                    <div className="card-footer">

                    </div>
                    </div>
                </div>
                
                <div className="col-md-4" >
                    <div className="card border-primary" style={{margin:10}}>
                    <div className="card-header text-center" onClick={()=> navigate('/annotatioc-anag/',true)}>
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faAddressCard} />
                    </div>
                    <div className="card-body" >
                        <h5 className="card-title">Anagrafica</h5>
                        <p className="card-text">I dati anagrafici di {anagrafica.nome} {anagrafica.cognome}</p>
                    </div>
                    </div>
                </div>
                
                <div className="col-md-4" >
                    <div className="card border-success" style={{margin:10}}>
                    <div className="card-header text-center" onClick={()=> navigate('/annotatioc-abbs/',true)}>
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faEuroSign} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Abbonamenti</h5>
                        <p className="card-text"></p>
                    </div>
                    <div className="card-footer">
                    
                    </div>
                    </div>
                </div>
                <div className="col-md-4" >
                    <div className="card border-danger" style={{margin:10}} onClick={()=> navigate('/anntt-client-sa/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faDumbbell} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Scheda Sala Attrezzi</h5>
                        <p className="card-text"></p>
                    </div>
                    <div className="card-footer">

                </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-warning" onClick={()=> navigate('/anntt-client-cc/',true)} style={{margin:10}}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faWeight} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Scheda Composizione Corporea</h5>
                        <p className="card-text">
                        
                        </p>
                    </div>
                    <div className="card-footer">

    </div>
                    </div>
                </div>
                
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatioc-accessi/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faDoorOpen} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Accessi</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>
                
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-ordini/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faArchive} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Ordini</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}