import axios from 'axios';
import serverInfo from '../util/globals';

export default async function gettoken(email, idpalestra, appid){
  if (!idpalestra){
    idpalestra=0;
  }
  const formData = new FormData();
  formData.append('email', email?email:'');
  formData.append('appid', 'pluginclient');
  formData.append('website', serverInfo.domain);
  const response = await axios.post(serverInfo.name+ '/token',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  console.log('token '+response.data)
  window.$t=response.data
  return response.data;
}