import React from "react";
import SceltaAbbonamentoUtente from "../abbonamenti/SceltaAbbonamentoUtente";
import AnagraficaCliente from "../anagrafica/AnagraficaCliente";
import QualeTipologia from "../calendario/QualeTipologia";
import Carrello from "../carrello/Carrello";
import DocumentiUtente from "../documenti/DocumentiUtente";
import RicercaFatture from "../fatture/RicercaFatture";
import Ingressi from "../ingressi/Ingressi";
import DettaglioSchedadieta from "../nutrizionista/schededieta/DettaglioSchedadieta";
import Ordini from "../ordini/Ordini";
import RiepilogoOrdine from "../ordini/RiepilogoOrdine";
import PrenotazioniCliente from "../prenotazioni/PrenotazioniCliente";
import AbbonamentiCliente from "../riquadriUtente/AbbonamentiCliente";
import ListaSchedeCompCorp from "../schedasalaattrezzi/composizionecorporea/ListaSchedeCompCorp";
import ListaSchedeCliente from "../schedasalaattrezzi/ListaSchedeCliente";
import TipiCorsoCard from "../tipocorso/TipiCorsoCard";
import AnnotatioModules from "../view/AnnotatioModules";

export const routes = {
  "*/annotatioc-anag/": () => <AnagraficaCliente />,
  "*/annotatioc/": () => <AnnotatioModules />,
  "*/annotatioc-abbs/": () => <AbbonamentiCliente />,
  "*/annotatioc-newabb/": () => <SceltaAbbonamentoUtente />,
  "*/annotatioc-preno/": () => <PrenotazioniCliente />,
  "*/annotatioc-scheda/":() => <ListaSchedeCliente />,
  "*/annotatioc-compcorp/":() => <ListaSchedeCompCorp />,
  "*/annotatioc-accessi/":() => <Ingressi />,
  "*/annotatioc-prods/": () => <TipiCorsoCard />,
  "*/anntt-client-calendario/": () => <QualeTipologia />,
  "*/annotatioc-carrello/": () => <Carrello />,
  "*/annotatioc-docs/": () => <DocumentiUtente />,
  "*/annotatioc-dieta/": () => <DettaglioSchedadieta />,
    "*/annotatioc-ordini/": () => <Ordini />,
    "*/annotatioc-riepord/:id/": ({id}) => <RiepilogoOrdine idordine={id} />,
    "*/annotatioc-fatture/": () => <RicercaFatture />,
  };