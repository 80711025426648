import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnagraficaByMailAndPalestra, insAnagrafica } from "../DAO/anagraficaDAO";
import { setanagrafica, setEmailLoggato, setPalestraAnagrafica, settoken } from "../STORE/reducers/gestoreUtente";
import { getPalestraFromUrl } from "./globals";
import { setprodotti } from "../STORE/reducers/gestoreCarrello";
import CarrelloPreview from "../carrello/CarrelloPreview";
import gettoken from "../DAO/tokenDAO";
import { getPalestra } from "../DAO/palestraDAO";

let palestratemp=null
let tokentemp=null;
export default function LoadMainThings(props){
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const carrello = useSelector(state => state.gestoreCarrello.prodotti);

    //const [carrello, setcarrello] = useState([]);
    const dispatch=useDispatch();

    function gettokenannotatio(){
        console.log("gettokenannotatio")
        async function gett(){
            if(!props.t){
                const result = await gettoken(emailloggato, palestraanagrafica.id, palestraanagrafica.website+palestraanagrafica.subdomain)
                window.$t=result
                tokentemp=result
                dispatch(settoken(result))
            }
            else{
                dispatch(settoken(props.t))
                props.t
            }
            caricaEmail();
            caricaPalestraAnagrafica();
            
            caricaCarrello();
        }
        gett()
    }

    function caricaEmail(){
        console.log("caricaEMail")
        if (props.email&&!emailloggato){
            dispatch(setEmailLoggato(props.email));
        }
    }

    //carica palestra in base all'url del dominio attuale
    function caricaPalestraAnagrafica(){
        console.log("caricaPalestraAnagrafica")
        async function carica()
        {
            if (!palestraanagrafica||!palestraanagrafica.id||palestraanagrafica.palestra){
                console.log("getPalestraFromUrl")
                const paltemp=await getPalestraFromUrl(tokentemp);
                console.log(paltemp)
                if (paltemp&&paltemp.id){
                    
                    palestratemp=paltemp;
                    console.log("carica palestra")
                    console.log(palestratemp)
                    
                    dispatch(setPalestraAnagrafica(paltemp))
                }
                
                
            }
            else{
                console.log("palestraanagrafica c'e' gia' ")
                console.log(palestraanagrafica)
            }
            caricaAnagrafica(tokentemp);
        }
        carica()
    }

    //carica anagrafica in base all'email e all'url del dominio
    function caricaAnagrafica(tokentemp){
        console.log("caricaAnagrafica ")
        console.log(palestratemp)
        async function carica()
        {
            console.log("carico anagrafica")
            if (!anagrafica||!anagrafica.id){
                if (localStorage.getItem('anagrafica')){
                    
                    const anagtemp=JSON.parse(localStorage.getItem('anagrafica'))
                    dispatch(setanagrafica(anagtemp))
                    if (!palestraanagrafica||!palestraanagrafica.id||!palestratemp||!palestratemp.id){
                        const paltemp=await getPalestra(anagtemp.idpalestra, tokentemp)
                        dispatch(setPalestraAnagrafica(paltemp))
                    }
                }
                else if ((emailloggato||props.email)&&palestratemp&&palestratemp.id){
                    console.log("email login recuperata "+emailloggato+"-"+props.email+" e palestra recuperata "+palestratemp.id)
                    const anagtemp=await getAnagraficaByMailAndPalestra(props.email?props.email:emailloggato, palestratemp.id,'000', tokentemp);
                    if ((!anagtemp||!anagtemp.id)){
                            console.log("anagtemp non trovata")
                            const anagr={id:0, nome: props.nome?props.nome:"", 
                            cognome: props.cognome?props.cognome:"", 
                            telefono: props.telefono?props.telefono:"", email: emailloggato?emailloggato:props.email, palestra:palestratemp.id}
                            // console.log("provo a creare anagrafica con "+props.nome+" "+props.cognome+" "+props.telefono)
                            // const result=await insAnagrafica(emailloggato,palestratemp.id,anagr)
                            // console.log(result)
                    }
                    else{
                        console.log("trovata anagrafica "+anagtemp.id)
                        console.log(anagtemp)
                    }
                    dispatch(setanagrafica(anagtemp))
                    if (!palestraanagrafica||!palestraanagrafica.id||!palestratemp||!palestratemp.id){
                        const paltemp=await getPalestra(anagtemp.idpalestra, tokentemp)
                        dispatch(setPalestraAnagrafica(paltemp))
                    }
                }
            }
            else{
                console.log("niente login email "+emailloggato+"-"+props.email+" e niente palestra "+(palestratemp?palestratemp.id:""))
            }
        }
        carica()
    }

    function caricaCarrello(){
        console.log("caricaCarrello")
        if (localStorage.getItem('carrello')){
              dispatch(setprodotti(JSON.parse(localStorage.getItem('carrello'))));
        }
    }

    useEffect(() => {
        gettokenannotatio();
        
        

    },[emailloggato])

    return (
        <div>
            {props.showDebugInfo &&
            <span>
            {props.email} - {palestraanagrafica.palestra} - {anagrafica&&anagrafica.id?anagrafica.nome:''} {props.nome}
            </span>}
            {carrello&&carrello.length>0&&anagrafica&&anagrafica.id&&(!props.nascondicarrello)?<CarrelloPreview />:null}
        </div>
    );
}