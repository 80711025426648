import React, { useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";

export default function InvioMessaggioCliente (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

  const [messaggio, setmessaggio] = useState('');
  const [risultato, setrisultato] = useState();


  function inviaMessaggio(){
    if (messaggio.trim()===''){
        alert("Messaggio obbligatorio");
        return;
    }
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra.id);
    formData.append('msg', messaggio);
    formData.append('idAnagrafica', anagrafica.id);
    axios.post(serverInfo.namesec+'/msg/inviaMessaggiRest', formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },)
    .then((result) => {
  console.log(result.data)
        alert(result.data)
      setrisultato(result.data);
    });
}

      return (
        <div className="form-group">
        <label htmlFor="Messaggio">Messaggio</label>
        <textarea className="form-control" 
        name="messaggio" id="Messaggio" 
        value={this.state.messaggio}
        onChange={(e) => setmessaggio(e.target.value)}></textarea>
         <button className="btn btn-primary" onClick={inviaMessaggio}>Invia messaggio</button>
    </div>
        );
  }