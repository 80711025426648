import React from "react";
import axios from 'axios';
import '../util/config'
import { connect } from "react-redux";
import serverInfo from '../util/globals';

class Ingressi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                ingressi:null
        };
      }

      componentDidMount(){
        const formData = new FormData();
        formData.append('palestra', this.props.palestra.id);
        formData.append('email',this.props.email);
		formData.append('website', global.config.domain);
		formData.append('lic','000');
		  axios.post(serverInfo.namesec+"/user/getIngressi",formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },)
		  .then(response=>{
			console.log("Ingressi: ")
			  console.log(response.data)
			  this.setState({ingressi: response.data});
		  })
		  .catch(err => {
              if (err.response){
                alert("Ingressi: "+err.response.data.message);
              }
              else{
                alert("Ingressi: "+err.response);
              }
		});
      }

render() {
    return (
        <span>
            <h2 className="entry-title"><a href="#">Ingressi</a></h2>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            {this.state.ingressi&&Array.isArray(this.state.ingressi)&&this.state.ingressi.length>0?
                            this.state.ingressi.map((ingr, index)=>(
                        <p key={index} className="card-text">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" value={ingr.data} />
                                </div>
                            </div>
                        </p>
                        ))
                        :"Nessuna prenotazione trovata"}
                        </div>
                    </div>
                </div>
    </div>
    
</span>
);
  }
}

const mapStateToProps = state => {
  return {
      palestra: state.gestoreUtente.palestraAnagrafica,
      email: state.gestoreUtente.emailloggato,
      anagrafica: state.gestoreUtente.anagrafica
  }
}

export default connect(mapStateToProps, null)(Ingressi)