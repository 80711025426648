/* eslint-disable react/prop-types */
import React from 'react';

import '../util/config'
import CalendarioMenuClienteItem from './CalendarioMenuClienteItem';
import './calendario.css'
import CalendarioMenuItemNew from './CalendarioMenuItemNew';
export default function CalendarioMenuPlaceCliente(props) {

    return (
      <div>
          {props.place?
          <div className='container'>
            <div className='row'>
          {props.showplace&&<h2 className='text-center'>{props.place.id}</h2>}
          {localStorage.getItem('carrello') &&props.email&& <div><button onClick={() => window.location.href="/carrello"}>carrello</button></div>}
          </div>
          {props.tipicorso?
            props.tipicorso.map((tipo, index)=>(
                <div key={index}>
                  <div className='strike' >
                   <span><h2>{tipo.descrizione}</h2></span>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4">

                    {
                    props.appuntamenti&&props.appuntamenti.length>0?
                    props.appuntamenti.map(app=>(
                        tipo.nome===app.tipoCorso?
                        <CalendarioMenuClienteItem 
                        email={props.email} 
                        key={app.id} 
                        app={app} 
                        tipo={props.tipocorso}
                        linkcalendario={props.linkcalendario}
                        aggiornaCarrello={props.aggiornaCarrello}
                         />
                        :null
                    ))
                    :
                    null
                    }

                    {
                    props.place.corsi.map(corso=>
                      corso.disponibilita==='disponibile' && (corso.disponibileda <= props.dataISO && corso.disponibilea >= props.dataISO)?
                      <CalendarioMenuItemNew key={'corso'+corso.id} corso={corso} linkcalendario={props.linkcalendario} />
                      :<div key={'corso'+corso.id}>Oggi non è previsto alcun prodotto in {tipo.nome}</div>
                      )
                    
                    }
                    </div>
                    </div>
                    ))
                    :"Nesuna tipologia"}
              </div>
        :null}
      </div>
    );
  }