import {createSlice} from '@reduxjs/toolkit';

export const gestoreCarrelloSlice = createSlice({
  name: 'gestoreCarrello',
  initialState: {
    prodotti: [
      // {
      //   prodotto: corsoBO,
      //   schedulazione: appuntamentoDTO,
      //    prodottoComposto: corsoBO(padre)
      //   ingrediente: ingredienteBO,
      //   quantita:0,
      //  idanagrafica
      // }
    ],
  },
  reducers: {
    setprodotti: (state, prodotti) => {
      //console.log('setprodotti ' + prodotti.payload);
      state.prodotti = prodotti.payload;
      localStorage.setItem('carrello',JSON.stringify(state.prodotti));
    },
    aggiornaprodotti: (state, item) => {
     console.log('aggiornaprodotti ',item.payload);

      //se nel carrello viene aggiunta/aggiornata una schedulazione
      if (item.payload.schedulazione){
        let indexprodotto = state.prodotti?state.prodotti.findIndex(prodtemp => prodtemp.schedulazione&&prodtemp.schedulazione.id === item.payload.schedulazione.id):-1;
        if (indexprodotto>=0){
          state.prodotti[indexprodotto]=item.payload;
        }
        else{
          state.prodotti.push(item.payload);
        }
      }
      
      else if (item.payload.prodpers){
        let indexprodotto = state.prodotti?state.prodotti.findIndex(prodtemp => prodtemp.prodpers&&prodtemp.prodpers.id === item.payload.prodpers.id):-1;
        if (indexprodotto>=0){
          state.prodotti[indexprodotto]=item.payload;
        }
        else{
          state.prodotti.push(item.payload);
        }

      }
      else if (item.payload.prodotto){
        let indexprodotto = state.prodotti?state.prodotti.findIndex(prodtemp => prodtemp.prodotto&&prodtemp.prodotto.id === item.payload.prodotto.id):-1;
        if (indexprodotto>=0){
          state.prodotti[indexprodotto]=item.payload;
        }
        else{
          state.prodotti.push(item.payload);
        }

      }
      localStorage.setItem('carrello',JSON.stringify(state.prodotti));
    },
  },
});

export const {setprodotti, aggiornaprodotti} = gestoreCarrelloSlice.actions;

export default gestoreCarrelloSlice.reducer;
