import axios from "axios";
import '../util/config'
import serverInfo from "../util/globals";

export async function ordini(idanag){
    const formData = new FormData();
    formData.append('idAnag',idanag);
    const response = await axios.post(serverInfo.namesec+"/ord/ordini",
    formData,
    {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    //console.log(response); 
    return response.data;
}

export async function getordine(idordine){
  const formData = new FormData();
  formData.append('idordine',idordine);
  const response = await axios.post(serverInfo.namesec+"/ord/getordine",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response); 
  return response.data;
}

export async function ordiniadmin(email, palestra){
  const formData = new FormData();
  formData.append('website','annotatio.online');
  formData.append('email',email);
  formData.append('palestra',palestra);
  const response = await axios.post(serverInfo.namesec+"/ord/ordini",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response); 
  return response.data;
}

export async function modificaoraconsegna(email, palestra, idordine, nuovaoraconsegna){
  const formData = new FormData();
  formData.append('email',email);
	formData.append('website','annotatio.online');
	formData.append('lic','00000000');
  formData.append('palestra',palestra);
  formData.append('idordine',idordine);
  formData.append('oraconsegna',nuovaoraconsegna);
  const response = await axios.post(serverInfo.namesec+"/ord/aggiornaOrdine", formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  )
  //console.log(response); 
  return response.data;
}

export async function inviamsgcliente(email, palestra, idordine, messaggio, idanag){
  const formData = new FormData();
  formData.append('email',email);
	formData.append('website','annotatio.online');
	formData.append('lic','00000000');
  formData.append('palestra',palestra);
  formData.append('msg',messaggio)
  formData.append('idAnagrafica',idanag) //destinatario
  formData.append('idordine',idordine);
  const response = await axios.post(serverInfo.namesec+"/msg/inviaMessaggioCliente", 
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  )
  //console.log(response); 
  return response.data;
}

export async function annullaordine(email, palestra, idordine){
  const formData = new FormData();
  formData.append('email',email);
	formData.append('website','annotatio.online');
	formData.append('lic','00000000');
  formData.append('palestra',palestra);
  formData.append('idordine',idordine);
  formData.append('status',"X_ANNULLATO");
  const response = await axios.post(serverInfo.namesec+"/ord/aggiornaOrdine",
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  )
  //console.log(response); 
  return response.data;
}

export async function aggiornastatusordine(email, palestra, idordine, status){
  const formData = new FormData();
  formData.append('email',email);
	formData.append('website','annotatio.online');
	formData.append('lic','00000000');
  formData.append('palestra',palestra);
  formData.append('idordine',idordine);
  formData.append('status',status);
  const response = await axios.post(serverInfo.namesec+"/ord/aggiornaOrdine",
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  )
  //console.log(response); 
  return response.data;
}