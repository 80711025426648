import React from "react";
import '../util/config'
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setcalendariocliente } from "../STORE/reducers/gestoreCalendari";

export default function RigaCalendario (props) {

  const dispatch = useDispatch();

  function openCalendario(){
    props.setcalendario(props.calendario)
    dispatch(setcalendariocliente(props.calendario))
    }

      return (
        <Button onClick={openCalendario} style={{backgroundColor:props.calendario===localStorage.getItem('calendario')?'green':'blue'}}>{props.calendario}</Button>
        );

  }