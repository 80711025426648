import serverInfo from "../util/globals";
import axios from 'axios';

export async function acquista(email, palestra, carrello, consegna, nome, cognome, indirizzo, cap, citta, note, oraconsegna, telefono, codicesconto){
    const formData = new FormData();
    formData.append('website', 'annotatio.online');
    formData.append('email',email);
    formData.append('lic','00000000');
    formData.append('palestra',palestra);
    formData.append('carrello',carrello);//stringfy
    formData.append('consegna',consegna);
    formData.append('nome',nome);
    formData.append('cognome',cognome);
    formData.append('indirizzo',indirizzo);
    formData.append('cap',cap);
    formData.append('citta',citta);
    formData.append('note',note?note:'');
    formData.append('oraconsegna',oraconsegna?oraconsegna:'');
    formData.append('telefono',telefono?telefono:'');
    formData.append('codicesconto',codicesconto?codicesconto:'');
    const response = await axios.post(serverInfo.namesec+"/ord/pagacarrello", 
    formData,
    {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
        },
    },
    );
    //console.log(response.data);
    return response.data;
}