import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import PrenotazioneCliente from "./PrenotazioneCliente";
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";
import { prenota } from "../DAO/calendarioDAO";

export default function PrenotazioniCliente(){
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [prenotazioni, setprenotazioni] = useState([])

    useEffect(() => {
        if (palestra&&palestra.id&&anagrafica&&anagrafica.id){
          const formData = new FormData();
          formData.append('website', global.config.domain);
          formData.append('palestra', palestra.id);
          formData.append('email',email);
          formData.append('lic','000');
          
          formData.append('id',anagrafica.id);
          axios.post(serverInfo.namesec+"/wp/getPrenotazioni",formData,
          {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization' :'Bearer '+window.$t
              },
            },)
          .then(response=>{
          console.log("Prenotazioni: ")
            console.log(response.data)
          setprenotazioni(response.data);
          })
          .catch(err => {
                  if (err.response){
                    alert("Prenotazioni: "+err.response.data.message);
                  }
                  else{
                    alert("Prenotazioni: "+err.response);
                  }
        });
      }
      }, [palestra, anagrafica]);

    return (
        <span>
            <h2 className="entry-title"><a href="#">Prenotazioni effettuate</a></h2>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            {prenotazioni&&Array.isArray(prenotazioni)&&prenotazioni.length>0?
                            prenotazioni.map(prenotazione=>(
                                <PrenotazioneCliente key={prenotazione.id} prenotazione={prenotazione} />
                        ))
                        :"Nessuna prenotazione trovata"}
                        </div>
                    </div>
                </div>
    </div>
</span>
);
}