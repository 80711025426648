import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputText from "../util/UI/InputText";
import { documentiricevuti, eliminadoc, handleEliminaFile, handleMostraFile, handleUploadFile, salvadoc } from "../DAO/DocumentiDAO";
import ListaFilesProto from "./listaFilesProto";
import Bottone from "../util/UI/Bottone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'

export default function ProtoEntrataAnag(){
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const [docricevuti, setdocricevuti] = useState([])
    const [id, setid] = useState(0)
    const [titolo, settitolo] = useState('')
    const [html, sethtml] = useState('')
    const [tipo, settipo] = useState('E')
    const [idtipomovimento, setidtipomovimento] = useState('')
    const [files, setfiles]=useState([])
    const [file, setfile]=useState()

    function selectdocinviato(index){
        setid(docricevuti[index].id)
        sethtml(docricevuti[index].htmlreadable)
        settitolo(docricevuti[index].oggetto)
        setidtipomovimento(docricevuti[index].idtipomovimento)
        settipo(docricevuti[index].tipo)
        async function caricaFiles(){
            const result = await handleMostraFile(docricevuti[index].id, email, palestra.palestra.id)
            setfiles(result)
        }
        caricaFiles()
        //setkeytorefresh(new Date().getTime())
    }

    function eliminadocinviato(id){
        async function elimina(){
            const result = await eliminadoc(id, palestra.id, email)
            alert(result)
            docinviati()
        }
        if (window.confirm("Sei sicuro di voler il documento selezionato?")){
            elimina()
        }
    }

    function eliminafile(idfile){
        async function elimina(){
            const result = await handleEliminaFile(idfile, palestra.id, email)
            alert(result)
            docinviati()
        }
        if (window.confirm("Sei sicuro di voler eliminare il file selezionato?")){
            elimina()
        }
    }

    function salvadocumento(){
        async function salva(){
            const proto = await salvadoc(palestra.id,email,id, titolo, html, anagrafica.id, 'E', idtipomovimento)
            if (file){
                const result = await handleUploadFile(proto.id, file, email, palestra.id)
            }
            console.log(proto)
            if (proto.id&&proto.id>0){
                alert("Documento salvato con successo")
                setid(proto.id)
                docinviati()
            }
        }
        if (!titolo){
            alert('Oggetto obbligatorio')
            return
        }
        salva()
    }

    function onChangeFile(e) {
		setfile(e.target.files[0])
	  }

      function stampahtmlpers() {
        var divContents = document.getElementById("printarea").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > ');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    function docinviati(){
        async function carica(){
            let result = await documentiricevuti(palestra.id, anagrafica.id);
            setdocricevuti(result)
        }
        carica();
    }

    useEffect( ()=> {
        docinviati()
    },[palestra])

    function nuovodocumento(){
        settitolo('')
        setid(0)
        setfiles([])
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4"> Documenti INVIATI a {palestra.palestra} </div>
                <Bottone bootstrapsize={"col-md-3"} bootstrapstyle={"warning"}
                label={<FontAwesomeIcon icon={faPlusCircle} />} onClick={nuovodocumento} />
                <table className="table">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Titolo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {docricevuti&&Array.isArray(docricevuti)&&docricevuti.length>0&&
                        docricevuti.map((doc,index) =>(
                        <tr key={index} onClick={() => selectdocinviato(index)}>
                            <td>{doc.dataarrivo}</td>
                            <td>{doc.oggetto}</td>
                            <td><button className="btn btn-danger" onClick={() => eliminadocinviato(doc.id)}>Elimina</button></td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
            <h5 className="col-md-12">{(id===0)?"Nuovo documento":"Modifica documento "}</h5>
            </div>
            <div className="row" >
            <div className="col-md-9" style={{borderColor:'black', borderStyle:'dashed', borderWidth:1}}>

            <InputText bootstrapsize={"col-md-12"} 
            id={"oggetto"}
            label={"Titolo"}
            name={"titolo"}
            onChange={(e) => settitolo(e.target.value)}
            placeholder={"TITOLO DOCUMENTO"}
            type={"text"} value={titolo} />
            <div id="printarea">
            { <div dangerouslySetInnerHTML={{ __html: html }} /> }
            </div>
        </div>
       
        </div>
        
        <div className="row">
        <button className="btn btn-success" onClick={salvadocumento}>SALVA</button>
            
        </div>
        <div className="row">
				<ListaFilesProto files={files} handleEliminaFile={eliminafile}
                file={file} onChangeFile={onChangeFile} uploadfile={salvadocumento} allega={true} />
		</div>
        </div>
    )
}