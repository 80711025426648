import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import Ordine from "./Ordine";
import { useSelector } from "react-redux";
import { ordini } from "../DAO/ordiniDAO";
import LoadMainThings from "../util/LoadMainThings";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStepContext } from "@mui/material";

export default function Ordini(props) {
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);

  const [listaordini, setlistaordini] = useState([])
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const [expanded, setExpanded] = useState(false);
  const [messaggio, setmessaggio] = useState('');
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

  function caricaOrdini(){
    async function getordini(){
      const ordinitemp=await ordini(anagrafica.id)
      setlistaordini(ordinitemp);
      setmessaggio(ordinitemp&&Array.isArray(ordinitemp)&&ordinitemp.length>0?'Trovati '+ordinitemp.length+' ordini':'Nessun ordine trovato')
    }
    if (anagrafica&&anagrafica.id){
      setmessaggio('Caricamento degli ordini in corso. Attendere prego...');
      getordini();
    }
  }

  useEffect(() =>{
    caricaOrdini();
  },[anagrafica, keytorefresh])

        return (
            <span key={keytorefresh}>
                <div className="row">
                  <LoadMainThings email={props.email} showDebugInfo={false}/>
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                            {messaggio}
                                {listaordini&&Array.isArray(listaordini)&&listaordini.length>0&&
                                listaordini.map(ordine=>(
                                  <Accordion key={ordine.id} expanded={expanded === ordine.id} onChange={(event,isExpanded) => handleChange(ordine.id, event, isExpanded)}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={ordine.id+"bh-content"}
                                      id={ordine.id+"bh-header"}>
                                        <Typography sx={{ width: '33%', flexShrink: 0, color:ordine.status.indexOf('PRONTO')>=0?'green':ordine.status.indexOf('NUOVO')>=0?'blue':ordine.status.indexOf('ANNULLATO')>=0?'red':"black"}}>
                                          {ordine.id} {ordine.status}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Ordine key={ordine.id} ordine={ordine} refresh={() => setkeytorefresh(new Date().getTime())}/>
                                    
                                    </AccordionDetails>
                                    </Accordion>
                            ))
                           
                            }
                            </div>
                        </div>
                    </div>
        </div>
        
    </span>
    );
}