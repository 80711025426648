/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Allergeni from "./Allergeni";

export default function DettIngrediente(props){

    function aggiornaQuantita(value){
        if (parseInt(value)>0){
            
            props.aggiornapersonalizzato(props.ingrediente, value)
        }
        else{
            
            props.aggiornapersonalizzato(props.ingrediente, 0)
        }
    }

    return (
        <div style={{borderBottomColor:'gray', borderBottomWidth:1, borderBottomStyle:'dotted', paddingBottom:5, marginBottom:5}} className="row">
            {
            !props.prodottopersonalizzato || !props.prodottopersonalizzato.ingredienti || props.prodottopersonalizzato.ingredienti.filter(ingrtemp => ingrtemp.nome===props.ingrediente.nome).length===0 ?
            <>
            <div style={{textAlign:'left'}} className="col">
            {props.ingrediente.nome}
            </div>
            <div style={{textAlign:'center'}}>
            <Allergeni allergeni={props.ingrediente.allergeni}/>
            </div>
            <div style={{textAlign:'right'}} className="col">
            {props.solouno?
                  <button onClick={()=>aggiornaQuantita(1)}>Seleziona</button>
                :<button onClick={()=>aggiornaQuantita(1)}>Seleziona</button>}
            </div>
            
            
                

                {/* <div style={{ flexDirection:'row'}}>
                <button onClick={()=>aggiornaQuantita(parseInt(parseInt(quantita)-1))}>-</button>
                <TextInput
                value={""+quantita} 
                keyboardType='numeric' 
                onChangeText={(value)=>setquantita(value)} />
                <button onClick={()=>aggiornaQuantita(parseInt(parseInt(quantita)+1)) }>+</button>
                </div> */}
   
            </>
            : 
            < >
            <div className="col" style={{backgroundColor:'green', color:'white'}}>
                <strong>{props.ingrediente.nome}</strong>
                <Allergeni allergeni={props.ingrediente.allergeni}/>
            </div>
            <div className="col">

                {props.isCategObbligatoria ? <button disabled={true}>Selezionato</button>:<button onClick={()=>aggiornaQuantita(0)}>Elimina</button>}
            </div>
            </>
        }

        </div> 
    );
}
