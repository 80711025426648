import axios from 'axios';
import serverInfo from '../util/globals';

export async function getPrenotazioni(email, lic, palestra, idanag, idabbonamento) {
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', lic);
  formData.append('palestra', palestra);
  formData.append('id', idanag);
  if (idabbonamento){
    formData.append('idabbonamento', idabbonamento);
  }

  const response = await axios.post(
    serverInfo.name+'/getPrenotazioni',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function disdici(email, lic, palestra, idPrenotazione) {
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', lic);
  formData.append('palestra', palestra);
  formData.append('idPrenotazione', idPrenotazione);
  const response = await axios.post(
    'https://be.annotatio.online/abb/annullaPrenotazione',
    formData,
    {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
  );
  //console.log(response);
  return response.data;
}

export async function getprenotati(palestra, email, idschedulazione){
  const formData = new FormData();
	formData.append('website', 'annotatio.online');
	formData.append('palestra',palestra);
  formData.append('email',email);
	formData.append('lic','00000000');
	const response = await axios.post(serverInfo.name+'/getPrenotazioni/'+idschedulazione,
  formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function prenotaAnag(email, palestra, idanagrafica, nomeLibero, idschedulazione, fisso){
  const formData = new FormData();
	formData.append('website', 'annotatio.online');
	formData.append('palestra',palestra);
  formData.append('email',email);
	formData.append('lic','00000000');
	formData.append('idUtente',idanagrafica?idanagrafica:'');
	formData.append('idAnagrafica',idanagrafica?idanagrafica:'');
	formData.append('nomeLibero',nomeLibero?nomeLibero:'');
	formData.append('nomelibero',nomeLibero?nomeLibero:'');
	formData.append('idSchedulazione',idschedulazione);
	formData.append('fisso',fisso);
  const response = await axios.post(serverInfo.name+'/inserisciPrenotazione',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);
//console.log(response);
return response.data;
}

export async function confermaPrenotazione(email, palestra, idanagrafica, nomeLibero, idschedulazione, fisso){
  const formData = new FormData();
	formData.append('website', 'annotatio.online');
	formData.append('palestra',palestra);
  formData.append('email',email);
	formData.append('lic','00000000');
	formData.append('idUtente',idanagrafica?idanagrafica:'');
	formData.append('idAnagrafica',idanagrafica?idanagrafica:'');
	formData.append('nomeLibero',nomeLibero?nomeLibero:'');
	formData.append('nomelibero',nomeLibero?nomeLibero:'');
	formData.append('idSchedulazione',idschedulazione);
	formData.append('fisso',fisso);
  const response = await axios.post(serverInfo.name+'/confermaPrenotazione',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);
//console.log(response);
return response.data;
}



export async function annullaPrenotazione(email, palestra, idprenotazione){
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
	formData.append('palestra',palestra);
  formData.append('email',email);
	formData.append('lic','00000000');
  formData.append('idPrenotazione',idprenotazione);
  const response = await axios.post(serverInfo.namesec+'/abb/annullaPrenotazione',
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
);
//console.log(response);
return response.data;
}