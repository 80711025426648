import React from "react";

export default function Bottone({bootstrapsize, label, id, bootstrapstyle, onClick}){

    return ( 
        <div className={"form-group "+bootstrapsize}>
            <button className={"btn btn-"+bootstrapstyle} 
            onClick={onClick} 
            id={id}>{label}</button>
        </div>
    );
}